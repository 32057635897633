import { FilesDriveResponse, FileStatus, NodeType } from '../../schemas/files-drive-schema'

export const response: FilesDriveResponse = {
  files: [
    {
      id: 'd505f473-f7b6-4bf4-8996-a5e219ca4da9',
      parent_id: '4eb3ba15-ab13-4cf1-9385-932ad5d041ed',
      type: NodeType.FILE, //Nodetype.FILE
      source_location: 'tbd',
      markdown_location: 'tbd',
      user_id: 'YrNLwVPDvjPi16fejN1HoDxHI1f2',
      size: 2000,
      name: 'test-files',
      status: FileStatus.PROCESSING, //filestatus
      extension: 'jpg',
      folder_path: '',
      created_at: '2024-12-13T21:13:43.078386',
      updated_at: '2024-12-13T21:13:43.078386',
      events: null,
      parse_file_id: null,
      parse_file_sentences_id: null,
    },
    {
      id: '58c42e70-c6d1-480d-9574-8bc2b5aa5647',
      parent_id: '4eb3ba15-ab13-4cf1-9385-932ad5d041ed',
      type: NodeType.FILE,
      source_location: 'tbd',
      markdown_location: 'tbd',
      user_id: 'YrNLwVPDvjPi16fejN1HoDxHI1f2',
      size: 2000,
      name: 'test-files-2',
      status: FileStatus.COMPLETE,
      extension: 'jpg',
      folder_path: '',
      created_at: '2024-12-13T21:18:14.973272',
      updated_at: '2024-12-13T21:18:14.973272',
      events: null,
      parse_file_id: null,
      parse_file_sentences_id: null,
    },
    {
      id: '5ef7d779-61f5-4656-a8da-ff7d5100c5b8',
      parent_id: '4eb3ba15-ab13-4cf1-9385-932ad5d041ed',
      type: NodeType.FILE,
      source_location: 'tbd',
      markdown_location: 'tbd',
      user_id: 'YrNLwVPDvjPi16fejN1HoDxHI1f2',
      size: 2000,
      name: 'test-files222',
      status: FileStatus.ERROR,
      extension: 'jpg',
      folder_path: '', //Change because it was "/", matching with folders
      created_at: '2024-12-13T21:23:02.470486',
      updated_at: '2024-12-13T21:23:02.470486',
      events: null,
      parse_file_id: null,
      parse_file_sentences_id: null,
    },
    {
      id: '60321871-aaa8-4bbf-a7f7-7a4f90ac5d36',
      parent_id: '6bcd5c34-14c8-4b87-99bb-72884da29c93',
      type: NodeType.FILE,
      source_location: 'tbd',
      markdown_location: 'tbd',
      user_id: 'YrNLwVPDvjPi16fejN1HoDxHI1f2',
      size: 2000,
      name: 'test-files22',
      status: FileStatus.COMPLETE,
      extension: 'jpg',
      folder_path: 'trash2/trash3/goo',
      created_at: '2024-12-13T21:23:45.885661',
      updated_at: '2024-12-13T21:23:45.885661',
      events: null,
      parse_file_id: null,
      parse_file_sentences_id: null,
    },
  ],
  folders: [
    {
      id: '4eb3ba15-ab13-4cf1-9385-932ad5d041ed', //root
      parent_id: null,
      type: NodeType.FOLDER,
      source_location: 'tbd',
      markdown_location: null,
      user_id: 'YrNLwVPDvjPi16fejN1HoDxHI1f2',
      size: 240,
      name: '',
      status: FileStatus.COMPLETE,
      extension: null,
      folder_path: '',
      created_at: '2024-12-13T21:13:42.995060',
      updated_at: '2024-12-13T21:13:42.995060',
      events: null,
      parse_file_id: null,
      parse_file_sentences_id: null,
      children: null,
    },
    {
      id: '4aa23ac0-e2ae-44a4-8f86-520c57f8863e',
      parent_id: '4eb3ba15-ab13-4cf1-9385-932ad5d041ed',
      type: NodeType.FOLDER,
      source_location: 'tbd',
      markdown_location: null,
      user_id: 'YrNLwVPDvjPi16fejN1HoDxHI1f2',
      size: 240,
      name: 'example2',
      status: FileStatus.COMPLETE,
      extension: null,
      folder_path: '',
      created_at: '2024-12-13T21:18:14.774362',
      updated_at: '2024-12-13T21:18:14.774362',
      events: null,
      parse_file_id: null,
      parse_file_sentences_id: null,
      children: null,
    },
    {
      id: 'e45e30a3-8320-4d2f-98b1-2d2bc2566345',
      parent_id: '4aa23ac0-e2ae-44a4-8f86-520c57f8863e',
      type: NodeType.FOLDER,
      source_location: 'tbd',
      markdown_location: null,
      user_id: 'YrNLwVPDvjPi16fejN1HoDxHI1f2',
      size: 240,
      name: 'example3',
      status: FileStatus.COMPLETE,
      extension: null,
      folder_path: 'example2',
      created_at: '2024-12-13T21:18:14.887194',
      updated_at: '2024-12-13T21:18:14.887194',
      events: null,
      parse_file_id: null,
      parse_file_sentences_id: null,
      children: null,
    },
    {
      id: '6bcd5c34-14c8-4b87-99bb-72884da29c93',
      parent_id: 'e45e30a3-8320-4d2f-98b1-2d2bc2566345',
      type: NodeType.FOLDER,
      source_location: 'tbd',
      markdown_location: null,
      user_id: 'YrNLwVPDvjPi16fejN1HoDxHI1f2',
      size: 240,
      name: 'goo',
      status: FileStatus.COMPLETE,
      extension: null,
      folder_path: 'example2/example3',
      created_at: '2024-12-13T21:18:14.929709',
      updated_at: '2024-12-13T21:18:14.929709',
      events: null,
      parse_file_id: null,
      parse_file_sentences_id: null,
      children: null,
    },
    {
      id: '6fc5fea3-ff68-43a1-825f-07a1d6dc617b',
      parent_id: '4eb3ba15-ab13-4cf1-9385-932ad5d041ed',
      type: NodeType.FOLDER,
      source_location: 'tbd',
      markdown_location: null,
      user_id: 'YrNLwVPDvjPi16fejN1HoDxHI1f2',
      size: 240,
      name: 'cat',
      status: FileStatus.COMPLETE,
      extension: null,
      folder_path: '',
      created_at: '2024-12-13T21:23:45.807964',
      updated_at: '2024-12-13T21:23:45.807964',
      events: null,
      parse_file_id: null,
      parse_file_sentences_id: null,
      children: null,
    },
  ],
}

import { kNewConversationTitle } from '../conversation_list/constants'
import { ChatV2Feature } from '../store/chat-v2.slice'

export function newChatButtonLabel(feature: ChatV2Feature | null) {
  switch (feature) {
    case ChatV2Feature.contractanalysis:
      return 'New Analysis'
    case ChatV2Feature.contractsearch:
      return 'New Search'

    default:
      return kNewConversationTitle
  }
}

import { kProblemDisplayingMessage } from '@/constants/constants-strings'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import ErrorMessageBubble from './ErrorMessageBubble'

type AgentUserQueryEventBubbleProps = {
  conversationId: string
  eventId: string
}

export default function AgentUserQueryEventBubble(props: AgentUserQueryEventBubbleProps) {
  const { conversationId, eventId } = props

  const event = useAppSelector((state: RootState) => state.agentEventsState.events[conversationId]?.[eventId])

  // TODO: User query bubble expand/collapse for when contentExceedsOriginalHeight
  // See ChatUserQueryBubble for reference

  if (!event) {
    console.error('Event not found')
    return <ErrorMessageBubble message={kProblemDisplayingMessage} />
  }

  const trimmedText = event.value.trim() || ''

  const messageHeader = 'You'

  return (
    <div className="p-2 rounded-lg text-base whitespace-pre-wrap compatible-word-break bg-transparent">
      <div className="font-bold text-xl mb-1">{messageHeader}</div>
      <div className="text-sm leading-6 mb-1">{trimmedText}</div>
    </div>
  )
}

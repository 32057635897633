import MicrosoftSSOForm from '@/components/auth/microsoft/microsoft-sso-form'
import { closeAuthDialog } from '@/store/slices/ui-state.slice'
import { useAppDispatch } from '@/store/store-hooks'
import { useNavigate } from 'react-router-dom'
import { kHaikuLogoPath } from '@/constants/constants-ui'

export default function DashboardAuthHaiku() {
  // Watch global UI state
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <div className={'flex flex-col justify-center items-center w-full p-4'}>
      <div className="p-6 text-[#222B64]">
        <h2 className="text-3xl ">
          <img className="mx-auto w-[240px]" src={kHaikuLogoPath} alt="Welcome to Haiku" />
        </h2>
      </div>
      <div className="w-full max-w-md rounded-lg bg-brand-neutral-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
        <MicrosoftSSOForm
          onSuccess={() => {
            dispatch(closeAuthDialog())
            navigate('/dashboard')
          }}
        />
        <div className="flex w-full items-center justify-center mt-4 font-semibold text-[#222B64]">with your Holland and Knight Account</div>
      </div>
    </div>
  )
}

import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import ReferenceViewer from '../reference-viewer/reference-viewer'
import ArtifactViewer from '@/artifacts/components/artifact-viewer/artifact-viewer'
import { selectConversationVisibleReferenceExists } from '@/chat-common/store/chat-v2.selectors'
import { selectAgentConversationContentViewer } from '@/agent/conversations/store/selectors'
import { ContentViewerMode } from '@/agent/conversations/store/slice'

type ContentViewerConstructorProps = {
  conversationId: string
}

/**
 * Determines the mode of the content viewer (reference or artifact),
 * and returns the correct sub-viewer component.
 */
export default function ContentViewerConstructor(props: ContentViewerConstructorProps) {
  const { conversationId } = props

  // Legacy chat visible reference
  const hasVisibleReference = useAppSelector((state: RootState) => selectConversationVisibleReferenceExists(state, { chatId: conversationId }))

  // Agent conversation content viewer containing an artifact or reference
  const contentViewer = useAppSelector((state: RootState) => selectAgentConversationContentViewer(state, { conversationId }))

  if (!contentViewer && !hasVisibleReference) {
    return null
  }

  // If it's a legacy chat with a visible reference, show the reference viewer
  if (hasVisibleReference) {
    return <ReferenceViewer conversationId={conversationId} />
  }

  // If it's an agent conversation with a content viewer, show the correct viewer based on the mode
  if (contentViewer) {
    switch (contentViewer.mode) {
      case ContentViewerMode.artifact:
        return <ArtifactViewer conversationId={conversationId} />
      case ContentViewerMode.reference:
        return null // Add new agent conversation reference viewer here
      default:
        return null
    }
  }

  return null
}

import { kMessageFeedbackApiPath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { FeedbackThumb } from '../schemas/feedback-schema'
import { ChatV2Message } from '../schemas/chat-v2.schemas'

export type PutMessageFeedbackArgs = {
  message: ChatV2Message
  thumb: FeedbackThumb
  thumb_text: string
}

export default async function putMessageFeedback(args: PutMessageFeedbackArgs) {
  const { message, thumb, thumb_text } = args

  // Create transaction id
  const sentry_transaction_id = nanoid()

  try {
    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw Error(kErrorCodeAuthError)

    // Construct request
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'PUT',
    }

    const apiUrl = kPaxtonAppApiBaseUrl() + `${kMessageFeedbackApiPath}/${message.metadata.message_id}?thumb=${thumb}&thumb_text=${thumb_text}`
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // console.log(
    //   `Submitting feedback for message ${message.metadata.message_id} with thumb: ${thumb} and thumb_text: ${thumb_text}. RequestOptions: `,
    //   requestOptions
    // )

    // Handle Errors and return early
    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            response: data,
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
    }
    return data
  } catch (e) {
    console.error(e)

    // Capture to sentry
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
  }
}

import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import getUserSettings from '../fetch/get-user-settings'
import patchUserSettings from '../fetch/patch-user-settings'
import { SettingsGetResponse, UserSettingsPatchResponse } from '../schemas/settings-schema'
import { chatV2UxSlice } from '@/chat-common/store/chat-v2-ux.slice.ts'
import { selectPromptAssistEnabled, selectPromptAssistLastFetched, selectPromptAssistFetchIsLoading } from '@/chat-common/store/chat-v2-ux.selectors'

// Cache duration in milliseconds (5 seconds)
const SETTINGS_CACHE_DURATION = 5 * 1000

/**
 * Settings Hook
 * Hook to get company + user settings, and update user settings
 * @returns {getSettings} function to get user settings
 * @returns {updateUserSettings} function to update user settings
 */
export function useSettings() {
  const dispatch = useAppDispatch()
  const promptAssistEnabled = useAppSelector(selectPromptAssistEnabled)
  const lastFetched = useAppSelector(selectPromptAssistLastFetched)
  const isLoading = useAppSelector(selectPromptAssistFetchIsLoading)

  /**
   * Get Settings
   *
   * Handles:
   * 1. Check cache and return if valid
   * 2. Request settings from server if cache invalid
   * 3. Inserting the settings into the state
   *
   * @param onSuccess
   * @param onError
   */
  const getSettings = async (onSuccess?: () => void, onError?: (e: any) => void) => {
    // Check if we are already loading
    if (isLoading) return

    try {
      dispatch(chatV2UxSlice.actions.setPromptAssistFetchIsLoading({ isLoading: true }))

      // Check if we have valid cached settings
      const now = Date.now()
      if (lastFetched && now - lastFetched < SETTINGS_CACHE_DURATION) {
        dispatch(chatV2UxSlice.actions.setPromptAssistEnabled({ enabled: promptAssistEnabled }))
        if (onSuccess) onSuccess()
        return
      }

      console.log(`Getting user settings`)

      // Ask the server for settings
      const response: SettingsGetResponse = await getUserSettings()

      dispatch(chatV2UxSlice.actions.setPromptAssistEnabled({ enabled: response.data.user.prompt_assist_enabled ?? true }))
      dispatch(chatV2UxSlice.actions.setPromptAssistLastFetched({ lastFetched: now }))

      // Call the onSuccess callback
      if (onSuccess) onSuccess()
    } catch (e) {
      // Call the onError callback
      if (onError) onError(e)
    } finally {
      dispatch(chatV2UxSlice.actions.setPromptAssistFetchIsLoading({ isLoading: false }))
    }
  }

  /**
   * Update User Settings
   *
   * Handles:
   * 1. Send the user settings to the server
   * 2. Update the user settings in the state
   * 3. Refresh the user settings in the state
   *
   * @param userSettings
   * @param onSuccess
   * @param onError
   */
  const updateUserSettings = async (userSettings: { prompt_assist_enabled: boolean }, onSuccess?: () => void, onError?: (e: any) => void) => {
    try {
      console.log('Updating user settings')

      // Update the user settings
      const response: UserSettingsPatchResponse = await patchUserSettings(userSettings)

      // Update the user settings in state
      dispatch(chatV2UxSlice.actions.setPromptAssistEnabled({ enabled: response.data.prompt_assist_enabled }))

      if (onSuccess) onSuccess()
    } catch (e) {
      // Call the onError callback
      if (onError) onError(e)
    }
  }

  return { getSettings, updateUserSettings }
}

import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { kChatV2ApiConversationsBasePath } from '@/constants/constants-api-paths'
import { ChatV2Feature } from '../store/chat-v2.slice'

export interface PatchGenerateConversationTitleArgs {
  conversationId: string
  query: string
  metadataString: string
  feature: ChatV2Feature
}

/**
 * Generate Conversation Title via PATCH Request
 * @returns
 */
export default async function patchGenerateConversationTitle(args: PatchGenerateConversationTitleArgs): Promise<string> {
  const sentry_transaction_id = nanoid()

  try {
    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw Error(kErrorCodeAuthError)

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        query: args.query,
        metadata_string: args.metadataString,
        feature: args.feature,
      }),
      method: 'PATCH',
    }

    const apiUrl = kPaxtonAppApiBaseUrl() + kChatV2ApiConversationsBasePath + `/${args.conversationId}/generate_title`
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            response: JSON.stringify(response),
            data: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
      throw new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`)
    }

    // Validate the response is a string
    if (typeof data !== 'string') {
      throw new Error('Response is not a string')
    }

    const newTitle = data
    return newTitle
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    throw e
  }
}

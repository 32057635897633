import { selectAgentEventById, selectMostRecentConversationEventId } from '@/agent/events/store/selectors'
import { selectArtifactById } from '@/artifacts/store/selectors'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { selectConversationWebSocketConnected } from '@/websocket_v2/ws2.selectors'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { useEffect, useState } from 'react'

/**
 * Scroll To Bottom Icon Props
 * @param scrollingDivRef - The scrolling div reference
 * @param scrollTrigger - When this value changes, scrolling will be triggered
 */
export type ScrollToBottomIconProps = {
  conversationId: string
  scrollingDivRef: React.RefObject<HTMLDivElement>
}

/**
 * Scroll To Bottom Icon
 * An icon that appears at the bottom of a scrolling div when the user is not at the bottom.
 * Also handles auto-scrolling to the bottom logic to reduce rendering load on parent.
 *
 * IF: The user reaches the bottom, the icon will disappear, and content changes will auto-scroll to the bottom.
 * IF: The user scrolls up, the icon will appear, and auto-scrolling will be disabled.
 *
 * When the user clicks the icon, the scrolling div will scroll to the bottom.
 *
 * When the latest event changes, the scrolling logic will be re-triggered.
 *
 * @param props
 * @returns
 */
export default function ScrollToBottomIcon(props: ScrollToBottomIconProps) {
  const { conversationId, scrollingDivRef } = props

  // Watch the most recent Event in the conversation for changes
  const mostRecentEventId = useAppSelector((state: RootState) => selectMostRecentConversationEventId(state, { conversationId }))
  const mostRecentEvent = useAppSelector((state: RootState) =>
    selectAgentEventById(state, {
      conversationId: conversationId,
      eventId: mostRecentEventId ?? '',
    })
  )

  // Watch whether the conversation is loading
  const isConversationLoading = useAppSelector((state: RootState) => selectConversationWebSocketConnected(state, { conversationId }))

  // Watch the artifact status too
  const artifactVersion = useAppSelector((state: RootState) => selectArtifactById(state, { artifactVersionId: mostRecentEvent?.artifact_version_id }))

  // Scroll + Stick To Bottom
  const [stickToBottom, setStickToBottom] = useState<boolean>(true)
  function scrollToBottom() {
    const container = scrollingDivRef.current
    if (!container) return

    container.scrollTop = container.scrollHeight
  }

  /**
   * Scroll Position Listener
   * When we scroll to the bottom, we want to enable the stick to bottom feature
   */
  useEffect(() => {
    const container = scrollingDivRef.current
    if (!container) return

    const handleScroll = () => {
      const scrollHeight = container.scrollHeight
      const scrollTop = container.scrollTop
      const clientHeight = container.clientHeight

      const bufferHeight = 2
      const scrollPosition = scrollHeight - scrollTop - clientHeight
      const atBottom = scrollPosition < bufferHeight

      // console.log(`Scroll height: ${scrollHeight}, scroll top: ${scrollTop}, client height: ${clientHeight}`)
      // console.log(`Scrolling position: ${scrollPosition}, at bottom? ${atBottom}`)
      if (atBottom) {
        setStickToBottom(true)
      } else {
        setStickToBottom(false)
      }
    }

    container.addEventListener('scroll', handleScroll)
    return () => container?.removeEventListener('scroll', handleScroll)
  }, [scrollingDivRef])

  /**
   * Stick To Bottom
   * If: Stick to bottom is enabled, automatically scroll to the bottom
   *     when new messages are added, or when the most recent message is updated
   *
   * Reruns when:
   * - mostRecentEvent changes
   * - artifactVersion changes
   * - stickToBottom changes
   */
  useEffect(() => {
    const container = scrollingDivRef.current
    if (!container) return

    if (stickToBottom) {
      scrollToBottom()
    }
  }, [mostRecentEvent, stickToBottom, artifactVersion, isConversationLoading])

  return (
    <div
      className={`w-full flex justify-center items-center cursor-pointer ${stickToBottom ? 'hidden' : 'block'}`}
      onClick={() => {
        scrollToBottom()
      }}
    >
      <div
        className={
          'absolute -mt-16 w-8 h-8 grid place-items-center rounded-full bg-brand-500 bg-opacity-50 hover:bg-opacity-80 transition-all duration-500 ease-in-out text-white text-opacity-90'
        }
      >
        <ChevronDownIcon width="24" height="24" />
      </div>
    </div>
  )
}

import { store } from '@/store/store'
import { openAuthDialog } from '@/store/slices/ui-state.slice'
import { AuthDialogType } from '@/store/slices/ui-state.slice'
import { k429RateLimitError, kAnonymousUserLimitMessage429 } from '@/constants/constants-strings'
import { ConversationLoadError } from '@/agent/conversations/store/slice'
import { ApiError } from '../../../util/errors/api-error'
import { getAuth } from 'firebase/auth'

export function mapErrorToConversationLoadError(error: unknown, conversationId: string): ConversationLoadError {
  const isAnonymous = getAuth().currentUser?.isAnonymous

  // Default values
  const defaultLoadingError: ConversationLoadError = {
    code: 'UNKNOWN_ERROR',
    title: 'Error loading chat',
    message: `We could not load the chat with id: ${conversationId}. Please try again.`,
  }

  let loadingError

  if (error instanceof ApiError) {
    const status = error.status
    switch (status) {
      case 404:
        loadingError = {
          code: status.toString(),
          title: 'Chat not found',
          message: `We could not find the chat with id: ${conversationId}.`,
        }
        break
      case 429:
        loadingError = {
          code: status.toString(),
          title: 'Too many requests',
          message: k429RateLimitError,
        }

        // If user is anonymous, prompt to sign up
        if (isAnonymous) {
          store.dispatch(
            openAuthDialog({
              authDialogType: AuthDialogType.SIGN_UP,
              tooltipMessage: kAnonymousUserLimitMessage429,
            })
          )
        }
        break
      default:
        loadingError = {
          code: status.toString(),
          title: 'Error Loading Conversation',
          message: 'An unexpected error occurred. Please try again.',
        }
        break
    }
  } else {
    // Default error
    loadingError = defaultLoadingError
  }

  return loadingError
}

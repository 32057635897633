import React, { ReactElement } from 'react'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import rehypeWrap from 'rehype-wrap-all'
import rehypeTagNameChange from '@/util/rehype-custom-plugins/rehype-tag-swap'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'

interface EventTextMarkdownProps {
  conversationId: string
  eventId: string
}

/**
 * Event Text Markdown
 * Renders the event's value (text response) as Markdown.
 * TODO: Add support for footnotes once references are implemented.
 * Note: Functionality copied over from chatV2FormatResponseString for consistency between agent and legacy conversations.
 */
const EventTextMarkdown: React.FC<EventTextMarkdownProps> = ({ conversationId, eventId }): ReactElement | null => {
  const event = useAppSelector((state: RootState) => state.agentEventsState.events[conversationId]?.[eventId])

  if (!event || !event.value) {
    console.error('EventTextMarkdown: Event or event value is missing.')
    return null
  }

  // Trim whitespace
  const trimmedText = event.value.trim()

  return (
    <Markdown
      className="markdown-bubble-container max-w-3xl"
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[
        rehypeRaw,
        [rehypeWrap, { selector: 'table', wrapper: 'div.source-html-table-wrapper' }],
        [
          // Swap pre and code tags with div tags to fix markdown rendering issues
          rehypeTagNameChange,
          [
            { tagName: 'pre', newTagName: 'div' },
            { tagName: 'code', newTagName: 'div' },
          ],
        ],
      ]}
      components={{
        div: ({ children, ...props }) => {
          // map the children and if there is a child string that contains any more than 2 '/n' for example
          // "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n" then repalce it with a max of a 2 new line '\n\n'
          const newLineRegex = /\n{2,}/g
          const filteredChildren = Array.isArray(children)
            ? children.map((child) => {
                if (typeof child === 'string') {
                  return child.replace(newLineRegex, '\n\n')
                }
                return child
              })
            : children

          return <div {...props}>{filteredChildren}</div>
        },
        li: ({ children, ...props }) => {
          // filter the children and if there is a child string of '\n' then remove it
          const filteredChildren = Array.isArray(children) ? children.filter((child) => child !== '\n') : children

          return <li {...props}>{filteredChildren}</li>
        },
      }}
    >
      {trimmedText}
    </Markdown>
  )
}

export default EventTextMarkdown

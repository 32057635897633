import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { AuthDialogType, closeAuthDialog, openAuthDialog, switchAuthDialog } from '@/store/slices/ui-state.slice'
import SignInForm from './sign-in-form'
import SignUpForm from './sign-up-form'
import SignInFormPaxton from './sign-in-form-paxton'
import MicrosoftSSOForm from '@/components/auth/microsoft/microsoft-sso-form'
import { useNavigate } from 'react-router-dom'
import SendPasswordResetForm from './send-password-reset-form'
import { getBrandFromHost, overridePaxton } from '@/util/enterprise'
import { BrandEnum } from '@/firebase/auth/auth-jwt-schema'

const hostBrand = getBrandFromHost()

export default function AuthDialog() {
  // Watch global UI state
  const { showAuthDialog, authDialogType, authDialogTooltipMessage } = useAppSelector((state: RootState) => state.uiState)
  const overrideHaikuSSO = overridePaxton()
  const isSignInOrSignUp = authDialogType === AuthDialogType.SIGN_IN || authDialogType === AuthDialogType.SIGN_UP

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <Transition.Root show={showAuthDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => dispatch(closeAuthDialog())}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-brand-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto lg:ml-[300px]">
          <div className="flex min-h-full items-center justify-center p-2 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-brand-neutral-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all w-full max-w-md sm:my-8 sm:p-6">
                {authDialogType === AuthDialogType.SIGN_IN && hostBrand !== BrandEnum.HAIKU && (
                  <SignInForm
                    switchToForgotPassword={() => {
                      dispatch(switchAuthDialog(AuthDialogType.FORGOT_PASSWORD))
                    }}
                    switchToSignUp={() => dispatch(openAuthDialog({ authDialogType: AuthDialogType.SIGN_UP }))}
                    onSuccess={() => {
                      dispatch(closeAuthDialog())
                      navigate('/dashboard')
                    }}
                    tooltipMessage={authDialogTooltipMessage}
                  />
                )}
                {authDialogType === AuthDialogType.FORGOT_PASSWORD && (
                  <SendPasswordResetForm
                    goToSignIn={() => {
                      dispatch(switchAuthDialog(AuthDialogType.SIGN_IN))
                    }}
                    formTitle="Password Reset"
                    formDescription="You will receive an email with a link to reset your password."
                    buttonText="Submit"
                  />
                )}
                {authDialogType === AuthDialogType.SIGN_UP && hostBrand !== BrandEnum.HAIKU && (
                  <SignUpForm
                    switchToSignIn={() => dispatch(openAuthDialog({ authDialogType: AuthDialogType.SIGN_IN }))}
                    onSuccess={() => {
                      navigate('/dashboard')
                      dispatch(closeAuthDialog())
                    }}
                    tooltipMessage={authDialogTooltipMessage}
                    allowReferral={true}
                  />
                )}
                {isSignInOrSignUp && hostBrand === BrandEnum.HAIKU && !overrideHaikuSSO && (
                  <MicrosoftSSOForm
                    onSuccess={() => {
                      dispatch(closeAuthDialog())
                      navigate('/dashboard')
                    }}
                  />
                )}
                {isSignInOrSignUp && hostBrand === BrandEnum.HAIKU && overrideHaikuSSO && (
                  <SignInFormPaxton
                    onSuccess={() => {
                      dispatch(closeAuthDialog())
                      navigate('/dashboard')
                    }}
                    tooltipMessage={authDialogTooltipMessage}
                  />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

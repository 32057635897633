import { UploadTaskStatus } from '@/routes/dashboard/files/FilesDropZoneWithTaskState'
import { RootState } from '@/store/store'
import { createSelector, createSelectorCreator, lruMemoize } from '@reduxjs/toolkit'
import { FileProcessingStatus } from '../slices/file-upload-tasks.slice'

/**
 * Equality Check: Processing Status Keys
 * Custom equality check - needed because creating new object or array
 * Only recompute the selector if the previous and next objects are different
 * Compares only the filtered new object
 */
const equalityCheckProcessingStatusKeys = (prev: { keys: string[] }, next: { keys: string[] }) => {
  // Deep equality check
  return JSON.stringify(prev) === JSON.stringify(next)
}

// Create a custom selector with the custom equality function
const createFileProcessingStatusesWithKeysSelector = createSelectorCreator(lruMemoize, equalityCheckProcessingStatusKeys)

/**
 * SELECTOR: Selects a the uploading tasks list with the tasks that have the provided chatId.
 * Select File Upload Tasks With Keys
 * For a given input of filePaths, return the processing statuses of the selected files
 */

export const selectFileUploadTasksWithKeys = createFileProcessingStatusesWithKeysSelector(
  // Input: upload tasks
  (state: RootState) => state.uploadFileTasksState.uploadTasks,

  // Input 2 - keys needed for filtering
  (_: RootState, props: { keys: string[] }) => props,

  (uploadTasks, props) => {
    const { keys } = props

    // Return upload tasks with matching keys
    return keys.reduce((acc: Record<string, UploadTaskStatus>, key: string) => {
      if (uploadTasks[key]) {
        acc[key] = uploadTasks[key] as UploadTaskStatus
      }

      return acc
    }, {} as Record<string, UploadTaskStatus>)
  },
  {
    memoizeOptions: {
      // Custom equality check - needed because creating new object or array
      // Only recompute the selector if the previous and next objects are different
      // Compares only the filtered new object
      equalityCheck: equalityCheckProcessingStatusKeys,
    },
  }
)

/**
 * Select File Processing Statuses
 * Return true if there are any processing current happening.
 */
export const selectFileProcessingStatuses = createSelector(
  (state: RootState) => state.uploadFileTasksState.processingStatuses,
  // Input: processing statuses
  (processingStatuses) => {
    return Object.values(processingStatuses).some((status) => status === FileProcessingStatus.PROCESSING || status === FileProcessingStatus.ERROR)
  }
)

/**
 * Select File Processing Statuses With Keys
 * For a given input of filePaths, return the processing statuses of the selected files
 */
export const selectFileProcessingStatusesWithKeys = createFileProcessingStatusesWithKeysSelector(
  // Input: processing statuses
  (state: RootState) => state.uploadFileTasksState.processingStatuses,

  // Input 2 - keys needed for filtering
  (_: RootState, props: { keys: string[] }) => props,

  (processingStatuses, props) => {
    const { keys } = props

    // Return processing statuses with matching keys
    return keys.reduce((acc: Record<string, string>, key: string) => {
      if (processingStatuses[key]) {
        acc[key] = processingStatuses[key] as string
      }

      return acc
    }, {} as Record<string, string>)
  },
  {
    memoizeOptions: {
      equalityCheck: equalityCheckProcessingStatusKeys,
    },
  }
)

/**
 * Select Files Should Block Submission
 * Returns true if any of the selected files are still processing or have errored
 */
export const selectFilesShouldBlockSubmission = createSelector(
  // Input: processing statuses
  (state: RootState) => state.uploadFileTasksState.processingStatuses,

  // Input 2 - keys needed for filtering
  (_: RootState, props: { keys: string[] }) => props,

  (processingStatuses, props) => {
    const { keys } = props

    // Check if any of the selected files are still processing or have errored
    return keys.some((key) => processingStatuses[key] === FileProcessingStatus.PROCESSING || processingStatuses[key] === FileProcessingStatus.ERROR)
  }
)

/**
 * Selector: selectSelectedFilesAreProcessingOrPending
 * Returns true if any of the selected files are still processing or pending
 *
 * Flow:
 * - The selectedFiles path corresponds to the processing status keys
 * - For each selected file, check if it is still processing
 * - If any are pending or processing, return true
 */
export const selectSelectedFilesAreProcessingOrPending = createSelector(
  // Input: upload processing statuses
  (state: RootState) => state.uploadFileTasksState.processingStatuses,

  // Input: selectedFiles
  (state: RootState, props: { chatId: string }) => state.chatV2State.conversations[props.chatId]?.currentSource,

  // Logic: Check if any of the selected files are still processing
  (processingStatuses, currentSource) => {
    const selectedFiles: string[] = currentSource?.selected_files || []

    return selectedFiles.some((file) => {
      return processingStatuses[file] === FileProcessingStatus.PROCESSING || processingStatuses[file] === FileProcessingStatus.PENDING
    })
  }
)

import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import {
  chatV2CurrentSourceAssistantAddFile,
  chatV2CurrentSourceAssistantClearAllFiles,
  chatV2CurrentSourceAssistantToggleSelectedFile,
} from '@/chat-common/store/chat-v2.slice'
import { setSelectedFilesContentExpanded } from '@/chat-common/store/chat-v2-ux.slice'
import { SourceSelectionDialog } from './source-selection-dialog'
import { RootState } from '@/store/store'
import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'

type AssistantDocumentSelectionDialogProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversationId: string
}

/**
 * AssistantDocumentSelectionDialog component.
 *
 * This component is used exclusively in the Assistant features
 * to allow the user to select documents for the Assistant to use by wrapping the SourceSelectionDialog component with the appropriate dispatch functions.
 *
 * @param props - The properties for the AssistantDocumentSelectionDialog component.
 * @returns A JSX element representing the document selection dialog.
 */
export default function AssistantDocumentSelectionDialog(props: AssistantDocumentSelectionDialogProps) {
  const { open, onClose, conversationId } = props
  const dispatch = useAppDispatch()

  // Check if agent conversation
  const agentConversation = useAppSelector((state: RootState) => state.agentConversationsState.conversations[conversationId])

  const toggleFileSelection = (file: string) => {
    dispatch(setSelectedFilesContentExpanded({ conversationId: conversationId, expanded: true }))

    if (agentConversation) {
      dispatch(AgentConversationFormActions.removeFile({ conversationId: conversationId, filePath: file }))
    } else {
      dispatch(chatV2CurrentSourceAssistantToggleSelectedFile({ conversationId: conversationId, filePath: file }))
    }
  }

  const addFileSelection = (file: string) => {
    dispatch(setSelectedFilesContentExpanded({ conversationId: conversationId, expanded: true }))

    if (agentConversation) {
      dispatch(AgentConversationFormActions.addFile({ conversationId: conversationId, filePath: file }))
    } else {
      dispatch(chatV2CurrentSourceAssistantAddFile({ conversationId: conversationId, filePath: file }))
    }
  }

  const clearFilesSelection = () => {
    if (agentConversation) {
      dispatch(AgentConversationFormActions.clearFiles({ conversationId: conversationId }))
    } else {
      dispatch(chatV2CurrentSourceAssistantClearAllFiles({ conversationId: conversationId }))
    }
  }

  return (
    <SourceSelectionDialog
      conversationId={conversationId}
      open={open}
      onClose={onClose}
      dispatchFunctions={{
        addFileSelection,
        clearFilesSelection,
        toggleFileSelection,
      }}
    />
  )
}

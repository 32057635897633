import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext, useState } from 'react'
import {
  MenuOutlined,
  CloseOutlined,
  Compare,
  Gavel,
  MenuBook,
  TravelExplore,
  History,
  LiveHelp,
  Try,
  DocumentScanner,
  FindInPage,
  Lan,
  Inventory,
  JoinFull,
  Summarize,
  AccountBalance,
  DriveFileRenameOutlineRounded,
  CollectionsBookmark,
} from '@mui/icons-material'
import { useMatch } from 'react-router'
import { Link, PathMatch, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { AuthDialogType, openAuthDialog, setReferralDialogVisible, SidebarItemId } from '@/store/slices/ui-state.slice'
import { AuthContext } from '@/context/auth-context'
import { kPaxtonEmailToHref } from '@/constants/constants-strings'
import { PaxtonDisclaimer } from '@/constants/constants-components'
import DashboardMobileConnectionBanner from '../dashboard-mobile-connection-banner'
import DashboardMobileChatHistory from '../dashboard-mobile-chat-history'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import ChatV2AnonFeatureGuard from '@/components/guards/chat-v2-anon-feature-guard'
import DashboardDesktopConnectionBanner from '../dashboard-desktop-connection-banner'
import DashboardSidebarAccountMenu from './dashboard-sidebar-account-menu'
import { kSegmentTrackOpenSupportChatClicked } from '@/constants/constants-segment'
import { useHubspotConversations } from '@/context/hubspot-conversations-provider'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { BrandEnum, SubscriptionStatus } from '@/firebase/auth/auth-jwt-schema'
import { isPaxtonBrand } from '@/util/enterprise'
import CollapsibleItem from './collapsible-item'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material'
import { GiftIcon } from '@heroicons/react/24/outline'
import { selectLastViewedConversationIdRecord } from '@/chat-common/store/chat-v2-ux.selectors'
import { kDefaultLogoPath } from '@/constants/constants-ui'
import NewChatButton from '@/chat-common/new_chat/components/new-chat-button'
import ChatListSidebar from '@/chat-common/conversation_list/components/chat-list-sidebar'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export type NavigationItem = {
  name: string
  href: string
  icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string
  }
  current: boolean | PathMatch<string>
  new?: boolean
  subItem?: boolean
  organizationRequired?: boolean
  allowAnon: boolean
  excludedBrands?: BrandEnum[]
}

type NavigationCategory = {
  name: string
  id: SidebarItemId
  items: NavigationItem[]
  excludedBrands?: BrandEnum[]
}

type DashboardSidebarProps = {
  hideSidebars: boolean
}

export default function DashboardSidebar(props: DashboardSidebarProps) {
  const { hideSidebars } = props
  const { trackEvent } = useAnalytics()
  const { chatFeature } = useParams()
  const [chatHistoryMobileOpen, setChatHistoryMobileOpen] = useState(false)
  const { toggleWidget } = useHubspotConversations()
  const isPaxtonHost = isPaxtonBrand()
  const [sidebarOpen, setSidebarOpen] = useState(false)

  // Check if this is a valid chat feature (will be false for non-chat features)
  const validChatFeature: boolean = Object.values(ChatV2Feature).includes(chatFeature as ChatV2Feature)

  // Auth Context
  const authContext = useContext(AuthContext)
  const { user, userAccountData, logoUrl } = authContext
  const userIsAnon = user?.isAnonymous
  const brand = userAccountData?.brand ?? BrandEnum.NONE
  const isPaxton = userAccountData?.isPaxton ?? true
  const isPaidSubscriber = userAccountData?.subscriptionStatus === SubscriptionStatus.PAID
  const isOrgAccount = userAccountData?.isOrgAccount
  const showReferralButton = !userIsAnon && isPaxton && !isOrgAccount && isPaidSubscriber
  const isPaxtonLogo = logoUrl === kDefaultLogoPath

  const dispatch = useAppDispatch()

  // Get the last viewed conversations record
  const lastViewedConversationIdRecord = useAppSelector(selectLastViewedConversationIdRecord)

  // ConversationIds for each feature
  const lastViewedResearchId = lastViewedConversationIdRecord[ChatV2Feature.research]
  const lastViewedLrrV2Id = lastViewedConversationIdRecord[ChatV2Feature.lrr_v2]
  const lastViewedCaseLawId = lastViewedConversationIdRecord[ChatV2Feature.caselaw]
  const lastViewedWebSearchId = lastViewedConversationIdRecord[ChatV2Feature.websearch]
  const lastViewedAssistantId = lastViewedConversationIdRecord[ChatV2Feature.assistant]
  const lastViewedDocumentQueryId = lastViewedConversationIdRecord[ChatV2Feature.documentquery]
  const lastViewedDocumentDraftingId = lastViewedConversationIdRecord[ChatV2Feature.drafting]
  const lastViewedContractSearchId = lastViewedConversationIdRecord[ChatV2Feature.contractsearch]
  const lastViewedDocumentSummarizationId = lastViewedConversationIdRecord[ChatV2Feature.documentSummarization]

  // Route matches
  const orgDriveMatch = useMatch('/dashboard/drives/org-drive') ?? false
  const featureAssistant = useMatch(`/dashboard/chat/${ChatV2Feature.assistant}/*`)

  const navigationCategories: NavigationCategory[] = [
    {
      name: 'Drafting & Analysis',
      id: SidebarItemId.DraftAnalysis,
      items: [
        {
          name: 'AI Assistant',
          href: lastViewedAssistantId ? `/dashboard/chat/${ChatV2Feature.assistant}/${lastViewedAssistantId}` : `/dashboard/chat/${ChatV2Feature.assistant}`,
          icon: Try,
          new: true,
          current: featureAssistant ?? false,
          allowAnon: false,
        },
        {
          name: 'Drafting',
          href: lastViewedDocumentDraftingId ? `/dashboard/chat/drafting/${lastViewedDocumentDraftingId}` : '/dashboard/chat/drafting',
          icon: DriveFileRenameOutlineRounded,
          current: useMatch('/dashboard/chat/drafting/*') ?? false,
          allowAnon: false,
          subItem: true,
        },
        {
          name: 'File Analysis',
          href: lastViewedDocumentQueryId
            ? `/dashboard/chat/${ChatV2Feature.documentquery}/${lastViewedDocumentQueryId}`
            : `/dashboard/chat/${ChatV2Feature.documentquery}`,
          icon: CollectionsBookmark,
          current: useMatch(`/dashboard/chat/${ChatV2Feature.documentquery}/*`) ?? false,
          allowAnon: false,
          subItem: true,
        },
      ],
    },
    {
      name: 'Research',
      id: SidebarItemId.Research,
      items: [
        {
          name: 'Combined Research',
          href: lastViewedResearchId ? `/dashboard/chat/${ChatV2Feature.research}/${lastViewedResearchId}` : `/dashboard/chat/${ChatV2Feature.research}`,
          icon: AccountBalance,
          current: useMatch(`/dashboard/chat/${ChatV2Feature.research}/*`) ?? false,
          allowAnon: false,
        },
        {
          name: 'Laws & Regulations',
          href: lastViewedLrrV2Id ? `/dashboard/chat/lrr_v2/${lastViewedLrrV2Id}` : '/dashboard/chat/lrr_v2',
          icon: MenuBook,
          current: useMatch('/dashboard/chat/lrr_v2/*') ?? false,
          allowAnon: isPaxtonHost ? true : false,
          subItem: true,
        },
        {
          name: 'Case Law',
          href: lastViewedCaseLawId ? `/dashboard/chat/caselaw/${lastViewedCaseLawId}` : '/dashboard/chat/caselaw',
          icon: Gavel,
          current: useMatch('/dashboard/chat/caselaw/*') ?? false,
          allowAnon: false,
          subItem: true,
        },
      ],
    },
    {
      name: 'Drive',
      id: SidebarItemId.Drives,
      items: [
        {
          name: 'My Drive',
          href: '/dashboard/drives/my-drive',
          icon: Inventory,
          current: useMatch('/dashboard/drives/my-drive') ?? false,
          allowAnon: false,
        },

        // Organization required
        ...(userAccountData?.isOrgAccount
          ? [
              {
                name: 'Shared Drive',
                href: '/dashboard/drives/org-drive',
                icon: Lan,
                current: orgDriveMatch,
                allowAnon: false,
                organizationRequired: true,
                excludedBrands: [BrandEnum.HAIKU],
              },
            ]
          : []),
      ],
    },
    {
      name: 'Tools',
      id: SidebarItemId.Tools,
      items: [
        // {
        //   name: 'Contract Analysis',
        //   href: lastViewedContractAnalysis
        //     ? `/dashboard/chat/${ChatV2Feature.contractanalysis}/${lastViewedContractAnalysis}`
        //     : `/dashboard/chat/${ChatV2Feature.contractanalysis}`,
        //   icon: DocumentScanner,
        //   new: true,
        //   current: useMatch(`/dashboard/chat/${ChatV2Feature.contractanalysis}/*`) ?? false,
        //   allowAnon: false,
        // },
        {
          name: 'File Summarizer',
          href: lastViewedDocumentSummarizationId
            ? `/dashboard/chat/${ChatV2Feature.documentSummarization}/${lastViewedDocumentSummarizationId}`
            : `/dashboard/chat/${ChatV2Feature.documentSummarization}`,
          icon: Summarize,
          new: true,
          current: useMatch(`/dashboard/chat/${ChatV2Feature.documentSummarization}/*`) ?? false,
          allowAnon: false,
        },
        {
          name: 'Contract Analysis',
          href: '/dashboard/document-editing/list',
          icon: DocumentScanner,
          new: true,
          current: useMatch('/dashboard/document-editing/*') ?? false,
          allowAnon: false,
        },
        {
          name: 'Contract Search',
          href: lastViewedContractSearchId
            ? `/dashboard/chat/${ChatV2Feature.contractsearch}/${lastViewedContractSearchId}`
            : `/dashboard/chat/${ChatV2Feature.contractsearch}`,
          icon: FindInPage,
          new: true,
          current: useMatch(`/dashboard/chat/${ChatV2Feature.contractsearch}/*`) ?? false,
          allowAnon: false,
          excludedBrands: [BrandEnum.HAIKU],
        },
        {
          name: 'Web & News',
          href: lastViewedWebSearchId ? `/dashboard/chat/websearch/${lastViewedWebSearchId}` : '/dashboard/chat/websearch',
          icon: TravelExplore,
          current: useMatch('/dashboard/chat/websearch/*') ?? false,
          allowAnon: false,
        },
        {
          name: 'Boolean Composer',
          href: '/dashboard/boolean-composer',
          icon: JoinFull,
          current: useMatch('/dashboard/boolean-composer') ?? false,
          allowAnon: false,
          excludedBrands: [BrandEnum.HAIKU],
        },
        {
          name: 'Compare Documents',
          href: '/dashboard/audit',
          icon: Compare,
          current: useMatch('/dashboard/audit') ?? false,
          allowAnon: false,
          excludedBrands: [BrandEnum.HAIKU],
        },
      ],
    },
  ] // filter out excluded brands in both items and categories
    .map((category) => {
      return {
        ...category,
        items: category.items.filter((item) => !(item as { excludedBrands?: BrandEnum[] }).excludedBrands?.includes(brand)),
      }
    })
    // Filter categories and when there are no items
    .filter((category) => category.items.length > 0 && !(category as { excludedBrands?: BrandEnum[] }).excludedBrands?.includes(brand))

  // Anonymous user lower menu
  // Because we don't show the top dropdown menu to anon users
  const anonLowerMenu = [
    {
      name: 'Chat with support',
      icon: LiveHelp,
      current: false,
      onClick: () => {
        // Track
        analytics.track(kSegmentTrackOpenSupportChatClicked)

        toggleWidget()
      },
      excludedBrands: [BrandEnum.HAIKU],
    },
  ].filter((item) => !item.excludedBrands?.includes(brand))

  // Anonymous user sign in / sign up links component
  function AnonSignInSignUpButtons() {
    if (!userIsAnon) {
      return null
    }

    return (
      <>
        <div className="px-2 flex flex-row gap-2">
          {isPaxtonHost && (
            <button
              onClick={() => {
                dispatch(openAuthDialog({ authDialogType: AuthDialogType.SIGN_UP }))
                setSidebarOpen(false)
              }}
              className="flex items-center justify-center gap-x-2 w-full px-4 py-2 text-sm font-medium text-white bg-brand-500 hover:bg-brand-400 rounded-md"
            >
              Sign up
            </button>
          )}
          <button
            onClick={() => {
              dispatch(openAuthDialog({ authDialogType: AuthDialogType.SIGN_IN }))
              setSidebarOpen(false)
            }}
            className="flex items-center justify-center gap-x-2 w-full px-4 py-2 text-sm font-medium text-brand-500 bg-brand-neutral-50 hover:bg-brand-neutral-100 rounded-md"
          >
            Sign in
          </button>
        </div>
      </>
    )
  }

  return (
    <>
      {/* Mobile Chat History */}
      <ChatV2AnonFeatureGuard showAuthOnGuard={false}>
        <DashboardMobileChatHistory sidebarOpen={chatHistoryMobileOpen} setSidebarOpen={setChatHistoryMobileOpen} />
      </ChatV2AnonFeatureGuard>

      {/* Mobile Top Bar + Slide-Out Sidebar Nav */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-brand-neutral-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-2xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <CloseOutlined className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 h-full overflow-y-scroll bg-brand-neutral-50 px-2 pt-4 pb-2">
                  {(isPaxton || !userIsAnon) && (
                    <Link to={'/dashboard'} onClick={() => setSidebarOpen(false)} className={'flex items-center'}>
                      <img
                        className={`max-w-full max-h-[100px] mx-auto ${isPaxtonLogo ? 'py-1 px-3' : ''}`}
                        src={logoUrl}
                        alt={!isPaxton ? brand.toUpperCase() : 'Paxton AI'}
                      />
                    </Link>
                  )}

                  <AnonSignInSignUpButtons />
                  <DashboardSidebarAccountMenu
                    onSelect={() => {
                      setSidebarOpen(false)
                    }}
                  />

                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="">
                          {navigationCategories.map((category, index) => {
                            return (
                              <CollapsibleItem
                                key={`${category}-${index}`}
                                items={category.items}
                                title={category.name}
                                isAnonUser={userIsAnon ?? false}
                                setSidebarOpen={setSidebarOpen}
                                itemId={category.id}
                              />
                            )
                          })}
                        </ul>
                      </li>
                      {userIsAnon && (
                        <li className="mt-auto mb-4">
                          <ul role="list" className="mt-2 space-y-1">
                            {anonLowerMenu.map((item) => (
                              <li key={item.name}>
                                <button
                                  onClick={() => {
                                    item.onClick()
                                    setSidebarOpen(false)
                                  }}
                                  className={classNames(
                                    item.current
                                      ? 'bg-brand-neutral-50 text-brand-500'
                                      : 'text-brand-neutral-700 hover:text-brand-500 hover:bg-brand-neutral-50',
                                    'w-auto group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium cursor-pointer'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? 'text-brand-500' : 'text-brand-neutral-500 group-hover:text-brand-500',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </button>
                              </li>
                            ))}
                          </ul>
                        </li>
                      )}
                    </ul>
                    {showReferralButton ? (
                      <button
                        onClick={() => {
                          setSidebarOpen(false)
                          dispatch(setReferralDialogVisible(true))
                          trackEvent(AnalyticsEvent.ReferralDialogOpened)
                        }}
                        className="bg-brand-500 hover:bg-brand-400 text-white font-bold py-1 px-4 mb-3 rounded flex items-center justify-center"
                      >
                        <GiftIcon className="h-5 w-5 mr-2" />
                        Get 1 Month Free
                      </button>
                    ) : (
                      isPaxton && (
                        <button
                          onClick={() => trackEvent(AnalyticsEvent.SharePaxtonClicked)}
                          className="bg-brand-500 hover:bg-brand-400 text-white font-bold py-1 px-4 mb-3 rounded"
                        >
                          <a href={kPaxtonEmailToHref} className="text-white">
                            Share Paxton
                          </a>
                        </button>
                      )
                    )}
                    {isPaxton && (
                      <div className="mb-1 text-2xs text-brand-neutral-600">
                        <PaxtonDisclaimer />
                      </div>
                    )}

                    <div
                      className="mb-2 text-2xs text-brand-neutral-500 whitespace-nowrap"
                      onClick={async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        // If user held command key while clicking, log the id token
                        if (event.metaKey) {
                          console.log(await user?.getIdToken(true))
                        }
                      }}
                    >
                      v{APP_VERSION}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className={'hidden lg:flex h-full '}>
        <div className={`inset-y-0 z-50 flex flex-col overflow-hidden transition-all ease-in-out duration-500 ${hideSidebars ? 'w-0' : 'w-60'}`}>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="h-full w-60 flex flex-col pt-3 px-2 gap-y-3 overflow-y-auto overflow-x-clip border-r border-brand-neutral-200 bg-brand-neutral-50">
            <DashboardDesktopConnectionBanner />
            {(isPaxton || !userIsAnon) && (
              <div className="w-full items-center ">
                <Link to={'/dashboard'}>
                  <img
                    className={`max-w-full max-h-[100px] mb-1 mx-auto ${isPaxtonLogo ? 'py-1 px-3' : ''}`}
                    src={logoUrl}
                    alt={brand == BrandEnum.NONE ? 'Paxton AI' : brand}
                  />
                </Link>
              </div>
            )}

            <AnonSignInSignUpButtons />
            <DashboardSidebarAccountMenu
              onSelect={() => {
                setSidebarOpen(false)
              }}
            />
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="pt-2">
                    {navigationCategories.map((category, index) => {
                      return (
                        <CollapsibleItem
                          key={`${category}-${index}`}
                          items={category.items}
                          title={category.name}
                          isAnonUser={userIsAnon ?? false}
                          setSidebarOpen={setSidebarOpen}
                          itemId={category.id}
                        />
                      )
                    })}
                  </ul>
                </li>
                {userIsAnon && (
                  <li className="mt-auto mb-4">
                    <ul role="list" className="mt-2 space-y-1">
                      {anonLowerMenu.map((item) => (
                        <li key={item.name}>
                          <button
                            onClick={() => {
                              item.onClick()
                              setSidebarOpen(false)
                            }}
                            className={classNames(
                              item.current ? 'bg-brand-neutral-50 text-brand-500' : 'text-brand-neutral-700 hover:text-brand-500 hover:bg-brand-neutral-50',
                              'w-auto group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium cursor-pointer'
                            )}
                          >
                            <item.icon
                              className={classNames(item.current ? 'text-brand-500' : 'text-brand-neutral-500 group-hover:text-brand-500', 'h-6 w-6 shrink-0')}
                              aria-hidden="true"
                            />
                            {item.name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </li>
                )}
              </ul>

              {showReferralButton ? (
                <button
                  onClick={() => {
                    dispatch(setReferralDialogVisible(true))
                    trackEvent(AnalyticsEvent.ReferralDialogOpened)
                  }}
                  className="bg-brand-500 hover:bg-brand-400 text-white font-bold py-1 px-4 mb-3 rounded flex items-center justify-center"
                >
                  <GiftIcon className="h-5 w-5 mr-2" />
                  Get 1 Month Free
                </button>
              ) : (
                isPaxton && (
                  <button
                    onClick={() => trackEvent(AnalyticsEvent.SharePaxtonClicked)}
                    className="bg-brand-500 hover:bg-brand-400 text-white font-bold py-1 px-4 mb-3 rounded"
                  >
                    <a href={kPaxtonEmailToHref} className="text-white">
                      Share Paxton
                    </a>
                  </button>
                )
              )}

              {isPaxton && (
                <div className="mb-1 text-2xs text-brand-neutral-600">
                  <PaxtonDisclaimer />
                </div>
              )}
              <div
                className="mb-2 text-2xs text-brand-neutral-500 whitespace-nowrap"
                onClick={async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                  // If user held command key while clicking, log the id token
                  if (event.metaKey) {
                    console.log(await user?.getIdToken(true))
                  }
                }}
              >
                v{APP_VERSION}
              </div>
            </nav>
          </div>
        </div>

        {/* Desktop: Chat List Sidebar */}
        {/* Only display for valid features */}
        {validChatFeature && (
          <ChatV2AnonFeatureGuard showAuthOnGuard={false}>
            <div className={`overflow-y-scroll transition-all ease-in-out duration-500 ${hideSidebars ? 'w-0' : 'w-64 3xl:w-80'}`}>
              <div className={`px-2 py-2 w-full`}>
                <NewChatButton />
                {featureAssistant && (
                  <div className="mt-1">
                    <NewChatButton createOldAssistantChat={true} />
                  </div>
                )}
              </div>
              <ChatListSidebar />
            </div>
          </ChatV2AnonFeatureGuard>
        )}
      </div>

      {/* Mobile Top Bar */}
      <div className="h-12 w-full top-0 z-40 flex items-center bg-brand-neutral-50 pr-3 shadow-sm lg:hidden">
        <button type="button" className="px-3 pt-1 pb-1.5 text-brand-neutral-700" onClick={() => setSidebarOpen(true)}>
          <span className="sr-only">Open sidebar</span>
          <MenuOutlined className="h-6 w-6" aria-hidden="true" />
        </button>

        <div className="w-full flex items-center justify-between text-sm font-medium text-brand-neutral-900">
          <div className="flex gap-x-3 items-center">
            <div className="text-base font-extrabold tracking-tighter">
              {isPaxtonHost ? <img className="w-20" src={kDefaultLogoPath} alt="PAXTON" /> : brand.toUpperCase()}
            </div>
          </div>

          <div className="flex">
            <DashboardMobileConnectionBanner />

            {/* Only show if we're on a valid feature */}
            {validChatFeature && (
              <ChatV2AnonFeatureGuard showAuthOnGuard={false}>
                <button
                  type="button"
                  className="ml-3 p-2 text-brand-neutral-700 hover:text-brand-neutral-500"
                  onClick={() => setChatHistoryMobileOpen(true)}
                  aria-label="Open chat history"
                  title="History"
                >
                  <History style={{ fontSize: '28px' }} />
                </button>
                <NewChatButton isCompact />
              </ChatV2AnonFeatureGuard>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

/**
 * Represents an error that occurs during the fetching of an artifact.
 */
export class ArtifactFetchError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ArtifactFetchError'
  }
}

/**
 * Represents an error that occurs during the processing of an artifact.
 */
export class ArtifactProcessingError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ArtifactProcessingError'
  }
}

import { FileUploadContext } from '@/context/file-upload-context'
import { AttachFileOutlined, Upload, FolderOpen } from '@mui/icons-material'
import * as Popover from '@radix-ui/react-popover'
import { useContext, useState } from 'react'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'
import { ChatV2QueryMetadataAssistant, ModeChoice } from '@/chat-common/schemas/chat-query-metadata-schema'
import { UploadDisclaimer } from '@/constants/constants-components'
import AssistantDocumentSelectionDialog from '../../source-dialogs/selection-dialog-assistant'

type FileActionMenuProps = {
  disabled?: boolean
  conversationId: string
}

enum FileAction {
  Upload = 'upload',
  Select = 'select',
}

const fileActions = [
  {
    value: FileAction.Upload,
    label: 'Upload Files',
    description: 'Select documents from your computer',
    icon: <Upload />,
  },
  {
    value: FileAction.Select,
    label: 'Add from File Manager',
    description: "Select documents you've already uploaded",
    icon: <FolderOpen />,
  },
]

export default function FileActionMenu(props: FileActionMenuProps) {
  const { disabled, conversationId } = props
  const [open, setPopoverOpen] = useState<boolean>(false)
  const [docSourceDialogIsOpen, setDocSourceDialogOpen] = useState<boolean>(false)
  const { openSystemFileSelector } = useContext(FileUploadContext)

  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataAssistant | null

  const selectedFiles = currentSource?.selected_files ?? []
  const selectedMode = currentSource?.focus_mode
  const highlightAttachIcon = selectedFiles.length === 0 && selectedMode === ModeChoice.DOCUMENTQUERY

  const handleSelection = (action: FileAction) => {
    setPopoverOpen(false)
    if (action === FileAction.Upload) {
      openSystemFileSelector()
    } else if (action === FileAction.Select) {
      setDocSourceDialogOpen(true)
    }
  }

  return (
    <div className="flex">
      <Popover.Root open={open} onOpenChange={setPopoverOpen}>
        <Popover.Trigger asChild>
          <button
            onClick={() => setPopoverOpen(!open)}
            className={`${
              disabled ? 'opacity-50 cursor-default' : 'opacity-none hover:text-brand-500 cursor-pointer'
            } text-gray-700 w-[30px] h-[35px] inline-flex items-center justify-center
            ${highlightAttachIcon ? 'animate-pulse ring-2 ring-brand-neutral-700 hover:ring-brand-500 bg-brand-neutral-100 rounded' : ''}
            `}
            aria-label="Add files by uploading or selecting from file manager"
            title="Add Files"
            disabled={disabled}
          >
            <AttachFileOutlined sx={{ transform: 'rotate(45deg)' }} />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            onEscapeKeyDown={() => setPopoverOpen(false)}
            onInteractOutside={() => setPopoverOpen(false)}
            className="PopoverContent z-50 min-w-[260px] mr-5 px-2 py-2 bg-brand-neutral-50 rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)]"
          >
            <div className="flex flex-col gap-1">
              <h2 className="text-[14px] mt-1 text-center font-semibold text-brand-neutral-600">Add Files</h2>
              {fileActions.map((action) => (
                <button
                  key={action.value}
                  className="flex p-2 items-center text-left rounded-md hover:bg-brand-neutral-100 text-brand-neutral-700"
                  onClick={() => handleSelection(action.value)}
                  aria-label={action.label}
                  role="menuitem"
                >
                  {action.icon && action.icon}
                  <div className="ml-3 text-[12px]">
                    <label className="font-semibold w-[75px]">{action.label}</label>
                    <p>{action.description}</p>
                  </div>
                </button>
              ))}
              <UploadDisclaimer />
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <AssistantDocumentSelectionDialog
        open={docSourceDialogIsOpen}
        conversationId={conversationId}
        onClose={(value: boolean) => setDocSourceDialogOpen(value)}
      />
    </div>
  )
}

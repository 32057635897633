import { useRef, useState } from 'react'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import NewDocumentSessionSelectFileDialog from './NewDocumentSessionSelectFileDialog'
import { FullMetadata } from 'firebase/storage'
import { Description, Upload } from '@mui/icons-material'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { ArrowRightIcon, CrossCircledIcon } from '@radix-ui/react-icons'
import { WebsocketQueryStreamConstructorArgs, WebsocketQueryStreamType } from '@/websocket/websocket-schema'
import { DocumentEditingWsQueryPayloadType } from '@/document-editing/schema/document-editing-websocket.schema'
import { WebsocketQueryStream } from '@/websocket/websocket-query-stream'
import { documentEditingPayloadStateDispatcher } from '@/document-editing/service/document-editing-payload-dispatcher'
import { useAppDispatch } from '@/store/store-hooks'
import { Link, useNavigate } from 'react-router-dom'
import { setEditingSessionError, setEditingSessionLoading } from '@/document-editing/store/document-editing.slice'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import ChatWindowDropZone from '@/chat-common/components/chat-window-dropzone'
import { brandedAIFriendlyName } from '@/util/enterprise'

export default function NewDocumentSessionPage() {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { trackEvent } = useAnalytics()

  // Local State
  const [fileSelectDialogOpen, setFileSelectDialogOpen] = useState<boolean>(false)
  const [selectedFile, setSelectedFile] = useState<FullMetadata | null>(null)
  const [textAreaInput, setTextAreaInput] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Inferred Values
  const submitDisabled = !textAreaInput || isLoading || !selectedFile
  const fileName = selectedFile?.fullPath.split('/').pop() ?? ''

  // Handle Submit
  function handleSubmit() {
    console.log(`Submitting form with values: ${textAreaInput}, ${fileName}`)
    setIsLoading(true)

    // Make sure a file is selected
    if (!selectedFile) {
      setIsLoading(false)
      return
    }

    // Construct the payload
    const payload: DocumentEditingWsQueryPayloadType = {
      user_query: textAreaInput,
      file_path: selectedFile.fullPath,
    }

    // Execute the websocket query stream
    const queryArgs: WebsocketQueryStreamConstructorArgs = {
      streamType: WebsocketQueryStreamType.DOCUMENT_EDITING,
      initialQueryPayload: payload,
      onMessageHandler: (instance, payload): void => {
        // Send the event to the dispatcher
        documentEditingPayloadStateDispatcher({
          payload,
          dispatch,
          dispatchSuccessCallback: (validatedPayload) => {
            if (validatedPayload.event === 'header' && validatedPayload.data.id) {
              // Set the websocket query stream id
              instance.setQueryStreamId = validatedPayload.data.id

              // Navigate after the header event is dispatched
              navigate(`/dashboard/document-editing/doc/${validatedPayload.data.id}`)
            }
          },
        })
      },
      onError: function (instance: WebsocketQueryStream, event: Event): void {
        console.warn(`New document websocket onError for ID ${instance.getQueryStreamId}: `, event)
      },
      onCloseAll: function (instance: WebsocketQueryStream): void {
        console.log('New document websocket query onCloseAll with instance id: ', instance.getQueryStreamId)
        if (!instance.getQueryStreamId) throw new Error('Query stream ID is not set.')

        dispatch(setEditingSessionLoading({ id: instance.getQueryStreamId, loading: false }))
      },
      onCloseSuccess: function (instance: WebsocketQueryStream): void {
        console.log(`New document websocket query completed successfully for ID ${instance.getQueryStreamId}`)
      },
      onCloseUnexpected: function (instance: WebsocketQueryStream, code: number, reason: string): void {
        console.warn(`New document websocket query onCloseUnexpected for id ${instance.getQueryStreamId}: `, code, reason)
        if (!instance.getQueryStreamId) throw new Error('Query stream ID is not set.')

        if (reason == '400: Document contains tracked changes or comments.') {
          // Set error
          dispatch(
            setEditingSessionError({
              id: instance.getQueryStreamId,
              errorString: `${brandedAIFriendlyName} cannot yet analyze and annotate documents that already contain .docx tracked changes, comments, or redlines. Please try again with a clean document.`,
            })
          )
        } else {
          // Set error - generic
          dispatch(setEditingSessionError({ id: instance.getQueryStreamId }))
        }
      },
    }

    // Create a new websocket query stream instance
    const wsQuery = new WebsocketQueryStream(queryArgs)

    // Execute the websocket query stream
    wsQuery.execute()
  }

  return (
    <div className={'grow flex flex-col items-center justify-center'}>
      <NewDocumentSessionSelectFileDialog
        open={fileSelectDialogOpen}
        onClose={(value) => setFileSelectDialogOpen(value)}
        onFileSelect={(file: FullMetadata) => {
          setSelectedFile(file)
        }}
      />

      <div className={'bg-brand-neutral-50 rounded-lg p-4 shadow-md max-w-2xl'}>
        <div className={'px-1 mb-2 flex flex-col items-start'}>
          <h1 className={'font-bold'}>New Contract Analysis</h1>
          <div className="rounded-lg bg-brand-50 p-4 my-2 grid grid-cols-[60px_auto] items-center">
            <div className={'flex text-sm h-10 w-12 font-bold text-brand-900 bg-brand-100 rounded-lg p-[6px] ring-4 ring-white items-center justify-center'}>
              {/* <LightBulbIcon /> */}
              .docx
            </div>
            <div className={'text-sm text-brand-900'}>
              <span className={'font-bold'}>Tip:</span> docx files now use our latest AI engine to create redlines compatible with Microsoft Word and Google
              Docs.
            </div>
          </div>

          <ChatWindowDropZone onUpdate={() => void 0} overrideFeature={ChatV2Feature.contractanalysis} setProcessedStateCallback={setSelectedFile} />

          <div className={'my-2'}>
            <div className={'flex items-start gap-x-4'}>
              <div>
                <button
                  className={
                    'flex items-start text-sm gap-x-2 px-3 pr-3 py-2 border border-opacity-0 rounded-md bg-brand-500 text-white hover:bg-brand-700 transition-all duration-200 border-brand-neutral-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
                  }
                  onClick={() => {
                    trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.contractanalysis })
                    setFileSelectDialogOpen(true)
                  }}
                >
                  <Description style={{ fontSize: 18 }} />
                  <div>Choose contract</div>
                </button>
                {fileName == '' && <div className={'text-sm text-red-700 text-left'}>* Required</div>}
              </div>
              <div>
                <Link
                  to={'/dashboard/drives/my-drive'}
                  className={
                    'flex items-start text-sm gap-x-2 px-3 pr-3 py-2 rounded-md border border-brand-500 text-brand-800 hover:bg-brand-50 transition-all duration-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
                  }
                  onClick={() => {
                    trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.contractanalysis })
                    setFileSelectDialogOpen(true)
                  }}
                >
                  <Upload style={{ fontSize: 20 }} />
                  <div>Upload</div>
                </Link>
              </div>
            </div>

            {fileName != '' && (
              <div
                className={
                  'pt-1 text-sm text-brand-neutral-500 max-h-12 overflow-scroll items-start grid grid-cols-[24px_auto] overflow-x-hidden compatible-word-break'
                }
              >
                <button
                  className={'pt-[2px]'}
                  onClick={() => {
                    setSelectedFile(null)
                  }}
                >
                  <CrossCircledIcon className={'h-[18px] w-[18px] mr-2 align-top'} />
                </button>
                <div className={'pt-[1px]'}>{fileName}</div>
              </div>
            )}
          </div>
          <div className={'pt-2 text-sm leading-tight'}>
            <span className={'font-bold'}>Instructions: </span>
            Provide context including the purpose of the contract, a summary of the parties, your role, and the goal of the analysis.
          </div>
        </div>
        {/* {isAnonymous && (
          <div className={'flex justify-center w-full text-xs text-center'}>
            <div className={'flex gap-x-2 items-end bg-brand-800 bg-opacity-80 py-1 px-4 rounded-t-md'}>
              <div className={'text-white'}>Save your work:</div>
              <button
                className={'text-white underline px-1 hover:text-opacity-80'}
                onClick={() => {
                  dispatch(
                    openAuthDialog({
                      authDialogType: AuthDialogType.SIGN_UP,
                    })
                  )
                }}
              >
                Sign up
              </button>
              <button
                className={'text-white underline hover:text-opacity-80'}
                onClick={() => {
                  dispatch(
                    openAuthDialog({
                      authDialogType: AuthDialogType.SIGN_IN,
                    })
                  )
                }}
              >
                Sign in
              </button>
            </div>
          </div>
        )} */}
        <form className="mt-auto w-full p-2 grid gap-x-2 items-end grid-cols-[auto_40px] border rounded-lg">
          <textarea
            ref={textAreaRef}
            className="resize-none flex-1 p-2 border-none focus:ring-0 bg-transparent"
            value={textAreaInput}
            onChange={(e) => {
              setTextAreaInput(e.target.value)

              const target = e.target as HTMLTextAreaElement
              target.style.height = 'auto'
              target.style.height = `${target.scrollHeight}px`
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault() // Prevent default to avoid line break in textarea
                handleSubmit() // Call your form's submit handler
              }
            }}
            placeholder={'Provide context here'}
            rows={1}
            style={{ lineHeight: '1.5', maxHeight: '12rem' }}
          />

          {isLoading ? (
            <div className={'w-10 h-10 grid place-items-center rounded-md bg-brand-500 bg-opacity-50'}>
              <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
            </div>
          ) : (
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault()
                handleSubmit()
              }}
              disabled={submitDisabled}
              className={`w-10 h-10 bg-brand-500 text-white rounded-md grid place-items-center ${submitDisabled ? 'opacity-50' : 'opacity-100'}`}
            >
              <ArrowRightIcon width="20" height="20" />
            </button>
          )}
        </form>
        <div className={'px-1'}>
          <p className={'text-xs text-brand-neutral-600 text-center mt-1 hidden sm:block'}>new line: shift + enter</p>
        </div>
      </div>
    </div>
  )
}

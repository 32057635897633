import { selectFileProcessingStatusesWithKeys } from '@/store/selectors/file-upload-tasks-reducers.selector'
import { FileProcessingStatus } from '@/store/slices/file-upload-tasks.slice'
import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'
import { AgentFocusMode } from './schemas'

/**
 * Agent Conversation Form Submit Blocked
 * Check if the agent conversation form submission is blocked.
 *
 * @returns The reason the form is blocked or null if it is not blocked.
 */
export const selectAgentConversationFormSubmitBlockedReason = createSelector(
  // Input 1: Get the conversation based on chatId
  (state: RootState, props: { conversationId: string }) => state.agentConversationFormState.forms[props.conversationId] ?? null,

  // Input 2: Provide the processing statuses of the selected files
  (state: RootState, props: { keys: string[] }) => selectFileProcessingStatusesWithKeys(state, props),

  // Input 3: The websocket connection for this conversation
  (state: RootState, props: { conversationId: string }) => state.ws2State.connections[props.conversationId],

  // Output: Check if the form is blocked
  (form, processingStatuses, connection): string | null => {
    if (!form) return 'no form'

    // Block if already connected
    if (connection?.connected) return 'websocket connected - previous message turn in progress'

    // Block if the text input is empty
    if (form.requestParams.input_text.trim() === '') return 'input text is empty'

    // Block if selected files are not ready
    const hasUnprocessedFiles = Object.values(processingStatuses).some((status) =>
      [FileProcessingStatus.PENDING, FileProcessingStatus.PROCESSING, FileProcessingStatus.ERROR].includes(status as FileProcessingStatus)
    )
    if (hasUnprocessedFiles) return 'selected files are not ready'

    // Block is in document query mode and no files are selected
    if (form.requestParams.focus_mode === AgentFocusMode.ANALYSIS && form.requestParams.file_paths.length === 0) return 'document query mode requires files'

    // Allow submission
    return null
  }
)

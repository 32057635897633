import { kPaymentsSurveyPathV2 } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { OnboardingSurveyRequest, OnboardingSurveyResponse, OnboardingSurveyResponseSchema } from '@/onboarding/schema/onboarding.schema'
import { getAuth } from 'firebase/auth'
import * as Sentry from '@sentry/browser'
import { nanoid } from 'nanoid'

/**
 * Create Survey Response via POST Request
 * @returns
 */
export default async function postCreateSurveyResponse(params: OnboardingSurveyRequest): Promise<OnboardingSurveyResponse> {
  const sentry_transaction_id = nanoid()

  try {
    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw Error(kErrorCodeAuthError)

    // if there is an area of practice, append it to professional role
    const professionalRole =
      params.professional_role === 'Law firm' && params.area_of_practice ? `${params.professional_role} - ${params.area_of_practice}` : params.professional_role

    const paxtonReferrer =
      params.paxton_referrer === 'Social media' && params.social_media_source
        ? `${params.paxton_referrer} - ${params.social_media_source}`
        : params.paxton_referrer
    // Construct the body with snake_case keys to match API expectations
    const body = {
      first_name: params.first_name,
      last_name: params.last_name,
      professional_role: professionalRole,
      firm_size: params.firm_size,
      ranking_first_choice: params.planned_use_first_choice,
      ranking_second_choice: params.planned_use_second_choice,
      ranking_third_choice: params.planned_use_third_choice,
      paxton_referrer: paxtonReferrer,
    }

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      body: JSON.stringify(body),
    }

    const apiUrl = kPaxtonAppApiBaseUrl() + `/${kPaymentsSurveyPathV2}`
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            response: JSON.stringify(response),
            data: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
      throw new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`)
    }

    // Validate the response data using Zod
    const parsedData = OnboardingSurveyResponseSchema.parse(data)
    return parsedData
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    throw e
  }
}

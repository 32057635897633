import { createListenerMiddleware } from '@reduxjs/toolkit'
import { FilesDriveActions } from './files-drive.slice'
import { fetchAllItemsByFolder } from '../repository/fetch-drive-items'
import { FileNode, FolderNode } from '../schemas/files-drive-schema'

const filesDriveListenerMiddleware = createListenerMiddleware()

filesDriveListenerMiddleware.startListening({
  actionCreator: FilesDriveActions.fetchItems,
  effect: async (action, listenerApi) => {
    const data = await fetchAllItemsByFolder(action.payload.folderId ?? null)

    const folders = data.folders as FolderNode[]
    const files = data.files as FileNode[]

    listenerApi.dispatch(FilesDriveActions.setDriveItems({ folders, files }))
  },
})

export default filesDriveListenerMiddleware

import { kConversationListCacheDuration } from '../constants'
import { getUsersConversationList } from '../fetch/get-conversation-list'
import { createListenerMiddleware } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '@/store/store'
import { ChatV2Actions } from '@/chat-common/store/chat-v2.slice'

export const conversationListListenerMiddleware = createListenerMiddleware()
const startListener = conversationListListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()

/**
 * Fetch Conversations Action Listener
 * This listener fetches the conversations from the server
 * It checks the cache duration and only fetches if the cache has expired
 * or if the user forces a refresh
 */
startListener({
  actionCreator: ChatV2Actions.refreshConversations,
  effect: async (action, listenerApi) => {
    const { forceRefresh } = action.payload

    // Get the existing state
    const state = listenerApi.getState().chatV2State

    // Is loading already?
    const isLoading = state.loading

    // Cache Handling
    const cacheDuration = kConversationListCacheDuration
    const lastFetch = state.lastRefresh
    const cacheExpired = lastFetch === null || Date.now() - lastFetch > cacheDuration

    // Return early if:
    // - the cache has not expired and we are not forcing a refresh
    // - the conversation is already loading
    if ((!cacheExpired && !forceRefresh) || isLoading) {
      return
    }

    // Else, fetch the conversations
    try {
      listenerApi.dispatch(ChatV2Actions.setLoading(true))
      listenerApi.dispatch(ChatV2Actions.setError(false))

      // Fetch conversations
      const response = await getUsersConversationList()

      // Sort the the conversations by modified_on, most recent first
      // modified_on is milliseconds since epoch
      const sorted = [...response.conversations].sort((a, b) => {
        return b.modified_on - a.modified_on
      })

      // For each conversation, upsert it into the state
      sorted.forEach((conversationHeader) => {
        listenerApi.dispatch(ChatV2Actions.upsertConversationFromHeader(conversationHeader))
      })

      // Set initial load complete
      listenerApi.dispatch(ChatV2Actions.setInitialLoadComplete(true))
    } catch (e) {
      listenerApi.dispatch(ChatV2Actions.setError(true))
    } finally {
      // Set loading to false
      listenerApi.dispatch(ChatV2Actions.setLoading(false))

      // Set the last refresh time
      listenerApi.dispatch(ChatV2Actions.setLastRefresh(Date.now()))
    }
  },
})

import { z } from 'zod'

/**
 * Validates and parses a timestamp.
 * - Accepts numbers (assumes milliseconds since epoch).
 * - Accepts strings (assumes ISO 8601 or similar formats).
 * - Adds a Zod custom issue for invalid timestamps.
 */
export const parseTimestamp = (val: unknown, ctx: z.RefinementCtx): number => {
  // preprocess filtering - only includes valid timestamps
  // 1. Check if the value is a number and validate that it's in milliseconds since epoch.
  // 2. If it's not a number, attempt to parse it as a Python datetime string and convert it to milliseconds.

  // If it's a number, assume it's already in milliseconds
  if (typeof val === 'number') {
    // Validate it's milliseconds since epoch
    if (val < 1000000000000) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Provided timestamp (${val}) is not in milliseconds since epoch`,
      })
      return z.NEVER // Return a value that will fail the schema
    }
    return val
  }

  // If it's not a number, try to parse it (should be provided as a python datetime string)
  const timestamp = Date.parse(val as string)
  if (isNaN(timestamp)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Provided timestamp (${val}) failed to Date.parse()`,
    })
    return z.NEVER // Return a value that will fail the schema
  }

  return timestamp
}

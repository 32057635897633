import { kChatV2ApiConversationsBasePath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { store } from '@/store/store'
import { getAuth } from 'firebase/auth'
import { ChatV2Actions, ChatV2Conversation } from '../store/chat-v2.slice'
import { ErrorComponentProps } from '@/components/error/error-component'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { k429RateLimitError, kAnonymousUserLimitMessage429 } from '@/constants/constants-strings'
import { AuthDialogType, openAuthDialog } from '@/store/slices/ui-state.slice'
import { ChatV2GetConversationByIdResponseSchema } from '../schemas/chat-v2.schemas'
import { ConversationHeader } from '../conversation_list/schema'
import getDefaultQueryMetadataByFeature from '../schemas/chat-default-schema-values'
import { kConversationRefreshCacheDuration } from '../conversation_list/constants'
import { validateQueryMetadataByFeature } from '../schemas/validate-chat-query-metadata'

/**
 * Load Missing Conversation Into State
 * IF: Conversation is not in state
 * - Fetches the messages for a given conversation id
 * - Inserts them into state
 *
 * This is for initial conversation loading only. Subsequent messages are handled by the websocket,
 * and dynamically update the existing conversation state.
 *
 * Not using RTK Query since we want to manipulate the conversation state client-side with elements
 * that are not saved to persistence from the server only while the chat is active
 * @param conversationId
 * @param forceRefresh - Force the load even if the conversation is already in state
 * @returns
 */
export default async function refreshFullConversation(props: {
  conversationId: string
  onError: (errorProps: ErrorComponentProps) => void
  onLoadingStatus: (isLoading: boolean) => void
  forceRefresh: boolean
}) {
  const { conversationId, onError, onLoadingStatus, forceRefresh } = props

  // Get the current conversation state
  const currentConversationState: ChatV2Conversation | null = store.getState().chatV2State.conversations[conversationId]

  // Is loading already?
  const isLoading = currentConversationState?.isLoading ?? false

  // Cache Handling
  const cacheDuration = kConversationRefreshCacheDuration
  const lastRefresh = currentConversationState?.lastRefresh ?? null
  const cacheExpired = !lastRefresh || Date.now() - lastRefresh > cacheDuration

  // Return early if:
  // - the cache has not expired and we are not forcing a refresh
  // - the conversation is already loading
  if ((!cacheExpired && !forceRefresh) || isLoading) {
    return
  }

  // Create transaction id
  const sentry_transaction_id = nanoid()

  try {
    // Set loading status
    onLoadingStatus(true)

    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw Error(kErrorCodeAuthError)

    // Construct request
    const apiUrl = kPaxtonAppApiBaseUrl() + `${kChatV2ApiConversationsBasePath}/${conversationId}`
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    // console.log(`Querying ${apiUrl} with ${JSON.stringify(requestOptions, null, 2)}`)
    const response = await fetch(apiUrl, requestOptions)

    if (!response.ok) {
      let captureToSentry = false

      // Handle UX messages and decide whether to capture to sentry
      switch (response.status) {
        case 404:
          captureToSentry = true
          onError({
            code: response.status.toString(),
            title: 'Chat not found',
            message: `We could not find the chat with id: ${conversationId}`,
          })
          break

        case 429:
          // Don't capture for 429
          onError({
            code: response.status.toString(),
            title: 'Too many requests',
            message: k429RateLimitError,
          })

          // If 429 error and user is anon, show dialog
          if (getAuth().currentUser?.isAnonymous) {
            store.dispatch(
              openAuthDialog({
                authDialogType: AuthDialogType.SIGN_UP,
                tooltipMessage: kAnonymousUserLimitMessage429,
              })
            )
          }
          break

        default:
          captureToSentry = true
          onError({
            code: response.status.toString(),
            title: 'Error loading chat',
            message: `We could not load the chat with id: ${conversationId}. Please try again.`,
          })
          break
      }

      // Conditionally capture to sentry
      if (captureToSentry) {
        Sentry.withScope((scope) => {
          scope.setTags({ transaction_id: sentry_transaction_id })

          Sentry.captureException(new Error(`Fetch error - status: ${response.status}`), {
            extra: { requestUrl: apiUrl, requestOptions: requestOptions, onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
          })
        })
      }

      return
    }

    // Validate the response data
    const data = await response.json()
    const validatedData = ChatV2GetConversationByIdResponseSchema.safeParse(data)
    if (!validatedData.success) {
      throw new Error(`Conversation schema validation failure: ${validatedData.error}`)
    }

    // Upsert the conversation header data into state
    const conversationHeader: ConversationHeader = validatedData.data
    store.dispatch(ChatV2Actions.upsertConversationFromHeader(conversationHeader))

    // Upsert the conversation message data into state
    const messages = validatedData.data.messages
    if (messages.length > 0) {
      store.dispatch(ChatV2Actions.upsertConversationMessages({ conversationId, messages }))
    }

    // Get the last message in the conversation, or null if there are no messages
    const lastMessage = messages.length === 0 ? null : messages[messages.length - 1]

    // Update the current source based on the last message if it exists and meets conditions.
    if (lastMessage) {
      // Get the last message's request params and feature
      const messageRequestParams = lastMessage.request_params
      const feature = validatedData.data.feature

      // Get the feature's default currentSource
      const defaultCurrentSource = getDefaultQueryMetadataByFeature(validatedData.data.feature)

      // Get the conversation's currentSource
      const currentSource = currentConversationState?.currentSource ?? null

      // Current source is null or default?
      const currentSourceIsNullOrDefault = !currentSource || JSON.stringify(currentSource) === JSON.stringify(defaultCurrentSource)

      // If the conversation's currentSource is still the default or null, update it with the last message's request params
      // (if it's not the default, the user may have already started editing it)
      if (currentSourceIsNullOrDefault) {
        try {
          // Validate the request params against the feature's schema
          const validatedParams = validateQueryMetadataByFeature(feature, messageRequestParams)

          store.dispatch(ChatV2Actions.updateConversationCurrentSource({ conversationId, currentSource: validatedParams }))
        } catch (err) {
          Sentry.withScope((scope) => {
            scope.setTags({ transaction_id: sentry_transaction_id })

            Sentry.captureException(err, {
              extra: {
                onLine: navigator.onLine,
                cookieEnabled: navigator.cookieEnabled,
                feature: feature,
                conversationId: conversationId,
                messageId: lastMessage.metadata.message_id,
                requestParams: JSON.stringify(messageRequestParams),
              },
            })
          })
          // If the request params are invalid, set the default current source for the feature
          store.dispatch(ChatV2Actions.updateConversationCurrentSource({ conversationId, currentSource: defaultCurrentSource }))
        }
      }
    }
  } catch (e) {
    console.error(e)

    // Capture to sentry
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    onError({
      code: 'UNKNOWN ERROR',
      title: 'Error loading chat',
      message: `We could not load the chat with id: ${conversationId}. Please try again.`,
    })
  } finally {
    // Set loading status
    onLoadingStatus(false)
  }
}

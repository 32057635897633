import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import ArtifactViewMarkdown from './artifact-view-markdown'
import { CloseRounded, TextSnippet } from '@mui/icons-material'
import { artifactNameFallback } from '@/constants/constants-artifact'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import { selectAgentConversationVisibleArtifact } from '@/agent/conversations/store/selectors'

type ArtifactViewerProps = {
  conversationId: string
}

/**
 * Renders the core content for an Artifact:
 * - Artifact name
 * - Artifact content via ArtifactViewMarkdown
 */
export default function ArtifactViewer(props: ArtifactViewerProps) {
  const { conversationId } = props
  const dispatch = useAppDispatch()

  const visibleArtifact = useAppSelector((state: RootState) => selectAgentConversationVisibleArtifact(state, { conversationId }))

  if (!visibleArtifact) return null

  // Use fallback name if visibleArtifact.name is not available
  const artifactName = visibleArtifact.name ?? artifactNameFallback

  return (
    <div className="bg-brand-neutral-50 flex flex-col h-full w-full overflow-hidden">
      {/* Header */}
      <div className="grid grid-cols-[47px_auto_47px] items-center h-14 border-b border-brand-neutral-200">
        <div className={'w-full text-center'}>
          <TextSnippet className="text-gray-400" />
        </div>
        <div
          className="text-base font-bold truncate leading-none mt-1"
          title={artifactName} // Tooltip with full name for long, truncated names
        >
          {artifactName}
        </div>
        <div className={'text-center'}>
          <button onClick={() => dispatch(AgentConversationsActions.removeVisibleArtifact({ conversationId }))} aria-label="Close Artifact Viewer">
            <CloseRounded fontSize="small" />
          </button>
        </div>
      </div>
      {/* Artifact Viewer Content */}
      <div className="flex-grow overflow-y-auto pt-3 px-3 h-full w-full">
        <ArtifactViewMarkdown artifactVersion={visibleArtifact} />
      </div>
    </div>
  )
}

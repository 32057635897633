import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

// const selectFolderId = (_state: RootState, folderId: string) => folderId

// Selector that return the files drive state
export const selectFilesDriveState = createSelector(
  (state: RootState) => state.filesDrive,
  (filesDrive) => filesDrive
)

export const selectFoldersContent = createSelector(
  // Input selectors
  (state: RootState) => state.filesDrive.folders,
  (state: RootState) => state.filesDrive.files,
  (_state: RootState, folderId: string | null) => folderId,
  (folders, files, folderId) => {
    if (!folderId || folderId === '') {
      return {
        folders,
        files,
        isLoading: false,
      }
    }

    const filteredFolders = folders.filter((f) => f.parent_id === folderId)
    const filteredFiles = files.filter((f) => f.parent_id === folderId)

    return {
      folders: filteredFolders,
      files: filteredFiles,
      isLoading: false,
    }
  }
)

export const selectCurrentFolderId = createSelector(
  (state: RootState) => state.filesDrive.currentFolder,
  (currentFolder) => (currentFolder ? currentFolder.id : '')
)

export const selectCurrentFolder = createSelector(
  (state: RootState) => state.filesDrive.currentFolder,
  (currentFolder) => {
    return currentFolder
  }
)

import { Switch } from '@headlessui/react'
import { useSettings } from '@/settings/hooks/use-settings'
import { useAppSelector } from '@/store/store-hooks'
import { selectPromptAssistEnabled } from '@/chat-common/store/chat-v2-ux.selectors'
import { useDispatch } from 'react-redux'
import { setPromptAssistEnabled } from '@/chat-common/store/chat-v2-ux.slice'
import { useState, useEffect } from 'react'
import { brandedAIFriendlyName } from '@/util/enterprise'

export default function SettingsPage() {
  const { getSettings, updateUserSettings } = useSettings()
  const promptAssistEnabled = useAppSelector(selectPromptAssistEnabled)
  const [updateError, setUpdateError] = useState<boolean>(false)
  const dispatch = useDispatch()

  // Load settings on mount
  useEffect(() => {
    getSettings()
  }, []) // Empty dependency array means this runs once on mount

  const handleToggle = async () => {
    const newValue = !promptAssistEnabled
    dispatch(setPromptAssistEnabled({ enabled: newValue }))

    try {
      await updateUserSettings({ prompt_assist_enabled: newValue })
      setUpdateError(false)
    } catch (err) {
      dispatch(setPromptAssistEnabled({ enabled: !newValue }))
      setUpdateError(true)
      console.error('Failed to update user settings:', err)
    }
  }

  return (
    <div className="grow">
      <div className="flex justify-center px-4 sm:px-8 md:px-[178px] sm:mt-0 mt-6">
        <div className="grow max-w-[640px]">
          <h1 className="text-xl leading-xl font-headline font-bold text-brand-neutral-950 mb-3 mt-9">Settings</h1>
        </div>
      </div>

      <hr className="border-t border-brand-neutral-200 w-full mb-6" />

      <div className="flex justify-center px-4 sm:px-8 md:px-[178px]">
        <div className="grow max-w-[640px]">
          <div className="flex flex-col grow bg-white rounded-lg border border-[#EAE9E5] px-4 py-6 ">
            <div className="flex flex-col space-y-4">
              <div className="flex flex-col sm:flex-row items-start sm:items-center sm:justify-between gap-4 sm:gap-x-4">
                <div className="flex flex-col w-full sm:flex-1">
                  <div className="flex justify-between items-center mb-2">
                    <h2 className="font-semibold">Prompt Assist</h2>
                    <div className="flex sm:hidden items-center">
                      <Switch
                        checked={promptAssistEnabled}
                        onChange={handleToggle}
                        className={`${
                          promptAssistEnabled ? 'bg-brand-600' : 'bg-brand-neutral-200'
                        } group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-600 focus:ring-offset-2`}
                      >
                        <span className="sr-only">Enable Prompt Assist</span>
                        <span
                          aria-hidden="true"
                          className={`${
                            promptAssistEnabled ? 'translate-x-5' : 'translate-x-0'
                          } pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>
                    </div>
                  </div>
                  <p className="text-sm leading-sm text-brand-neutral-600">
                    Enables {brandedAIFriendlyName} to recommend an alternative feature or ask follow-up questions in order to improve the quality of its
                    response. Prompt assist only applies to the first query in a conversation.
                  </p>
                </div>
                <div className="hidden sm:flex items-center gap-x-2">
                  <Switch
                    checked={promptAssistEnabled}
                    onChange={handleToggle}
                    className={`${
                      promptAssistEnabled ? 'bg-brand-600' : 'bg-brand-neutral-200'
                    } group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-600 focus:ring-offset-2`}
                  >
                    <span className="sr-only">Enable Prompt Assist</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        promptAssistEnabled ? 'translate-x-5' : 'translate-x-0'
                      } pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
              </div>
              {updateError && (
                <p className="text-red-500 text-sm">
                  There was an error updating your settings. Please refresh and try again. If the problem persists please let us know through the support chat
                  in the main menu.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

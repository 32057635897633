import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useState, useEffect, useRef } from 'react'
import { ChatListSidebarItem } from '../../conversation_list/schema'
import { ChatV2Actions, ChatV2Feature } from '../../store/chat-v2.slice'
import patchConversationTitle from '@/chat-common/fetch/patch-conversation-title'
import { useAppDispatch } from '@/store/store-hooks'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import patchRenameAgentConversationTitle from '@/agent/conversations/fetch/patch-rename-agent-conversation-title'

type ChatTitleChangeDialogProps = {
  dialogVisible: boolean
  onDialogClose: () => void
  chatItem: ChatListSidebarItem | null
}

export default function ChatTitleChangeDialog(props: ChatTitleChangeDialogProps) {
  const { dialogVisible, chatItem, onDialogClose } = props
  const dispatch = useAppDispatch()
  const inputRef = useRef<HTMLInputElement>(null)

  // Local state
  const [newTitle, setNewTitle] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<boolean>(false)

  // Change the title of the chat
  const changeTitleHandler = async (newTitle: string) => {
    if (!chatItem) {
      throw new Error('Chat item is not set')
    }

    setLoading(true)
    setError(false)
    try {
      // If Agent Conversation:
      if (chatItem.feature === ChatV2Feature.agent) {
        await patchRenameAgentConversationTitle({ conversationId: chatItem.id, title: newTitle })

        // Refresh the agent conversations list
        dispatch(AgentConversationsActions.refreshConversationList({ forceRefresh: true }))
      } else {
        await patchConversationTitle({
          conversationId: chatItem.id,
          newTitle: newTitle,
        })

        // Refresh the legacy conversations list
        dispatch(ChatV2Actions.refreshConversations({ forceRefresh: true }))
      }

      // timeout for 300 milliseconds
      setTimeout(() => {
        onDialogClose()
      }, 300)

      // Close the dialog
      onDialogClose()
    } catch (err) {
      console.error('Error while renaming chat:', err)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  // Reset the dialog if dialogVisible changes
  useEffect(() => {
    setError(false)

    // Clear the newTitle
    setNewTitle('')

    // Focus on the input field when dialog becomes visible
    if (dialogVisible && inputRef.current) {
      setTimeout(() => inputRef.current?.focus(), 100)
    }
  }, [dialogVisible])

  // Handle escape key interactions
  useEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onDialogClose()
      }
    }

    if (dialogVisible) {
      document.addEventListener('keydown', handleEscKey)
    }
    return () => {
      document.removeEventListener('keydown', handleEscKey)
    }
  }, [dialogVisible, onDialogClose])

  // Handle form submission
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    changeTitleHandler(newTitle)
  }

  // Do not show if not visible
  if (!dialogVisible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={() => onDialogClose()}></div>

      {/* Dialog Content */}
      <div className="bg-brand-neutral-50 p-4 mx-4 rounded-md shadow-lg relative z-[1000] w-full max-w-sm sm:max-w-md">
        <h2 className="mb-1 text-lg font-semibold">Rename Chat</h2>

        <form onSubmit={handleFormSubmit}>
          <input
            type="text"
            ref={inputRef}
            className="w-full p-2 border border-brand-neutral-300 rounded-md"
            placeholder="Enter new title"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          {error && <div className={'mt-2 ml-1 text-red-700 text-sm'}>Failed to rename chat. Please try again.</div>}
          <div className={'flex gap-x-4 justify-end items-center mt-4'}>
            <button
              type="button"
              className="inline-flex justify-center rounded-md bg-brand-neutral-50 px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-50"
              onClick={() => onDialogClose()}
            >
              Cancel
            </button>

            {/* Loading spinner */}
            {loading && <CircularProgressContinuousSized size={20} thickness={7} />}

            {/* Submit button */}
            {!loading && (
              <button
                type="submit"
                className={`inline-flex justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm ${
                  !newTitle.trim() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-brand-400'
                }`}
                disabled={loading || !newTitle.trim()}
              >
                Submit
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
// request_params in the ChatV2MessageSchema is typed as any
import { z } from 'zod'
import { ChatV2Feature } from '../store/chat-v2.slice'
import {
  ChatV2QueryMetadataAssistantSchema,
  ChatV2QueryMetadataCaseLawSchema,
  ChatV2QueryMetadataContractAnalysisSchema,
  ChatV2QueryMetadataContractSearchSchema,
  ChatV2QueryMetadataDocumentQuerySchema,
  ChatV2QueryMetadataDocumentSummarizationSchema,
  ChatV2QueryMetadataDraftingSchema,
  ChatV2QueryMetadataLrrV2Schema,
  ChatV2QueryMetadataResearchSchema,
  ChatV2QueryMetadataWebsearchQuerySchema,
} from './chat-query-metadata-schema'

const schemaMap: Partial<Record<ChatV2Feature, z.ZodSchema<any>>> = {
  [ChatV2Feature.contractsearch]: ChatV2QueryMetadataContractSearchSchema,
  [ChatV2Feature.drafting]: ChatV2QueryMetadataDraftingSchema,
  [ChatV2Feature.lrr]: ChatV2QueryMetadataLrrV2Schema,
  [ChatV2Feature.lrr_v2]: ChatV2QueryMetadataLrrV2Schema,
  [ChatV2Feature.caselaw]: ChatV2QueryMetadataCaseLawSchema,
  [ChatV2Feature.research]: ChatV2QueryMetadataResearchSchema,
  [ChatV2Feature.documentquery]: ChatV2QueryMetadataDocumentQuerySchema,
  [ChatV2Feature.contractanalysis]: ChatV2QueryMetadataContractAnalysisSchema,
  [ChatV2Feature.websearch]: ChatV2QueryMetadataWebsearchQuerySchema,
  [ChatV2Feature.documentSummarization]: ChatV2QueryMetadataDocumentSummarizationSchema,
  [ChatV2Feature.assistant]: ChatV2QueryMetadataAssistantSchema,
}

export function validateQueryMetadataByFeature(feature: ChatV2Feature, requestParams: any) {
  const schema = schemaMap[feature]
  if (!schema) {
    throw new Error(`No schema found for feature: ${feature}`)
  }

  // Will throw a ZodError if validation fails
  return schema.parse(requestParams)
}

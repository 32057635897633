import { createListenerMiddleware } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '@/store/store'
import { ChatV2Actions } from '@/chat-common/store/chat-v2.slice'
import patchGenerateConversationTitle from '../fetch/patch-generate-conversation-title'

export const chatV2ListenerMiddleware = createListenerMiddleware()
const startListener = chatV2ListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()

/**
 * Generate Conversation Title Action Listener
 * This listener generates a new title for the conversation
 */
startListener({
  actionCreator: ChatV2Actions.generateConversationTitle,
  effect: async (action, listenerApi) => {
    // Call the API
    const newTitle = await patchGenerateConversationTitle(action.payload)

    // Dispatch the new title
    listenerApi.dispatch(ChatV2Actions.chatV2UpdateConversationTitle({ conversationId: action.payload.conversationId, title: newTitle }))
  },
})

import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material/SvgIcon'
import { brandedAIFriendlyName } from '@/util/enterprise'
import { ModeChoice } from '@/chat-common/schemas/chat-query-metadata-schema'
import { LightbulbOutlined, DriveFileRenameOutlineRounded, CollectionsBookmark } from '@mui/icons-material'
import { AgentFocusMode } from '@/agent/chat_form/store/schemas'

type AssistantMode = {
  legacyValue: ModeChoice
  agentValue: AgentFocusMode
  title: string
  description: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> // Type for Material UI icons
  recommended: boolean
}

export const assistantModes: AssistantMode[] = [
  {
    legacyValue: ModeChoice.ASSISTANT,
    agentValue: AgentFocusMode.INTELLIGENT,
    title: 'Intelligent',
    description: `${brandedAIFriendlyName} will determine the best path forward`,
    icon: LightbulbOutlined,
    recommended: true,
  },
  {
    legacyValue: ModeChoice.DRAFTING,
    agentValue: AgentFocusMode.DRAFTING,
    title: 'Drafting',
    description: 'Create documents and memos',
    icon: DriveFileRenameOutlineRounded,
    recommended: false,
  },
  {
    legacyValue: ModeChoice.DOCUMENTQUERY,
    agentValue: AgentFocusMode.ANALYSIS,
    title: 'Analysis',
    description: 'Upload files to extract insights and analyze content',
    icon: CollectionsBookmark,
    recommended: false,
  },
]

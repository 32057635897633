import { parseTimestamp } from '@/util/validators'
import { z } from 'zod'

// AgentEventType Enum
export enum AgentEventType {
  USER_QUERY = 'user_query',
  AGENT_RESPONSE = 'agent_response',
  STATUS_UPDATE = 'status_update',
  CLIENT_ERROR_EVENT = 'client_error_event',
  STOP_REQUEST_RECEIVED = 'stop_request_received',
  SERVER_ERROR = 'server_error',
}

// AgentEventRead Schema
export const AgentEventReadSchema = z.object({
  id: z.string().uuid(),
  turn_id: z.string().uuid(),
  type: z.nativeEnum(AgentEventType),
  value: z.string(),
  created_at: z.preprocess((val, ctx) => parseTimestamp(val, ctx), z.number()),
})

export type AgentEventRead = z.infer<typeof AgentEventReadSchema>

/**
 * ClientAgentEventRead Schema
 *
 * This is the version we store in the state, which includes the artifact_version_id.
 * We will get this version via REST when fetching conversation data,
 * and the AgentEventRead version via websocket.
 */
export const ClientAgentEventReadSchema = AgentEventReadSchema.extend({
  artifact_version_id: z.string().uuid().optional().nullable().default(null),
})

export type ClientAgentEventRead = z.infer<typeof ClientAgentEventReadSchema>

/**
 * Conversation Message Chunk Payload Schema
 * (Message == Event)
 */
export const AgentEventChunkSchema = z.object({
  event_id: z.string().uuid(),
  chunk: z.string(),
})
export type AgentEventChunk = z.infer<typeof AgentEventChunkSchema>

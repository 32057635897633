import { getBrandName } from '@/util/enterprise'

const messageHeader = getBrandName()

export default function ResponseStoppedEventBubble() {
  return (
    <div className="p-2 rounded-lg text-base whitespace-pre-wrap bg-white">
      <div className="font-bold text-xl mb-1">{messageHeader}</div>
      <div className="text-sm leading-6 mb-1">The response was stopped before completion.</div>
    </div>
  )
}

import { RootState } from '@/store/store'
import { AgentConversation, ContentViewerMode, ConversationLoadError } from './slice'
import { createSelector } from '@reduxjs/toolkit'

/**
 * Select: Agent Conversation Exists
 * Returns true if the agent conversation exists in the state
 */
export const selectAgentConversationExists = (state: RootState, props: { conversationId: string | undefined | null }): boolean => {
  const { conversationId } = props
  if (!conversationId) return false

  const conversation = state.agentConversationsState.conversations[conversationId]
  return !!conversation
}

/**
 * Select: Agent Conversation From Id
 * Returns the agent conversation from the state if it exists.
 */
export const selectAgentConversationFromId = (state: RootState, props: { conversationId: string | undefined }): AgentConversation | null => {
  const { conversationId } = props
  return state.agentConversationsState.conversations[conversationId ?? ''] ?? null
}

/**
 * Select: Agent Conversation Loading
 * Returns true if the agent conversation is loading.
 */
export const selectAgentConversationLoading = (state: RootState, props: { conversationId: string | undefined | null }): boolean => {
  const { conversationId } = props
  if (!conversationId) return false

  const conversation = state.agentConversationsState.conversations[conversationId]
  return conversation?.isLoading ?? false
}

/**
 * Select: Agent Conversation Load Error
 * Returns the load error for the agent conversation if it exists.
 */
export const selectAgentConversationLoadError = (state: RootState, props: { conversationId: string | undefined | null }): ConversationLoadError | null => {
  const { conversationId } = props
  if (!conversationId) return null

  const conversation = state.agentConversationsState.conversations[conversationId]
  return conversation?.loadError ?? null
}

/**
 * Select: Agent Conversation Content Viewer
 * Retrieves the content viewer object (mode and data) from a conversation.
 */
export const selectAgentConversationContentViewer = createSelector(selectAgentConversationFromId, (conversation) => conversation?.contentViewer ?? null)

/**
 * Select: Agent Conversation Content Viewer Visible
 * Determines if the content viewer is visible in the conversation.
 */
export const selectAgentConversationContentViewerVisible = createSelector(selectAgentConversationContentViewer, (contentViewer) => contentViewer !== null)

/**
 * Select: Agent Conversation Visible Artifact
 * Retrieves the artifact data if the content viewer mode is 'artifact'.
 */
export const selectAgentConversationVisibleArtifact = createSelector(selectAgentConversationContentViewer, (contentViewer) =>
  contentViewer?.mode === ContentViewerMode.artifact ? contentViewer.data : null
)

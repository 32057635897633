import ContentViewerConstructor from './content-viewer-constructor'

type ContentViewerColumnProps = {
  conversationId: string
}

/**
 * A layout component that displays content views in a side column.
 */
export default function ContentViewerColumn(props: ContentViewerColumnProps) {
  const { conversationId } = props

  return (
    <div className="bg-brand-neutral-50 flex flex-col h-full w-full border-l border-brand-neutral-200">
      <div className="flex-grow overflow-y-auto">
        <ContentViewerConstructor conversationId={conversationId} />
      </div>
    </div>
  )
}

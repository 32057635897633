import SignInForm from '@/components/auth/sign-in-form'
import SignUpForm from '@/components/auth/sign-up-form'
import { AuthDialogType, closeAuthDialog, switchAuthDialog } from '@/store/slices/ui-state.slice'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import AuthIncentive from './AuthIncentive'
import MobileFeaturedLogos from './MobileLogos'
import { useNavigate } from 'react-router-dom'
import SendPasswordResetForm from '@/components/auth/send-password-reset-form'

export default function DashboardAuth() {
  // Watch global UI state
  const { authDialogType } = useAppSelector((state: RootState) => state.uiState)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <div className={'flex-grow flex flex-col overflow-y-scroll items-center py-2 pb-4 pt-10 mt-5 lg:mt-0 lg:min-h-screen'}>
      <div className={'flex grow justify-center items-center md:pr-5 xl:pr-20 2xl:pr-64'}>
        <div className={'hidden md:block md:pr-4'}>
          <AuthIncentive />
        </div>
        <div className="relative transform rounded-lg bg-brand-neutral-50 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
          {authDialogType === AuthDialogType.SIGN_IN && (
            <SignInForm
              tooltipMessage="This feature requires a free account."
              switchToSignUp={() => dispatch(switchAuthDialog(AuthDialogType.SIGN_UP))}
              switchToForgotPassword={() => {
                dispatch(switchAuthDialog(AuthDialogType.FORGOT_PASSWORD))
              }}
              onSuccess={() => {
                dispatch(closeAuthDialog())
                navigate('/dashboard')
              }}
            />
          )}
          {authDialogType === AuthDialogType.FORGOT_PASSWORD && (
            <SendPasswordResetForm
              goToSignIn={() => dispatch(switchAuthDialog(AuthDialogType.SIGN_IN))}
              formTitle="Password Reset"
              formDescription="You will receive an email with a link to reset your password."
              buttonText="Submit"
            />
          )}
          {authDialogType === AuthDialogType.SIGN_UP && (
            <SignUpForm
              tooltipMessage="This feature requires a free account."
              switchToSignIn={() => dispatch(switchAuthDialog(AuthDialogType.SIGN_IN))}
              onSuccess={() => {
                dispatch(closeAuthDialog())
                navigate('/dashboard')
              }}
              allowReferral={true}
            />
          )}
        </div>
      </div>
      <div className={'block md:hidden'}>
        <MobileFeaturedLogos />
      </div>
    </div>
  )
}

import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useEffect } from 'react'
import { ClipboardIcon, DocumentArrowDownIcon, HandThumbDownIcon, HandThumbUpIcon } from '@heroicons/react/24/outline'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { getBrandName } from '@/util/enterprise'
import { ArtifactVersionRead, ArtifactVersionStatusType } from '@/artifacts/schemas'
import { selectArtifactById } from '@/artifacts/store/selectors'
import ArtifactButton from '@/artifacts/components/artifact-button'
import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'
import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'
import { kProblemDisplayingMessage } from '@/constants/constants-strings'
import ErrorMessageBubble from './ErrorMessageBubble'
import EventTextMarkdown from './EventTextMarkdown'
import { selectConversationWebSocketConnected } from '@/websocket_v2/ws2.selectors'

type AgentResponseEventBubbleProps = {
  conversationId: string
  eventId: string
}

export default function AgentResponseEventBubble(props: AgentResponseEventBubbleProps) {
  const { conversationId, eventId } = props
  const { trackEvent } = useAnalytics()
  const dispatch = useAppDispatch()

  // Redux state selectors
  const event = useAppSelector((state: RootState) => state.agentEventsState.events[conversationId]?.[eventId])
  const artifactVersion: ArtifactVersionRead | null = useAppSelector((state: RootState) =>
    selectArtifactById(state, { artifactVersionId: event?.artifact_version_id })
  )

  // If the websocket is connected, the conversation and messages/message chunks may not be complete yet
  const isConversationLoading = useAppSelector((state: RootState) => selectConversationWebSocketConnected(state, { conversationId }))

  // See ChatResponseBubble for reference on the following:
  // TODO: References
  // TODO: Confidence Indicator
  // TODO: Blinking cursor
  // TODO: Prompt Assist
  // TODO: Thumb feedback
  const messageFeedbackThumb = null
  // TODO: Message Feedback Dialog

  const text = event?.value

  // Trim the text
  const trimmedText = text?.trim() || ''

  // Message header
  const messageHeader = getBrandName()

  // Every time the text changes, call the onNewMessageText
  useEffect(() => {
    //   onNewMessageText()
  }, [text])

  // Handle Copy
  const handleCopyEventText = async () => {
    console.warn('Copy not implemented')
  }

  const handleDownloadDocx = async () => {
    console.warn('Download not implemented')
  }

  if (!event) {
    console.error('Event not found')
    return <ErrorMessageBubble message={kProblemDisplayingMessage} />
  }

  // If the text is empty, don't render the bubble yet
  if (!trimmedText) {
    return null
  }

  return (
    <div className="p-2 rounded-lg text-base whitespace-pre-wrap compatible-word-break bg-white mb-4">
      <div className="font-bold text-xl mb-1">{messageHeader}</div>
      <div className="text-sm leading-6 mb-2">
        <div id="chat-response-bubble">
          <EventTextMarkdown conversationId={conversationId} eventId={eventId} />
        </div>
      </div>

      {/* Message Action Icons */}
      {!isConversationLoading && !artifactVersion && (
        <div className="flex gap-x-2 items-center text-brand-neutral-500 text-xs mb-1">
          <button
            className={`p-1 mb-1 rounded-full hover:text-brand-700 active:bg-brand-100 ${messageFeedbackThumb == FeedbackThumb.UP ? 'bg-brand-100' : ''}`}
            onClick={() => {
              console.warn('Feedback not implemented')
            }}
          >
            <HandThumbUpIcon className={'h-5'} />
          </button>
          <button
            className={`p-1 mb-1 rounded-full hover:text-brand-700 active:bg-brand-100  ${messageFeedbackThumb == FeedbackThumb.DOWN ? 'bg-brand-100' : ''}`}
            onClick={() => {
              console.warn('Feedback not implemented')
            }}
          >
            <HandThumbDownIcon className={'h-5'} />
          </button>
          <button
            className="p-1 mb-1 rounded-full hover:text-brand-700 active:bg-brand-100"
            onClick={() => {
              trackEvent(AnalyticsEvent.CopyChatResponse)
              handleCopyEventText()
            }}
          >
            <ClipboardIcon className="h-5" />
          </button>
          <button
            className="p-1 mb-1 rounded-full hover:text-brand-700 active:bg-brand-100"
            onClick={() => {
              trackEvent(AnalyticsEvent.DownloadChatResponseToDocx)
              handleDownloadDocx()
            }}
          >
            <DocumentArrowDownIcon className="h-5" />
          </button>
        </div>
      )}

      {artifactVersion && (
        <>
          <ArtifactButton
            conversationId={conversationId}
            artifactVersion={artifactVersion}
            onCancelGenerating={() => {
              dispatch(AgentConversationFormActions.sendStop({ conversationId }))
            }}
          />

          {artifactVersion.status === ArtifactVersionStatusType.COMPLETED && (
            <div className="text-sm leading-6 mt-2">
              Your draft is ready for review and may be accessed via the artifact above. Is there anything you would like clarified or modified?
            </div>
          )}
        </>
      )}
    </div>
  )
}

import { BoltIcon } from '@heroicons/react/24/solid'
import * as Popover from '@radix-ui/react-popover'
import { Cross2Icon } from '@radix-ui/react-icons'
import { Link } from 'react-router-dom'
import { brandedAIFriendlyName } from '@/util/enterprise'

export default function ChatResponsePromptAssistIndicator() {
  return (
    <div className="mb-4">
      <hr className="my-4 border-t border-brand-neutral-200" />
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="flex items-center gap-x-1 text-sm text-brand-neutral-600 hover:bg-brand-neutral-100 rounded-md font-medium pl-1 pr-2">
            <div className="flex items-center justify-center w-4 h-4">
              <div className="flex items-center justify-center w-[13.3px] h-[13.3px] rounded-full bg-brand-500">
                <BoltIcon className="w-2.5 h-2.5 text-white" />
              </div>
            </div>
            Generated with Prompt Assist
          </button>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="rounded-lg bg-white w-[calc(100vw-32px)] max-w-[640px] animate-slideDownAndFade [filter:drop-shadow(0_0_10px_rgba(0,0,0,0.04))_drop-shadow(0_25px_25px_rgba(0,0,0,0.10))]"
            align="start"
            alignOffset={-8}
            sideOffset={5}
          >
            <div className="text-sm">
              <div className="flex items-center gap-x-2 mb-3 py-3 px-5 border-b border-brand-neutral-200">
                <div className="font-bold">Prompt Assist</div>
              </div>

              <div className="px-5 pb-4">
                <div className="mb-4">Prompt assist is a {brandedAIFriendlyName} feature to aid you when we believe your query could be improved by:</div>
                <ul className="list-disc pl-5 mb-4">
                  <li className="mb-2">using an alternative feature</li>
                  <li className="mb-2">answering a few follow up questions first</li>
                </ul>
                <Link to="/dashboard/settings" className="underline hover:text-brand-blue-500 underline-offset-4">
                  Settings
                </Link>
              </div>
            </div>

            <Popover.Close
              className="rounded-full h-[25px] w-[25px] inline-flex items-center justify-center text-brand-neutral-500 absolute top-[5px] right-[5px] hover:bg-brand-neutral-100 outline-none cursor-pointer"
              aria-label="Close"
            >
              <Cross2Icon />
            </Popover.Close>
            <Popover.Arrow className="fill-white" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}

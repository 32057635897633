import { saveSidebarItemsStateToLocalStorage } from '@/util/storage-utils'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export enum AuthDialogType {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}

export type RetitleConversationAction = {
  visible: boolean
  conversationId: string | null
}

// Define types for Sidebar
export type SidebarItemState = {
  itemId: string
  isOpen: boolean
}

export enum SidebarItemId {
  DraftAnalysis = 'draft-analysis',
  Research = 'research',
  Drives = 'drives',
  Tools = 'tools',
}

// Default sidebar items state
const defaultSidebarItemsState: SidebarItemState[] = [
  { itemId: SidebarItemId.DraftAnalysis, isOpen: true },
  { itemId: SidebarItemId.Research, isOpen: true },
  { itemId: SidebarItemId.Drives, isOpen: true },
  { itemId: SidebarItemId.Tools, isOpen: false },
]

// Define a type for the slice state
interface UIState {
  showAuthDialog: boolean
  authDialogType: AuthDialogType
  authDialogTooltipMessage?: string
  hideSidebars: boolean
  stripePortalDialogVisible: boolean
  showLegacyStripeCheckout: boolean
  legacyStripeCheckoutMessage: string
  referralDialogVisible: boolean
  sidebarItemsState: SidebarItemState[]
}

// Define the initial state using that type
const initialState: UIState = {
  showAuthDialog: false,
  authDialogType: AuthDialogType.SIGN_UP,
  authDialogTooltipMessage: undefined,
  hideSidebars: false,
  stripePortalDialogVisible: false,
  showLegacyStripeCheckout: false,
  legacyStripeCheckoutMessage: '',
  referralDialogVisible: false,
  sidebarItemsState: defaultSidebarItemsState,
}

export const uiStateSlice = createSlice({
  name: 'uiState',
  initialState,
  reducers: {
    openAuthDialog: (state, action: PayloadAction<{ authDialogType: AuthDialogType; tooltipMessage?: string }>) => {
      console.log('Opening auth dialog...')
      state.showAuthDialog = true
      state.authDialogType = action.payload.authDialogType
      state.authDialogTooltipMessage = action.payload.tooltipMessage
    },
    switchAuthDialog: (state, action: PayloadAction<AuthDialogType>) => {
      state.authDialogType = action.payload
    },
    closeAuthDialog: (state) => {
      state.showAuthDialog = false
    },
    toggleSidebarVisibility: (state) => {
      state.hideSidebars = !state.hideSidebars
    },
    setStripePortalDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.stripePortalDialogVisible = action.payload
    },
    setLegacyStripeCheckoutVisible: (state, action: PayloadAction<boolean>) => {
      state.showLegacyStripeCheckout = action.payload
    },
    setLegacyStripeCheckoutMessage: (state, action: PayloadAction<string>) => {
      state.legacyStripeCheckoutMessage = action.payload
    },
    clearLegacyStripeCheckoutMessage: (state) => {
      state.legacyStripeCheckoutMessage = ''
    },
    setReferralDialogVisible: (state, action: PayloadAction<boolean>) => {
      state.referralDialogVisible = action.payload
    },
    setSidebarItemOpen: (state, action: PayloadAction<string>) => {
      const itemId = action.payload
      const item = state.sidebarItemsState.find((item) => item.itemId === itemId)

      if (item) {
        item.isOpen = !item.isOpen
        saveSidebarItemsStateToLocalStorage(state.sidebarItemsState)
      }
    },
    setSidebarItemsState: (state, action: PayloadAction<SidebarItemState[]>) => {
      state.sidebarItemsState = action.payload
    },
  },
})

export const {
  openAuthDialog,
  switchAuthDialog,
  closeAuthDialog,
  toggleSidebarVisibility,
  setStripePortalDialogVisible,
  setLegacyStripeCheckoutVisible,
  setLegacyStripeCheckoutMessage,
  clearLegacyStripeCheckoutMessage,
  setReferralDialogVisible,
  setSidebarItemOpen,
  setSidebarItemsState,
} = uiStateSlice.actions

export default uiStateSlice.reducer

import { z } from 'zod'

//create enum for type Can be file or folder
export enum NodeType {
  FILE = 'file',
  FOLDER = 'folder',
}

export enum FileStatus {
  PROCESSING = 'processing',
  COMPLETE = 'complete',
  ERROR = 'error',
  OCR = 'ocr',
}

//Valid for folders
export const DriveBaseSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  type: z.nativeEnum(NodeType),
  folder_path: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  parent_id: z.string().uuid().nullable(),
  user_id: z.string(),
  markdown_location: z.string().optional().nullable(),
  events: z.any().optional().nullable(),
  parse_file_id: z.any().optional().nullable(),
  parse_file_sentences_id: z.any().optional().nullable(),
  error: z.any().optional().nullable(),
  source_location: z.string(),
  size: z.number(),
  status: z.nativeEnum(FileStatus),
  extension: z.string().nullable(),
  children: z.array(z.string()).optional().nullable(), //remove it if not needed
})

//valid for files
//Add files that are supposed to only be in files, like size, extension, status, source_path...
const DriveFileSchema = DriveBaseSchema.extend({})

export const FileDriveItemsSchema = z.object({
  folders: z.array(DriveBaseSchema),
  files: z.array(DriveFileSchema),
})

export type FilesDriveResponse = z.infer<typeof FileDriveItemsSchema>

//Union of both schemas
export type ItemNode = z.infer<typeof DriveBaseSchema> | z.infer<typeof DriveFileSchema>

export type FolderNode = z.infer<typeof DriveBaseSchema>
export type FileNode = z.infer<typeof DriveFileSchema>

import { kAgentRouterApiConversationsBasePath } from '@/constants/constants-api-paths'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { GetAgentConversationDataResponse, GetAgentConversationDataResponseSchema } from '../store/schemas'
import { ApiError } from '@/util/errors/api-error'

/**
 * Fetches the agent conversation data for a given conversation ID.
 * @param conversationId - The id of the conversation to fetch.
 * @returns The validated agent conversation data.
 * @throws An error if the fetch fails or the response is invalid.
 */
export async function getAgentConversationData(conversationId: string): Promise<GetAgentConversationDataResponse> {
  const sentry_transaction_id = nanoid()

  try {
    const auth = getAuth()
    const token = await auth.currentUser?.getIdToken()
    if (!token) {
      throw new Error("Could not retrieve the user's authentication token.")
    }

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    const apiUrl = `${kPaxtonAppApiBaseUrl()}${kAgentRouterApiConversationsBasePath}/${conversationId}`
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (!response.ok) {
      // Capture the error to Sentry if it's not a rate limit error
      if (response.status !== 429) {
        Sentry.withScope((scope) => {
          scope.setTags({ transaction_id: sentry_transaction_id })

          Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`), {
            extra: {
              requestUrl: apiUrl,
              requestOptions: requestOptions,
              response: JSON.stringify(response),
              data: JSON.stringify(data),
              onLine: navigator.onLine,
              cookieEnabled: navigator.cookieEnabled,
            },
          })
        })
      }

      throw new ApiError(response.status, `Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`)
    }

    // Validate the response data using the schema
    const validatedData = GetAgentConversationDataResponseSchema.safeParse(data)
    if (!validatedData.success) {
      throw new Error(`Schema validation failed for GetAgentConversationDataResponse: ${validatedData.error}`)
    }

    return validatedData.data
  } catch (error) {
    if (!(error instanceof ApiError)) {
      // Skip api errors already captured above
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(error, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
        })
      })
    }
    throw error
  }
}

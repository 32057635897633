import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'

import AgentResponseEventBubble from './AgentResponseEventBubble'
import { AgentEventType } from '@/agent/events/store/schemas'
import AgentUserQueryEventBubble from './UserQueryEventBubble'
import { kProblemDisplayingMessage, kProblemGeneratingResponseMessage } from '@/constants/constants-strings'
import ErrorMessageBubble from './ErrorMessageBubble'
import { selectAgentEventById } from '@/agent/events/store/selectors'
import ResponseStoppedEventBubble from './ResponseStoppedEventBubble'

type EventBubbleConstructorProps = {
  conversationId: string
  eventId: string
}

export default function EventBubbleConstructor(props: EventBubbleConstructorProps) {
  const { conversationId, eventId } = props

  const event = useAppSelector((state: RootState) => selectAgentEventById(state, { conversationId, eventId }))

  if (!event) {
    return <div className="p-2 text-sm bg-red-200">{kProblemDisplayingMessage}</div>
  }

  switch (event.type) {
    case AgentEventType.USER_QUERY: {
      return <AgentUserQueryEventBubble conversationId={conversationId} eventId={eventId} />
    }

    case AgentEventType.AGENT_RESPONSE:
      return <AgentResponseEventBubble conversationId={conversationId} eventId={eventId} />

    case AgentEventType.CLIENT_ERROR_EVENT: {
      const message = event?.value ?? kProblemGeneratingResponseMessage
      return <ErrorMessageBubble message={message} />
    }
    case AgentEventType.STOP_REQUEST_RECEIVED:
      return <ResponseStoppedEventBubble />

    case AgentEventType.SERVER_ERROR:
      return <ErrorMessageBubble message={kProblemGeneratingResponseMessage} />

    default:
      console.error('Unknown event type: ', event.type)
      return <ErrorMessageBubble message={kProblemDisplayingMessage} />
  }
}

import React, { useState } from 'react'
import { getMonthlyCost } from '@/routes/dashboard/subscribe/store/subscription.slice'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { SubscriptionType, BillingCycle } from '@/firebase/auth/auth-jwt-schema'
import { kPaxtonEnterpriseEmailToHref } from '@/constants/constants-strings'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import * as Switch from '@radix-ui/react-switch'

type OrganizationSetupProps = {
  orgName: string
  setOrgName: (orgName: string) => void
  emails: string[]
  setEmails: (emails: string[]) => void
  emailValue: string
  setEmailValue: (emailValue: string) => void
  // promise void
  advance?: (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>
  isLoading?: boolean
  annualBilling: boolean
  setAnnualBilling: (annualBilling: boolean) => void
}

const OrganizationSetup: React.FC<OrganizationSetupProps> = ({
  orgName,
  setOrgName,
  emails,
  setEmails,
  emailValue,
  setEmailValue,
  advance,
  isLoading,
  annualBilling,
  setAnnualBilling,
}) => {
  const state = useAppSelector((state: RootState) => state.subscriptionState)
  const [emailError, setEmailError] = useState<string | null>(null)

  const monthlyCost = getMonthlyCost(state, BillingCycle.MONTHLY, SubscriptionType.STANDARD)
  const annualMonthlyCost = getMonthlyCost(state, BillingCycle.ANNUAL, SubscriptionType.STANDARD)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(e.target.value)
    setEmailError(null) // Clear error when user types
  }

  const validateAndAddEmail = (email: string) => {
    const trimmedEmail = email.trim()
    if (isValidEmail(trimmedEmail) && !emails.includes(trimmedEmail)) {
      setEmails([...emails, trimmedEmail])
      setEmailValue('')
      setEmailError(null)
      return true
    }
    setEmailError('Invalid email or already added')
    return false
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === ' ' || e.key === 'Enter') && emailValue) {
      e.preventDefault() // Prevent space from being added to the input
      validateAndAddEmail(emailValue)
    } else {
      setEmailError(null) // Clear error for other key presses
    }
  }

  const handleAddEmail = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    validateAndAddEmail(emailValue)
  }

  const removeEmail = (emailToRemove: string) => {
    setEmails(emails.filter((email) => email !== emailToRemove))
  }

  const isValidEmail = (email: string) => {
    // Basic email validation regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
  }

  const isCreateDisabled = !orgName.trim() || isLoading

  return (
    <form className="pl-6 mt-8">
      <div className="space-y-12">
        <div className="border-b border-brand-neutral-900/10 pb-12">
          <h3 className="text-lg font-medium leading-6 text-brand-neutral-900">Pricing & Terms</h3>
          <p className="mt-1 text-sm leading-6 text-brand-neutral-600">
            By creating an organization you will transition to a monthly payment plan. Each user will be charged ${monthlyCost} / month pro-rated for the
            remaining days of the month. Your default payment method will be transferred to a new subscription.
          </p>
          <div className="flex flex-row justify-start items-start space-x-6 my-4">
            <div className="bg-brand-neutral-100 p-4 rounded-lg">
              <h4 className="text-sm font-semibold">Up to 20 Users</h4>
              <p className="text-sm text-brand-neutral-600">
                ${monthlyCost} per user per month, or ${annualMonthlyCost} per user per month when billed annually.
              </p>
            </div>
            <div className="bg-brand-neutral-100 p-4 rounded-lg">
              <h4 className="text-sm font-semibold">More than 20 users?</h4>
              <a href={kPaxtonEnterpriseEmailToHref} className="text-brand-500 hover:text-brand-400 underline">
                <h4 className="text-sm font-semibold">Contact us for custom enterprise pricing</h4>
              </a>
            </div>
          </div>
          <div className="sm:col-span-4 py-4">
            <h2 className="text-base font-semibold leading-7 text-brand-neutral-900">Billing Frequency</h2>
            <div className="mt-5 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
              <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                <div className="max-w-xl text-sm text-brand-neutral-500">
                  <p>Save 20% by choosing the Annual Plan. ${annualMonthlyCost} per user per month, billed annually.</p>
                </div>
                <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                  <div className="flex items-center">
                    <Switch.Root
                      className="w-[42px] h-[25px] bg-brand-neutral-400 hover:bg-brand-neutral-500 rounded-full relative shadow-black focus:shadow-[0_0_0_2px] focus:shadow-black data-[state=checked]:bg-brand-500 outline-none cursor-pointer"
                      id="airplane-mode"
                      checked={annualBilling}
                      onCheckedChange={setAnnualBilling}
                    >
                      <Switch.Thumb className="block w-[21px] h-[21px] bg-brand-neutral-50 rounded-full shadow-[0_1px_2px] shadow-blackA4 transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
                    </Switch.Root>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:col-span-4 py-4">
            <h2 className="text-base font-semibold leading-7 text-brand-neutral-900">Organization Name</h2>
            <p className="mt-1 text-sm leading-6 text-brand-neutral-600">This will appear on the sidebar for all users of your organization.</p>

            <div className="mt-2">
              <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-brand-neutral-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-brand-500 sm:max-w-md">
                <input
                  id="username"
                  name="username"
                  type="text"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                  placeholder=""
                  autoComplete="username"
                  className="block w-full rounded-md border-0 py-1.5 max-w-md text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 placeholder:text-brand-neutral-500 focus:ring-2 focus:ring-inset focus:ring-brand-500 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>

          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <label htmlFor="emails" className="block text-sm font-medium leading-6 text-brand-neutral-900">
                Add Team Emails
              </label>
              <p className="text-sm italic text-brand-neutral-500">
                Invited team members will receive an email with a link to join your organization. You can add more later.
              </p>
              <div className="mt-2 flex items-center gap-2">
                <input
                  type="text"
                  id="emails"
                  name="emails"
                  value={emailValue}
                  onChange={handleInputChange}
                  onKeyDown={handleInputKeyDown}
                  className={`block w-full rounded-md border-0 py-1.5 max-w-md text-brand-neutral-900 shadow-sm ring-1 ring-inset 
                    ${emailError ? 'ring-red-500 focus:ring-red-500' : 'ring-brand-neutral-300 focus:ring-brand-500'} 
                    placeholder:text-brand-neutral-500 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6`}
                  placeholder="Type an email and press space to add"
                />
                <button
                  type="button"
                  className={`p-2 rounded-md font-medium text-sm transition-colors duration-150 text-white ${
                    emailError ? 'bg-brand-neutral-300 cursor-not-allowed' : 'bg-brand-neutral-500 hover:bg-brand-neutral-600 text-brand-neutral-700'
                  }`}
                  onClick={handleAddEmail}
                  disabled={!!emailError}
                >
                  Add
                </button>
              </div>
              {emailError && <p className="mt-1 text-sm italic text-red-500">{emailError}</p>}
              <div className="mt-2 flex flex-wrap gap-2">
                {emails.map((email) => (
                  <div key={email} className="inline-flex items-center px-2 py-1 rounded-md text-sm font-medium bg-brand-neutral-100 text-brand-neutral-800">
                    <span>{email}</span>
                    <button
                      type="button"
                      onClick={() => removeEmail(email)}
                      className="ml-1 w-5 h-5 flex items-center justify-center rounded-full border border-brand-neutral-300 text-brand-neutral-500 hover:bg-brand-neutral-200 hover:text-brand-neutral-700 focus:outline-none transition-colors duration-150"
                      aria-label={`Remove ${email}`}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {advance && (
            <div className="mt-6 flex items-center justify-end space-x-4">
              {isCreateDisabled && !isLoading && (
                <p className="text-sm text-brand-neutral-500 italic">Please enter an organization name to create the organization.</p>
              )}
              <button
                type="button"
                className={`rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500 disabled:opacity-50 disabled:cursor-not-allowed`}
                onClick={advance}
                disabled={isCreateDisabled}
              >
                <span className="flex items-center gap-2">
                  Create organization
                  {isLoading && <CircularProgressContinuousSized hexColor={'#eee'} size={20} thickness={4} />}
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

export default OrganizationSetup

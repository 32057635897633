import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileNode, FolderNode } from '../schemas/files-drive-schema'

export type FilesDriveState = {
  folders: FolderNode[]
  files: FileNode[]
  currentFolderId: string | null
  currentFolder: FolderNode | null
  loading: boolean
  error: string | null
}

const initialState: FilesDriveState = {
  folders: [],
  files: [],
  currentFolderId: null,
  currentFolder: {} as FolderNode,
  loading: false,
  error: null,
}

export const filesDriveSlice = createSlice({
  name: 'filesDrive',
  initialState,
  reducers: {
    //Disable since we will use the folderId for the fetchItems action.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchItems: (state, _action: PayloadAction<{ folderId: string }>) => {
      // handled by the listener middleware
      state.loading = true
      state.error = null
    },
    setDriveItems: (state, action: PayloadAction<{ folders: FolderNode[]; files: FileNode[] }>) => {
      const { folders, files } = action.payload
      state.folders = [...folders]
      state.files = [...files]
      state.loading = false
      state.error = null
    },
    setCurrentFolderId: (state, action: PayloadAction<{ folderId: string }>) => {
      state.currentFolderId = action.payload.folderId
    },
    setCurrentFolder: (state, action: PayloadAction<{ folderId: string }>) => {
      state.currentFolder = state.folders.find((f) => f.id === action.payload.folderId) || null
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
      state.loading = false
    },
    //Add more reducers as needed here.
  },
})

export const FilesDriveActions = filesDriveSlice.actions
export default filesDriveSlice.reducer

import { createListenerMiddleware } from '@reduxjs/toolkit'
import { WS2SendPayloadType, WS2SendMessagePayload } from './ws2.schemas'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getIdTokenForWS2 } from './ws2-util'
import { WS2Actions } from './ws2.slice'
import { ws2EventHandler } from './ws2-payload-handler'
import { kAgentWs2BasePath } from '@/constants/constants-api-paths'
import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'
import { AgentEventsActions } from '@/agent/events/store/slice'
import { kProblemGeneratingResponseMessage } from '@/constants/constants-strings'

// Create the middleware for these listeners
export const ws2ListenerMiddleware = createListenerMiddleware()

// Map to store WebSocket instances keyed by chat_id
// Allows other listeners to access the WebSocket instances
const websocketMap = new Map<string, WebSocket>()

/**
 * Connect And Send Listener
 * When the UX dispatches WS2Actions.connectAndSend
 * - Get the latest auth id token (force refresh if within 5 minutes of expiration)
 * - Open the WebSocket connection
 * - Attach event handlers for receiving data
 * - Send the message payload to the server
 * - Handle payloads, errors, and close events
 *
 * Initial Connection Retries:
 * - TODO: Will the browser automatically try again if connection fails?
 *
 * Disconnect / Reconnect:
 * - If the connection is lost, the app will fallback to a polling method for updates
 * - A new connection can be established next time the user interacts with the app
 * - The system is designed for each request to have a new dedicated connection (backend / cloud logistical requirement)
 */
ws2ListenerMiddleware.startListening({
  actionCreator: WS2Actions.connectAndSend,
  effect: async (action, listenerApi) => {
    console.log('WebSocket connectAndSend listener triggered.')
    // The id (used as the key for the WebSocket instance)
    const conversationId = action.payload.conversationId

    // Stringify the payload
    const payloadString = JSON.stringify(action.payload)

    try {
      // Step 1: Get the latest auth id token
      const idToken = await getIdTokenForWS2()

      // Step 2: Initialize WebSocket connection
      const url = kPaxtonAppApiBaseUrl() + `${kAgentWs2BasePath}/assistant/${conversationId}`
      console.log(`Connecting to WebSocket server at ${url}`)
      const websocket = new WebSocket(url)

      // Event Handlers
      const handleOpen = async () => {
        console.log('WebSocket connection opened.')

        // Store the WebSocket instance
        websocketMap.set(conversationId, websocket)

        // Set the connection status to connected in state
        listenerApi.dispatch(WS2Actions.connected({ conversationId }))

        // Mark the form as submitting
        listenerApi.dispatch(AgentConversationFormActions.setQueryIsSubmitting({ conversationId, isSubmitting: true }))

        // Immediately send the ID token to the server
        websocket.send(idToken)

        // Immediately send the payload to the server
        websocket.send(payloadString)
      }

      const handleError = (event: Event) => {
        console.error('WebSocket connection error:', event)
        listenerApi.dispatch(WS2Actions.setCloseError({ conversationId, hasError: true }))
        listenerApi.dispatch(
          AgentEventsActions.insertClientErrorEvent({
            conversationId: conversationId,
            value: 'Connection error. Please try again.',
          })
        )
      }

      const handleClose = (event: CloseEvent) => {
        console.log('WebSocket connection closed:', event)
        listenerApi.dispatch(WS2Actions.disconnected({ conversationId, code: 0, reason: 'WebSocket close reason' }))

        // Mark the form as not submitting
        listenerApi.dispatch(AgentConversationFormActions.setQueryIsSubmitting({ conversationId, isSubmitting: false }))

        /*
         * If the connection was closed for reasons other than:
         * - A normal close (code 1000)
         * - A server error gracefully handled by the backend (code 1011)
         *
         * Dispatch a client error event to the UX.
         */
        if (event.code !== 1000 && event.code !== 1011) {
          listenerApi.dispatch(
            AgentEventsActions.insertClientErrorEvent({
              conversationId: conversationId,
              value: kProblemGeneratingResponseMessage,
            })
          )
        }
      }

      const handleMessage = (event: MessageEvent) => {
        ws2EventHandler(conversationId, event)
      }

      // Attach event handlers
      websocket.addEventListener('open', handleOpen)
      websocket.addEventListener('error', handleError)
      websocket.addEventListener('close', handleClose)
      websocket.addEventListener('message', handleMessage)

      // Keep the effect function alive until the listener is aborted
      // (When the connection is closed, it will attempt to reconnect, do not assume its permanent)
      await new Promise<void>((resolve) => {
        // Resolve when the listener is aborted
        listenerApi.signal.addEventListener('abort', () => {
          console.log('Listener aborted')
          resolve()
        })
      })

      // Cleanup
      websocket.removeEventListener('open', handleOpen)
      websocket.removeEventListener('error', handleError)
      websocket.removeEventListener('close', handleClose)
      websocket.removeEventListener('message', handleMessage)
      websocket.close()

      // Remove the WebSocket instance from the map
      websocketMap.delete(conversationId)
    } catch (error) {
      console.error('Error connecting to WebSocket server:', error)
      listenerApi.dispatch(
        AgentEventsActions.insertClientErrorEvent({
          conversationId: conversationId,
          value: 'Connection error. Please try again.',
        })
      )
      throw error
    }
  },
})

// Handle disconnect action
ws2ListenerMiddleware.startListening({
  actionCreator: WS2Actions.manualDisconnect,
  effect: async (action) => {
    console.log('WebSocket manualDisconnect listener triggered.')

    const { conversationId } = action.payload

    // Get the active WebSocket connection
    const activeWebSocket = websocketMap.get(conversationId)
    if (!activeWebSocket) {
      console.error('WebSocket connection does not exist.')
      return
    }

    // Close the active WebSocket connection, if it exists
    if (activeWebSocket) {
      activeWebSocket.close()
      console.log('WebSocket connection manually closed.')

      // Remove the WebSocket instance from the map
      websocketMap.delete(conversationId)
    }
  },
})

// Send the stop signal through the websocket connection
ws2ListenerMiddleware.startListening({
  actionCreator: WS2Actions.stopTask,
  effect: async (action) => {
    const { conversationId } = action.payload

    // Get the active WebSocket connection
    const activeWebSocket = websocketMap.get(conversationId)
    if (!activeWebSocket) {
      console.error('WebSocket connection does not exist.')
      return
    }

    console.log('Sending stop signal to WebSocket server.')
    // Construct the payload
    const payload: WS2SendMessagePayload = {
      type: WS2SendPayloadType.CONVERSATION_STOP,
      payload: null,
    }

    // Stringify the payload
    const payloadString = JSON.stringify(payload)

    // Send the payload
    activeWebSocket.send(payloadString)
  },
})

import { ChatV2MessageType } from '@/chat-common/store/chat-v2.slice'
import ChatSuggestedQuestionsBubble from './chat-suggested-questions-bubble'
import ChatUserQueryBubble from './chat-user-query-bubble'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import ChatResponseBubble from './chat-response-bubble'
import ChatClientErrorMessageBubble from './chat-client-error-bubble'
import { selectMessageType } from '@/chat-common/store/chat-v2.selectors'
import ChatRetryUserQueryButton from './chat-retry-user-query-button'
import { ChatV2Message } from '@/chat-common/schemas/chat-v2.schemas'

type BubbleConstructorProps = {
  chatId: string
  messageId: string
  openFeedbackDialog: (message: ChatV2Message, positive: boolean) => void
  onNewMessageText: () => void
}

/**
 * Bubble Constructor
 * Creates the appropriate bubble based on the message type
 * @param props
 * @returns
 */
export default function BubbleConstructor(props: BubbleConstructorProps) {
  const { chatId, messageId, openFeedbackDialog, onNewMessageText } = props

  // Create custom selector for message type
  const messageType: ChatV2MessageType | null = useAppSelector((state: RootState) =>
    selectMessageType(state, {
      messageId,
      chatId,
    })
  )

  if (!messageType) {
    console.error('Invalid message type.')
    return <div className={'p-5 bg-red-200'}>There was a problem displaying this message.</div>
  }

  switch (messageType) {
    case ChatV2MessageType.suggested_questions:
      return <ChatSuggestedQuestionsBubble chatId={chatId} messageId={messageId} />

    case ChatV2MessageType.user_query:
      return <ChatUserQueryBubble chatId={chatId} messageId={messageId} />

    case ChatV2MessageType.response:
      return <ChatResponseBubble chatId={chatId} messageId={messageId} openFeedbackDialog={openFeedbackDialog} onNewMessageText={onNewMessageText} />

    case ChatV2MessageType.client_error: {
      return (
        <>
          <ChatClientErrorMessageBubble chatId={chatId} messageId={messageId} openFeedbackDialog={openFeedbackDialog} />

          {/* CONDITIONAL: SHOW RETRY BUTTON  */}
          <ChatRetryUserQueryButton conversationId={chatId} />
        </>
      )
    }
  }
}

import { createListenerMiddleware } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '@/store/store'
import { ArtifactsActions } from './slice'
import { getArtifactVersion } from '../fetch/get-artifact-version'
import { ArtifactVersionStatusType } from '../schemas'
import * as Sentry from '@sentry/react'

export const artifactListenerMiddleware = createListenerMiddleware()
const startListener = artifactListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()

/**
 * Artifact Version Status Poll
 *
 * Recursively checks for updates to an artifact version until it is no longer "generating".
 */
startListener({
  actionCreator: ArtifactsActions.startArtifactVersionStatusPoll,
  effect: async (action, listenerApi) => {
    const { artifactVersionId } = action.payload

    // Max polling time (10 minutes)
    const maxPollingTime = 10 * 60 * 1000
    const startTime = Date.now()

    async function executePoll(artifactVersionId: string) {
      const duration = Date.now() - startTime

      // Exit if the max polling time has been reached
      if (duration > maxPollingTime) {
        Sentry.captureException(new Error('Max polling time reached for artifact version'), {
          extra: {
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
            pollingMinutes: duration / 60000,
            artifactVersionId: artifactVersionId,
          },
        })
        return
      }

      // Fetch the artifact version
      const artifactVersion = await getArtifactVersion(artifactVersionId)

      // If the artifact is still generating, delay and recursively fetch again
      if (artifactVersion.status === ArtifactVersionStatusType.GENERATING) {
        try {
          await new Promise((resolve) => setTimeout(resolve, 5000))
          await executePoll(artifactVersionId)
          return
        } catch (e) {
          // Continue to poll but increase the delay in case of fetch error
          await new Promise((resolve) => setTimeout(resolve, 15000))
          await executePoll(artifactVersionId)
          return
        }
      }

      // Upsert the artifact version
      listenerApi.dispatch(ArtifactsActions.upsertArtifactVersion(artifactVersion))
    }

    // Start fetching the artifact version
    await executePoll(artifactVersionId)
  },
})

import { useAppDispatch } from '@/store/store-hooks'
import {
  chatV2CurrentSourceDocumentQueryAddSelectedFile,
  chatV2CurrentSourceDocumentQueryClearAll,
  chatV2CurrentSourceDocumentQueryToggleSelectedFile,
} from '@/chat-common/store/chat-v2.slice'
import { SourceSelectionDialog } from './source-selection-dialog'

type DocumentSelectionDialogProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversationId: string
}

/**
 * DocQuerySelectionDialog component.
 *
 * This component is used exclusively in the Document Query features
 * to allow the user to select documents for the Document Query to use by wrapping the SourceSelectionDialog component with the appropriate dispatch functions.
 *
 * @param props - The properties for the DocQuerySelectionDialog component.
 * @returns A JSX element representing the document selection dialog.
 */
export default function DocQuerySelectionDialog(props: DocumentSelectionDialogProps) {
  const { open, onClose, conversationId } = props
  const dispatch = useAppDispatch()

  const toggleFileSelection = (file: string) => {
    dispatch(chatV2CurrentSourceDocumentQueryToggleSelectedFile({ conversationId: conversationId, filePath: file }))
  }

  const addFileSelection = (file: string) => {
    dispatch(chatV2CurrentSourceDocumentQueryAddSelectedFile({ conversationId: conversationId, filePath: file }))
  }

  const clearFilesSelection = () => {
    dispatch(chatV2CurrentSourceDocumentQueryClearAll({ conversationId: conversationId }))
  }

  return (
    <SourceSelectionDialog
      conversationId={conversationId}
      open={open}
      onClose={onClose}
      dispatchFunctions={{
        addFileSelection,
        clearFilesSelection,
        toggleFileSelection,
      }}
    />
  )
}

import chatV2FormatResponseStringPrintable from '@/chat-common/util/chat-v2-format-response-printable'
import getUniqueReferenceNumbersInMessage from '@/chat-common/util/chat-v2-get-references-in-message'
import ChatMessageBubbleReferencePrintable from './chat-message-bubble-reference-printable'
import { getBrandName } from '@/util/enterprise'
import { ChatV2MessageReferenceType } from '@/chat-common/store/chat-v2.slice'
import { ChatV2Message } from '@/chat-common/schemas/chat-v2.schemas'

type ChatResponseBubbleProps = {
  message: ChatV2Message
  references: Record<string, ChatV2MessageReferenceType>
}

export default function ChatResponsePrintableBubble(props: ChatResponseBubbleProps) {
  const { message, references } = props

  const referencesInMessage = getUniqueReferenceNumbersInMessage(message)

  const usedReferences = Object.keys(references).filter((key) => referencesInMessage.includes(key))
  const unusedReferences = Object.keys(references).filter((key) => !referencesInMessage.includes(key))
  const formattedText = chatV2FormatResponseStringPrintable(message, references)
  const messageHeader = getBrandName()
  const referencesCount = Object.keys(references).length

  return (
    <div>
      <h2>{messageHeader}</h2>
      <p>{formattedText}</p>

      {referencesCount > 0 && (
        <div>
          <hr />
          <h3>References</h3>
          {usedReferences.map((referenceKey) => (
            <ChatMessageBubbleReferencePrintable key={referenceKey} referenceKey={referenceKey} references={references}  />
          ))}
          {unusedReferences.length > 0 && (
            <>
              <hr />
              <h3>Related: </h3>
              {unusedReferences.map((referenceKey) => (
                <ChatMessageBubbleReferencePrintable key={referenceKey} referenceKey={referenceKey} references={references} />
              ))}
            </>
          )}
        </div>
      )}
    </div>
  )
}

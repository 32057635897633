import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { Folder } from '@mui/icons-material'
import { Cross2Icon, TrashIcon } from '@radix-ui/react-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { GlobalToastType } from '@/constants/constants-ui'
import { useSelector } from 'react-redux'
import CreateFolderDialog from '../../files/create-folder-components/CreateFolderDialog'
import DeleteConfirmDialog from '../../files/dialogs/files-delete-confirm'
import FileDeleteConfirmDialog from '../../files/dialogs/single-file-delete-confirm'
import { downloadFile } from '../../files/files-utils'
import FilesActionsMenu from '../../files/FilesActionsMenu'
import RenameItemDialog from '../../files/dialogs/rename-file'
import { DynamicStatusIconFromFile } from '@/chat-common/components/source-dialogs/utils/file-status'
import { useState } from 'react'
import { FileNode, FolderNode, NodeType } from '../schemas/files-drive-schema'
import { useAppDispatch } from '@/store/store-hooks'
import { selectFoldersContent } from '../store/files-drive.selectors'
import { RootState } from '@/store/store'

interface FileDriveListProps {
  currentFolderId: string | null
}

export default function FileDriveList({ currentFolderId }: FileDriveListProps) {
  // Local state
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [activeItem, setActiveItem] = useState<FileNode | FolderNode | null>(null)

  // Dialogs
  const [openRenameFileDialog, setOpenRenameFileDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showSingleDeleteDialog, setShowSingleDeleteDialog] = useState(false)
  const [openCreateFolderDialog, setOpenCreateFolderDialog] = useState(false)

  const location = useLocation()
  const navigate = useNavigate()

  const { pathname } = location
  const baseDriveLocationPath = pathname.split('/').slice(0, 5).join('/')

  const dispatch = useAppDispatch()

  const { folders, files, isLoading } = useSelector((state: RootState) => selectFoldersContent(state, currentFolderId))


  if (isLoading) return <CircularProgressContinuousSized size={30} thickness={5} />

  // Helper functions
  const isItemSelected = (itemId: string) => selectedItems.includes(itemId)

  const handleFileCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation()
    const { checked, value } = e.currentTarget
    setSelectedItems((prev) => {
      if (checked) {
        return [...prev, value]
      } else {
        return prev.filter((item) => item !== value)
      }
    })
  }

  function handleFolderClick(folder: FolderNode) {
    navigate(`${baseDriveLocationPath}/${encodeURI(folder.id)}`)
    clearSelectedItems()
  }

  function clearSelectedItems() {
    setSelectedItems([])
  }

  function selectAllItems() {
    const allItemIds = [...files, ...folders].map((file) => file.id)
    setSelectedItems(allItemIds)
  }

  function haveSameItems(selectedFiles: string[], allItemIds: string[]) {
    const setA = new Set(selectedFiles)
    const setB = new Set(allItemIds)

    return setA.size === setB.size && [...setA].every(item => setB.has(item))
  }

  // Items array for checking "select all"
  const allItemIds = [...files, ...folders].map((item) => item.id)
  const allSelected = haveSameItems(selectedItems, allItemIds)

  async function handleRename(newName: string): Promise<void> {
    if (!activeItem) {
      //TODO: Handle this error and check need to show a toast / send error to sentry. 
      console.error('No active item selected to rename')
      return
    }
    console.log('Renaming item:', activeItem, 'to', newName, 'not implemented yet')
  }

  async function handleDeleteFile() {
    if (!activeItem) {
      console.error('No active item selected to delete')
      return
    }
    console.log('Deleting file:', activeItem, 'not implemented yet')
  }

  async function handleDeleteConfirm() {
    console.log('Deleting items: ', selectedItems, 'not implemented yet')
    // After deletion, clear the selection
    clearSelectedItems()
  }

  async function handleFileDownload(file: FileNode) {
    try {
      await downloadFile(file.source_location, file.name)
      dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: file.name + ' downloaded', durationMs: 2000 }))
    } catch (e) {
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: file.name + ' download failed', durationMs: 2000 }))
      throw e
    }
  }

  function formatDate(date: string) {
    const dateObj: Date = new Date(date)
    return Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
    }).format(dateObj)
  }

  function formatSize(size: number) {
    if (size < 1000000) return `${Math.round(size / 1000)} KB`
    return `${(size / 1000000).toFixed(1)} MB`
  }

  const fileCountLabel = selectedItems.length === 1 ? 'file' : 'files'
  const toolbarDisabled = selectedItems.length === 0

  return (
    <div>
      {/* Delete Confirmation Dialogs */}
      <DeleteConfirmDialog
        title={selectedItems.length > 1 ? 'Delete Files Permanently?' : 'Delete File Permanently?'}
        fileMessage={selectedItems.length > 0 ? `${selectedItems.length} ${fileCountLabel} will be deleted forever.` : null}
        folderMessage={''}
        onConfirm={handleDeleteConfirm}
        closeDialog={() => setShowDeleteDialog(false)}
        visible={showDeleteDialog}
      />

      <FileDeleteConfirmDialog
        fileName={activeItem?.name}
        onConfirm={handleDeleteFile}
        closeDialog={() => setShowSingleDeleteDialog(false)}
        visible={showSingleDeleteDialog}
      />

      <RenameItemDialog
        open={openRenameFileDialog}
        onSave={(data) => handleRename(data.itemName)}
        onClose={() => setOpenRenameFileDialog(false)}
      />

      <CreateFolderDialog
        open={openCreateFolderDialog}
        onSave={async (data) => {
          const { folderName } = data
          console.log('Creating folder:', folderName, 'not implemented yet')
        }}
        onClose={() => setOpenCreateFolderDialog(false)}
      />

      {/* Toolbar */}
      <div className={`px-2 mb-1 flex gap-x-2 ${!toolbarDisabled ? 'opacity-100' : 'opacity-50'} transition-all duration-200 ease-in-out`}>
        <button
          onClick={clearSelectedItems}
          disabled={toolbarDisabled}
          className={`flex gap-x-1 px-2 py-1 pr-3 items-center rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${toolbarDisabled ? 'cursor-default' : 'cursor-pointer'}`}
        >
          <Cross2Icon width={20} height={20} />
          <div>{selectedItems.length} selected</div>
        </button>

        <button
          onClick={() => setShowDeleteDialog(true)}
          disabled={toolbarDisabled}
          className={`flex gap-x-2 px-2 py-1 pr-3 rounded-full text-sm hover:border-brand-700 hover:text-brand-700 hover:bg-brand-50 transition-all duration-200 ease-in-out ${toolbarDisabled ? 'cursor-default' : 'cursor-pointer'}`}
        >
          <TrashIcon width={20} height={20} />
          <div>Delete</div>
        </button>
      </div>

      {/* Table Header */}
      <div className={'p-2 grid grid-cols-[19px_100%_0px_0px] sm:grid-cols-[19px_auto_166px_0px] md:grid-cols-[19px_auto_175px_82px] items-end overflow-hidden'}>
        <div>
          <input
            type="checkbox"
            checked={allSelected}
            onChange={() => (allSelected ? clearSelectedItems() : selectAllItems())}
            className={'w-4 h-4 transition-all ease-in-out duration-200 checked:bg-brand-600 rounded-sm'}
          />
        </div>
        <p className={'text-sm px-2'}>Name</p>
        <p className={'text-sm px-2 hidden sm:block'}>Last modified</p>
        <p className={'text-sm px-2 hidden md:block'}>File size</p>
      </div>

      <div>
        {/* Folders */}
        {folders.map((folder) => (
          <div
            key={folder.id}
            className="border-t-[1px] py-2 grid grid-cols-[100%_0px_0px] sm:grid-cols-[auto_175px_0px] md:grid-cols-[auto_175px_90px] items-center overflow-hidden cursor-pointer hover:bg-brand-100"
            onClick={() => handleFolderClick(folder)}
          >
            <div className="px-2 grid grid-cols-[22px_32px_auto] items-center">
              <input
                type="checkbox"
                checked={isItemSelected(folder.id)}
                onChange={handleFileCheckboxChange}
                value={folder.id}
                className={'w-4 h-4 transition-all ease-in-out duration-200 checked:bg-brand-600 rounded-sm'}
                onClick={(e) => e.stopPropagation()}
              />
              <Folder />
              <p className="text-sm overflow-hidden">{folder.name}</p>
            </div>
          </div>
        ))}

        {/* Files */}
        {files.map((file) => {
          if (file.type === NodeType.FILE) {
            const { status, id, name, error, size, created_at } = file
            return (
              <div
                key={id}
                className="group border-t-[1px] py-[6px] grid grid-cols-[100%_0px_0px] sm:grid-cols-[auto_175px_0px] md:grid-cols-[auto_175px_90px] items-center overflow-hidden hover:bg-brand-100"
              >
                <div className={'px-2 grid grid-cols-[22px_30px_auto] items-center'}>
                  <input
                    type="checkbox"
                    checked={isItemSelected(id)}
                    onChange={handleFileCheckboxChange}
                    value={id}
                    className={'w-4 h-4 transition-all ease-in-out duration-200 checked:bg-brand-600 rounded-sm'}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <div>{DynamicStatusIconFromFile(status)}</div>
                  <div className={'text-sm overflow-hidden flex items-center'}>
                    <p className={'flex-grow'}>
                      {name}
                      {error ? <span className={'text-red-700 ml-1'}>Error Processing File</span> : ''}
                    </p>
                    <FilesActionsMenu
                      onSelect={() => setActiveItem(file)}
                      onRenameClick={() => setOpenRenameFileDialog(true)}
                      onDownloadClick={() => {
                        if (status === 'complete') {
                          handleFileDownload(file)
                        }
                      }}
                      onDeleteClick={() => {
                        setActiveItem(file)
                        setShowSingleDeleteDialog(true)
                      }}
                    />
                  </div>
                </div>
                <p className={'text-sm px-2 hidden sm:block'}>{formatDate(created_at)}</p>
                <p className={'text-sm px-2 hidden md:block'}>{formatSize(size)}</p>
              </div>
            )
          }
          return <p>Unknown file type</p>
        })}
      </div>
    </div>
  )
}

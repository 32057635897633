/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WS2ConnectAndSendPayload } from './ws2.schemas'

export type WS2ConnectionStatus = {
  connected: boolean
  closeError: boolean
  closeCode: number | null
  closeReason: string | null
}

export type WS2Connections = {
  [connectionId: string]: WS2ConnectionStatus
}

export type WS2State = {
  connections: WS2Connections
}

const initialState: WS2State = {
  connections: {},
}

const ws2Slice = createSlice({
  name: 'ws2Slice',
  initialState,
  reducers: {
    connectAndSend(_state, _action: PayloadAction<WS2ConnectAndSendPayload>) {
      // Handled by middleware
    },
    stopTask(_state, _action: PayloadAction<{ conversationId: string }>) {
      // Handled by middleware
    },
    connected(state, action: PayloadAction<{ conversationId: string }>) {
      const newConnectionState: WS2ConnectionStatus = {
        connected: true,
        closeCode: null,
        closeReason: null,
        closeError: false,
      }

      state.connections[action.payload.conversationId] = newConnectionState
    },
    disconnected(state, action: PayloadAction<{ conversationId: string; code: number; reason: string }>) {
      const newConnectionState: WS2ConnectionStatus = {
        connected: false,
        closeCode: action.payload.code,
        closeReason: action.payload.reason,
        closeError: false,
      }

      const connectionState = state.connections[action.payload.conversationId]

      if (connectionState) {
        state.connections[action.payload.conversationId] = newConnectionState
      }
    },
    setCloseError(state, action: PayloadAction<{ conversationId: string; hasError: boolean }>) {
      const connectionState = state.connections[action.payload.conversationId]
      if (connectionState) {
        connectionState.closeError = action.payload.hasError
      }
    },
    manualDisconnect(_state, _action: PayloadAction<{ conversationId: string }>) {
      // Handled by middleware
    },
  },
})

export const WS2Actions = ws2Slice.actions
export default ws2Slice.reducer

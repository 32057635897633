import { ChatV2MessageType } from '../../store/chat-v2.slice'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { SignalSlashIcon } from '@heroicons/react/24/outline'
import { useOnlineStatus } from '@/context/online-status-context'
import { selectConversationIsLoading, selectMessageFromId } from '@/chat-common/store/chat-v2.selectors'
import { ChatV2Message } from '@/chat-common/schemas/chat-v2.schemas'

type ChatClientErrorMessageBubbleProps = {
  chatId: string
  messageId: string
  openFeedbackDialog: (message: ChatV2Message, positive: boolean) => void
}

export default function ChatClientErrorMessageBubble(props: ChatClientErrorMessageBubbleProps) {
  const { chatId, messageId } = props
  const isOnline = useOnlineStatus()

  // Redux State Selectors
  const message = useAppSelector((state: RootState) =>
    selectMessageFromId(state, {
      messageId,
      chatId,
    })
  )
  const { metadata, text } = message

  const conversationIsLoading = useAppSelector((state: RootState) => selectConversationIsLoading(state, { chatId }))

  // Trim the text
  const trimmedText = text.trim()

  return (
    <>
      <div className={`p-2 rounded-lg text-base whitespace-pre-wrap bg-red-100`}>
        <div className={`font-bold text-base mb-1`}>Error</div>
        <div className={`text-sm leading-6 mb-1`}>
          {trimmedText == '' && !conversationIsLoading && (
            <div className={`text-brand-neutral-600`}>
              Response may still be generating. If you refreshed while the response was being generated, it may not yet be complete. Try refreshing again in a
              few moments.
            </div>
          )}
          {trimmedText}
        </div>
        {metadata.message_type === ChatV2MessageType.client_error && !isOnline && (
          <div className={'py-2 flex items-center gap-x-1 text-sm font-normal text-red-700'}>
            <div className={'w-5 h-5'}>
              <SignalSlashIcon />
            </div>
            <div className={''}>No connection</div>
          </div>
        )}
      </div>
      <div className={`h-2`} />
    </>
  )
}

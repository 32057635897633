import { kSettingsBasePath } from '@/constants/constants-api-paths'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { SettingsGetResponse, SettingsGetResponseSchema } from '@/settings/schemas/settings-schema.ts'

import { kErrorCodeAuthError } from '@/constants/constants-error-codes'

import { getAuth } from 'firebase/auth'

import * as Sentry from '@sentry/browser'
import { nanoid } from 'nanoid'

/**
 * Fetch User Settings via GET Request
 * @returns
 */
export default async function getUserSettings(): Promise<SettingsGetResponse> {
  const sentry_transaction_id = nanoid()

  try {
    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw Error(kErrorCodeAuthError)

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    const apiUrl = kPaxtonAppApiBaseUrl() + kSettingsBasePath
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (!response.ok) {
      console.log('response was not ok')
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        console.log(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data)}`)
        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data)}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            response: JSON.stringify(response),
            data: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
      throw new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data)}`)
    }

    // Validate the response data using Zod
    const parsedData = SettingsGetResponseSchema.parse(data)
    return parsedData
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    throw e
  }
}

import { kAgentRouterApiBasePath } from '@/constants/constants-api-paths'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { ApiError } from '@/util/errors/api-error'

type PatchRenameAgentConversationTitleParams = {
  conversationId: string
  title: string
}
export default async function patchRenameAgentConversationTitle(params: PatchRenameAgentConversationTitleParams): Promise<void> {
  // Create transaction id
  const sentry_transaction_id = nanoid()

  try {
    const token = await getAuth().currentUser?.getIdToken(true)
    if (!token) throw Error(kErrorCodeAuthError)

    const { conversationId } = params
    const body = JSON.stringify({ title: params.title })
    // Construct request
    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'PATCH',
      body: body,
    }

    const apiUrl = `${kPaxtonAppApiBaseUrl()}${kAgentRouterApiBasePath}/conversations/${conversationId}/title/rename`
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    // Handle non-OK responses
    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${data.detail}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            responseData: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })

      throw new ApiError(response.status, `Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`)
    }
  } catch (e) {
    // Skip api errors already captured above
    if (!(e instanceof ApiError)) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(e, {
          extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
        })
      })
    }
    throw e
  }
}

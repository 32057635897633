import fetchStripeCustomerPortal from '@/chat-common/fetch/fetch-customer-portal'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { AuthContext } from '@/context/auth-context'
import { SubscriptionStatus, SubscriptionType } from '@/firebase/auth/auth-jwt-schema'
import { setStripePortalDialogVisible } from '@/store/slices/ui-state.slice'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { ExternalLinkIcon } from '@radix-ui/react-icons'
import { useContext, useEffect, useState } from 'react'
import createStripeCustomer from '@/chat-common/fetch/fetch-create-customer'
import { Cross2Icon } from '@radix-ui/react-icons' // Import the icon
import { useFetchCurrentPriceQuery } from './store/subscription.slice'
import { isPaxtonBrand } from '@/util/enterprise'

/**
 * The StripePortalDialog handles fetching and displaying of the Stripe portal Link.
 * This is to decouple the logic from the buttons that may activate this UI.
 */
export default function StripePortalDialog() {
  // Store - whether dialog is visible
  const dispatch = useAppDispatch()
  const uiState = useAppSelector((state: RootState) => state.uiState)
  const subscriptionState = useAppSelector((state: RootState) => state.subscriptionState)
  const formattedPrice = subscriptionState.currentSubscriptionFormattedPrice
  const currentSubscriptionInterval = subscriptionState.currentSubscriptionInterval
  const visible = uiState.stripePortalDialogVisible

  // Local state
  const { user, userAccountData } = useContext(AuthContext)
  const [isNonSubscribedOrgAdmin, setIsNonSubscribedOrgAdmin] = useState(false)
  const [portalUrl, setPortalUrl] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const isLoggedIn = user && !user.isAnonymous
  const hasSubscriptionPrice = isLoggedIn && userAccountData?.validSubscription && isPaxtonBrand() && !userAccountData?.isOrgAccount

  const { isLoading: isLoadingCurrentPrice, refetch: refetchCurrentPrice } = useFetchCurrentPriceQuery(undefined, {
    skip: !hasSubscriptionPrice,
  })

  useEffect(() => {
    if (hasSubscriptionPrice) {
      refetchCurrentPrice()
    }
  }, [userAccountData, refetchCurrentPrice, hasSubscriptionPrice])

  // Get the portal url
  async function requestPortalUrl() {
    try {
      setError(null)
      setLoading(true)
      let portalUrl

      if (userAccountData?.subscriptionStatus == SubscriptionStatus.PRE_TRIAL) {
        portalUrl = await createStripeCustomer()
      } else {
        const portalResponse = await fetchStripeCustomerPortal()
        console.log('portalResponse', portalResponse)
        const { url, isNonSubscribedOrg } = await portalResponse
        portalUrl = url

        if (isNonSubscribedOrg) {
          setIsNonSubscribedOrgAdmin(isNonSubscribedOrg)
        }
      }

      if (portalUrl != undefined && (portalUrl as any).detail == 'Customer already exists') {
        const portalResponse = await fetchStripeCustomerPortal()
        const { url } = await portalResponse
        portalUrl = url
      }

      if (portalUrl == undefined || portalUrl == null) {
        console.error('We are still completing your registration. Please refresh the page and try again shortly.')
        setError('We are still completing your registration. Please refresh the page and try again shortly.')
      } else {
        setPortalUrl(portalUrl)
      }
    } catch (error) {
      console.error(error)
      setError('There was an error loading your account portal link. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  // Fetch the portal url on visible
  useEffect(() => {
    if (visible) {
      requestPortalUrl()
    }
  }, [visible])

  // Do not show if not visible
  if (!visible) return null

  const subscriptionType = userAccountData?.subscriptionType ?? SubscriptionType.STANDARD
  const orgData = userAccountData?.orgData

  if (isNonSubscribedOrgAdmin) {
    console.log('isNonSubscribedOrgAdmin', isNonSubscribedOrgAdmin)
    return (
      <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
        {/* Dimmed background overlay */}
        <div className="absolute inset-0 bg-black opacity-50" onClick={() => dispatch(setStripePortalDialogVisible(false))}></div>

        {/* Dialog Content */}
        <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px] max-w-md">
          <button
            className="absolute top-2 right-2 text-brand-neutral-900 hover:text-brand-neutral-700"
            onClick={() => dispatch(setStripePortalDialogVisible(false))}
          >
            <Cross2Icon />
          </button>
          <h2 className="mb-2 text-lg font-semibold">Manage your subscription</h2>

          <p className="mb-2 text-sm text-brand-neutral-600">
            We are updating the billing portal. You will soon gain access to this page. Please contact your organization administrator if you have any
            questions.
          </p>

          {error && <div className={'mt-4 text-center text-red-700 text-sm'}>{error}</div>}
        </div>
      </div>
    )
  }

  if (isLoadingCurrentPrice) {
    return (
      <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
        {/* Dimmed background overlay */}
        <div className="absolute inset-0 bg-black opacity-50" onClick={() => dispatch(setStripePortalDialogVisible(false))}></div>

        {/* Dialog Content */}
        <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px] max-w-md">
          <CircularProgressContinuousSized size={20} thickness={7} />
        </div>
      </div>
    )
  }

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={() => dispatch(setStripePortalDialogVisible(false))}></div>

      {/* Dialog Content */}
      <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px] max-w-md">
        <button
          className="absolute top-2 right-2 text-brand-neutral-900 hover:text-brand-neutral-700"
          onClick={() => dispatch(setStripePortalDialogVisible(false))}
        >
          <Cross2Icon />
        </button>
        <h2 className="mb-2 text-lg font-semibold">Manage your subscription</h2>
        <div className="text-sm font-bold text-brand-neutral-900">
          {`${subscriptionType === SubscriptionType.STANDARD && 'Standard'}`} {`${currentSubscriptionInterval == 'month' ? 'Monthly' : 'Annual'}`} Subscription
          Price
        </div>

        <p className="mb-2 text-sm text-brand-neutral-600">
          {orgData?.id ? `${formattedPrice} / user / month` : `${formattedPrice} / ${currentSubscriptionInterval}`}
          {`${subscriptionType === SubscriptionType.EDUCATION ? '(student plan) ' : ''} `}
          {` `}
        </p>

        <p className=" text-sm whitespace-pre-wrap">Continue to our secure payment portal to manage your subscription.</p>
        <p className=" text-sm whitespace-pre-wrap italic mt-4">
          Please note: the above price does not reflect promotions, discounts, or custom enterprise pricing. Continue to our payment portal to see your active
          subscription.
        </p>
        <div className={'h-4'} />
        <div className={'flex gap-x-4 justify-end items-center'}>
          {/* TODO: Disabled styled loading button */}
          {loading && <CircularProgressContinuousSized size={20} thickness={7} />}

          {/* Loading complete, real link */}
          {!loading && portalUrl && (
            <a
              className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
              onClick={() => dispatch(setStripePortalDialogVisible(false))}
              target="_blank"
              href={portalUrl}
            >
              <div className={'flex items-center gap-x-2'}>
                <div>Open Payment Portal</div>
                <ExternalLinkIcon />
              </div>
            </a>
          )}
        </div>
        {error && <div className={'mt-4 text-center text-red-700 text-sm'}>{error}</div>}
      </div>
    </div>
  )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AgentEventType, ClientAgentEventRead } from './schemas'

export type AgentConversationEventsRecord = Partial<{ [eventId: string]: ClientAgentEventRead }>

export type AgentEventsState = {
  events: Partial<{ [conversationId: string]: AgentConversationEventsRecord }>
}

const initialState: AgentEventsState = {
  events: {},
}

/**
 * Agent Events Slice
 * Holds agent events representing communication between the user and the agent.
 */
export const agentEventsSlice = createSlice({
  name: 'agentEventsState',
  initialState,
  reducers: {
    // ============== Slice Actions ============== >
    nullifyData: () => initialState,

    // Upsert a list of agent events
    upsertEvents: (state, action: PayloadAction<{ conversationId: string; events: ClientAgentEventRead[] }>) => {
      const { conversationId, events } = action.payload

      // Create a Record of new events so they can all be added at once
      const newEventsRecord = events.reduce((acc, event) => {
        acc[event.id] = event
        return acc
      }, {} as AgentConversationEventsRecord)

      const conversation = state.events[conversationId]
      if (!conversation) {
        state.events[conversationId] = {}
      }

      // Add the new events to the conversation
      state.events[conversationId] = {
        ...state.events[conversationId],
        ...newEventsRecord,
      }
    },

    // Appent Event Chunk
    appendEventChunk: (state, action: PayloadAction<{ conversationId: string; eventId: string; chunk: string }>) => {
      const { conversationId, eventId, chunk } = action.payload

      const event = state.events[conversationId]?.[eventId]
      if (!event) return

      // Append the chunk to the event
      event.value += chunk
    },

    // Update Artifact Version ID
    updateArtifactVersionId: (state, action: PayloadAction<{ conversationId: string; eventId: string; artifactVersionId: string | null }>) => {
      const { conversationId, eventId, artifactVersionId } = action.payload

      const event = state.events[conversationId]?.[eventId]
      if (!event) {
        console.error(`Event not found: conversationId=${conversationId}, eventId=${eventId}`)
        return
      }

      // Update the artifact_version_id of the event
      event.artifact_version_id = artifactVersionId
    },

    // Insert CLIENT_ERROR_EVENT
    insertClientErrorEvent: (state, action: PayloadAction<{ conversationId: string; value: string }>) => {
      const { conversationId, value } = action.payload

      const conversation = state.events[conversationId]
      if (!conversation) {
        state.events[conversationId] = {}
      }

      const newEvent: ClientAgentEventRead = {
        id: crypto.randomUUID(),
        turn_id: crypto.randomUUID(),
        type: AgentEventType.CLIENT_ERROR_EVENT,
        value: value,
        created_at: Date.now(),
        artifact_version_id: null,
      }

      state.events[conversationId] = {
        ...state.events[conversationId],
        [newEvent.id]: newEvent,
      }
    },
  },
})

// Actions
export const AgentEventsActions = agentEventsSlice.actions

export default agentEventsSlice.reducer

import { isHaikuHost, brandedAIFriendlyName } from '@/util/enterprise'

const isHaiku = isHaikuHost()
// User File Upload Storage Ref
export const kGcsUserFileUploadsBucketRef = isHaiku ? import.meta.env.VITE_GCS_HAIKU_USER_UPLOAD_BUCKET :import.meta.env.VITE_GCS_USER_UPLOAD_BUCKET
export const kGcsCaseLawSourceBucketRef = import.meta.env.VITE_GCS_CASE_LAW_SOURCE_BUCKET
export const kGcsSecHtmlReferenceBucket = import.meta.env.VITE_GCS_SEC_HTML_REFERENCE_BUCKET
export const kGcsUserMarkdownFilesBucketRef = isHaiku ? import.meta.env.VITE_GCS_HAIKU_USER_MARKDOWN_FILES_BUCKET : import.meta.env.VITE_GCS_USER_MARKDOWN_FILES_BUCKET
export const kGcsUserFilesLlmOutputBucketRef = isHaiku ? import.meta.env.VITE_GCS_HAIKU_USER_FILE_LLM_OUTPUT_BUCKET : import.meta.env.VITE_GCS_USER_FILE_LLM_OUTPUT_BUCKET
export const kGcsArtifactsBucketRef = isHaiku ? import.meta.env.VITE_GCS_HAIKU_ARTIFACTS_BUCKET : import.meta.env.VITE_GCS_ARTIFACTS_BUCKET

// General hidden file for permanent folders
export const kHiddenFileForFolderName = '.keep'

// Organization hidden folder (never show in file list, used for org-access files (like org logo))
export const kGcsOrgHiddenFolder = '.org-hidden-xORv2GkKGk'

// Accepted Files List
export const kAcceptedFilesList: { [type: string]: string[] } = {
  'application/pdf': ['.pdf'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
  'text/plain': ['.txt'],
  'text/csv': ['.csv'],
  'application/json': ['.json'],
  'text/html': ['.html'],
  'application/xml': ['.xml'],
  'text/markdown': ['.md', '.markdown'],
}

// Accepted Logo Image Extensions List
export const kAcceptedImageFormats: { [type: string]: string[] } = {
  // Image MIME types
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
}

export const kUploadDisclaimer = `Your documents and data are not used to train ${brandedAIFriendlyName}.`
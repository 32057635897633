import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ArtifactVersionRead } from '../schemas'

export type ArtifactsState = {
  artifactsVersions: Partial<{ [artifactVersionId: string]: ArtifactVersionRead }>
}

const initialState: ArtifactsState = {
  artifactsVersions: {},
}

/**
 * Artifacts Slice
 * Holds artifact versions to be displayed to the user.
 */
export const artifactsSlice = createSlice({
  name: 'artifactsState',
  initialState,
  reducers: {
    // ============== Listener Actions ============== >
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    startArtifactVersionStatusPoll: (_state, _action: PayloadAction<{ artifactVersionId: string }>) => {
      // Handled by the listener middleware
    },

    // ============== Slice Actions ============== >
    nullifyData: () => initialState,

    // Upsert an artifact version
    upsertArtifactVersion: (state, action: PayloadAction<ArtifactVersionRead>) => {
      state.artifactsVersions[action.payload.id] = action.payload
    },

    // Upsert a list of artifact versions
    upsertArtifactVersions: (state, action: PayloadAction<ArtifactVersionRead[]>) => {
      const artifactVersionRecords = action.payload.reduce((acc, artifactVersion) => {
        acc[artifactVersion.id] = artifactVersion
        return acc
      }, {} as Record<string, ArtifactVersionRead>)

      state.artifactsVersions = {
        ...state.artifactsVersions,
        ...artifactVersionRecords,
      }
    },
  },
})

// Actions
export const ArtifactsActions = artifactsSlice.actions

export default artifactsSlice.reducer

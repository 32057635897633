import { useState, useEffect, useCallback } from 'react'
import { convertArtifactMarkdownToHtml } from '../util/artifact-markdown-to-html'
import { getArtifactMarkdownFromGCS } from '../fetch/get-artifact-markdown-gcs'

interface UseArtifactMarkdownParams {
  filePath: string | null
}

interface UseArtifactMarkdownReturn {
  htmlString: string
  isLoading: boolean
  hasError: boolean
  retry: () => void
}

/**
 * useArtifactMarkdown
 *
 * Custom hook to fetch and process markdown content for an artifact.
 *
 * @param filePath - The path to the markdown file.
 * @returns An object containing the processed HTML string, loading state, error flag, and a retry function.
 */
const useArtifactMarkdown = (props: UseArtifactMarkdownParams): UseArtifactMarkdownReturn => {
  const { filePath } = props
  const [htmlString, setHtmlString] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)

  const fetchAndProcess = useCallback(async () => {
    if (!filePath) {
      setHasError(true)
      console.error('File path is required for fetching artifact.')
      return
    }

    setIsLoading(true)
    setHasError(false)
    setHtmlString('')

    try {
      // Fetch markdown from GCS
      const markdown = await getArtifactMarkdownFromGCS(filePath)

      // Convert markdown to HTML
      const html = await convertArtifactMarkdownToHtml(markdown)

      // Update local state with HTML
      setHtmlString(html)
    } catch (err) {
      console.error('Error in useArtifactMarkdown:', err)
      setHasError(true)
    } finally {
      setIsLoading(false)
    }
  }, [filePath])

  // Initial fetch and process on mount or when filePath changes
  useEffect(() => {
    fetchAndProcess()
  }, [fetchAndProcess])

  return { htmlString, isLoading, hasError, retry: fetchAndProcess }
}

export default useArtifactMarkdown

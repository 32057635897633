import FileActionMenu from '@/chat-common/components/form-source-controls/assistant/file-action-menu'
import { selectConversationSelectedFiles } from '@/chat-common/store/chat-v2.selectors'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { RootState } from '@/store/store'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { ArrowUpIcon, Cross2Icon } from '@radix-ui/react-icons'
import { useEffect, useRef, useState } from 'react'
import { selectAgentConversationFormSubmitBlockedReason } from '../store/selectors'
import { AgentConversationFormActions } from '../store/slice'
import { AgentFocusMode } from '../store/schemas'

type AgentChatTextAreaProps = {
  conversationId: string
}

/**
 * Agent Chat Text Area
 * The text area for the agent chat window.
 * Isolated to avoid re-renders from the chat window.
 * @param props
 * @returns
 */
export default function AgentChatTextArea(props: AgentChatTextAreaProps) {
  const { conversationId } = props
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const dispatch = useAppDispatch()

  // Conversation values
  const formData = useAppSelector((state: RootState) => state.agentConversationFormState.forms[conversationId])
  const input_text = formData?.requestParams.input_text ?? ''
  const selectedFilePaths = useAppSelector((state: RootState) => selectConversationSelectedFiles(state, { conversationId }))
  const submitBlockedReason = useAppSelector((state: RootState) =>
    selectAgentConversationFormSubmitBlockedReason(state, {
      conversationId,
      keys: selectedFilePaths,
    })
  )

  // Local state to track if we're waiting for submission confirmation
  const [isWaitingForSubmissionConfirmation, setIsWaitingForSubmissionConfirmation] = useState(false)

  const submitBlocked = !!submitBlockedReason

  const isSubmitting = !!formData?.queryIsSubmitting

  // Websocket connection for this conversation
  const ws2Connection = useAppSelector((state: RootState) => state.ws2State.connections[conversationId])
  const isConnected = ws2Connection?.connected ?? false

  // Handle Send Message on the Chat Window Form
  const handleSendMessage = () => {
    console.log('Handling send message')
    if (submitBlocked) return
    if (!formData) return
    dispatch(AgentConversationFormActions.submitForm({ conversationId }))
  }

  // Create a new form if it doesn't exist
  useEffect(() => {
    if (!formData) {
      dispatch(AgentConversationFormActions.createForm({ conversationId, focus_mode: AgentFocusMode.INTELLIGENT }))
    }
  }, [formData, dispatch, conversationId])

  // Update text area height when input_text changes
  useEffect(() => {
    if (textAreaRef.current) {
      const target = textAreaRef.current
      target.style.height = 'auto'
      target.style.height = `${target.scrollHeight}px`
    }
  }, [input_text])

  // Set the waiting flag in local state when query is submitting
  // (happens once websocket is connected and query is being sent to the server)
  useEffect(() => {
    if (isSubmitting) {
      setIsWaitingForSubmissionConfirmation(true)
    }
  }, [isSubmitting])

  // Re-focus on text area after submission
  useEffect(() => {
    if (
      !isSubmitting && // Submission has finished
      isWaitingForSubmissionConfirmation && // We were waiting for confirmation
      textAreaRef.current // Text area element exists
    ) {
      textAreaRef.current.focus()
      setIsWaitingForSubmissionConfirmation(false) // Reset the flag
    }
  }, [isSubmitting, isWaitingForSubmissionConfirmation])

  return (
    <div>
      <div
        className={`z-10 relative w-full border border-brand-neutral-400 rounded-lg ${
          isSubmitting ? 'bg-brand-neutral-100 text-gray-400' : 'bg-brand-neutral-50'
        }`}
      >
        <form>
          <div className={'grid grid-cols-[auto_30px_48px] gap-x-2 items-end'}>
            <div className={'pl-3 py-1 self-center'}>
              <textarea
                ref={textAreaRef}
                disabled={isSubmitting}
                className={`p-0 m-0 pt-1 w-full resize-none self-center border-none focus:ring-0 bg-transparent border-0`}
                value={input_text}
                onChange={(e) => {
                  dispatch(AgentConversationFormActions.setInputText({ conversationId, text: e.target.value }))
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault() // Prevent default to avoid line break in textarea
                    handleSendMessage()
                  }
                }}
                placeholder={'Enter a prompt here'}
                rows={1}
                style={{ lineHeight: '1.5', maxHeight: '12rem' }}
              />
            </div>

            <div className={'mb-[10px]'}>
              <FileActionMenu disabled={isConnected} conversationId={conversationId} />
            </div>

            {/* Form initializing */}
            {!formData && (
              <div className={'w-10 h-10 grid place-items-center rounded-md bg-brand-500 bg-opacity-50'}>
                <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
              </div>
            )}

            {/* Not connected: send button */}
            {!isConnected && (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  handleSendMessage()
                }}
                disabled={submitBlocked}
                className={`w-10 h-10 mb-2 mt-2 bg-brand-500 text-white rounded-md grid place-items-center ${submitBlocked ? 'opacity-50' : 'opacity-100'}`}
              >
                <ArrowUpIcon width="20" height="20" />
              </button>
            )}

            {/* Connected: stop button */}
            {isConnected && (
              <button
                className={'w-10 h-10 mb-2 mt-2 bg-red-700 text-white rounded-md grid place-items-center ring-0 border-none outline-none focus:outline-none'}
                onClick={(e) => {
                  e.preventDefault()
                  dispatch(AgentConversationFormActions.sendStop({ conversationId }))
                }}
              >
                <Cross2Icon width="20" height="20" />
              </button>
            )}
          </div>
        </form>
      </div>

      {/* Below form */}
      <div className={'px-2'}>
        <p className={'text-xs text-brand-neutral-600 text-left mt-1 hidden sm:block'}>new line: shift + enter</p>
      </div>
    </div>
  )
}

import { z } from 'zod'
import { ChatV2Feature } from '../store/chat-v2.slice'
import * as Sentry from '@sentry/browser'
import { parseTimestamp } from '@/util/validators'

/**
 * Chat List Sidebar Item
 * Represents a single item in the chat list sidebar
 */
export interface ChatListSidebarItem {
  id: string
  title: string
  feature: ChatV2Feature
  modified_on: number
}

// Matches backend models:
// - ConversationHeader
// - CreateConversationResponse
export const ConversationHeaderSchema = z.object({
  id: z.string(),
  title: z.string().transform((val) => {
    // Transform empty strings to default
    return val.trim() === '' ? 'Untitled' : val
  }),
  feature: z.nativeEnum(ChatV2Feature),
  modified_on: z.preprocess((val, ctx) => parseTimestamp(val, ctx), z.number()),
})
export type ConversationHeader = z.infer<typeof ConversationHeaderSchema>

// Based on the backend GetUserConversationsResponse model (plural!)
export const GetUserConversationsResponseSchema = z.object({
  // preprocess filtering - only includes valid ConversationHeader objects
  // Allows us to not fail the entire schema if one conversation is invalid
  // Also allows us to filter out invalid conversations on ingestion
  // REASON: Frontend may have more strict validation than backend
  conversations: z.preprocess((val) => {
    if (!Array.isArray(val)) return []

    // Collect failures
    const zodIssues: z.ZodIssue[] = []

    // Collect valid conversations
    const validConversations: ConversationHeader[] = []

    // Filter out invalid conversations
    val.forEach((conversation) => {
      const result = ConversationHeaderSchema.safeParse(conversation)
      if (!result.success) {
        zodIssues.push(...result.error.errors)
        return
      }
      validConversations.push(result.data)
    })

    // Capture errors
    if (zodIssues.length > 0) {
      // console.log('Invalid conversations:', zodIssues)
      Sentry.captureException(new Error(`Received one or more invalid conversations`), {
        extra: {
          errors: JSON.stringify(zodIssues),
        },
      })
    }

    // Return valid conversations
    return validConversations
  }, z.array(ConversationHeaderSchema)),
})
export type GetUserConversationsResponse = z.infer<typeof GetUserConversationsResponseSchema>


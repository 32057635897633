import { RootState } from '@/store/store'
import { AgentEventType, ClientAgentEventRead } from './schemas'
import { createSelector, createSelectorCreator, lruMemoize } from '@reduxjs/toolkit'
import { AgentConversationEventsRecord } from './slice'
import { selectConversationWebSocketConnected } from '@/websocket_v2/ws2.selectors'

/**
 * Conversation Event List Equality
 * Purpose: Avoids re-rendering every time the Record of Agent Events is updated
 *          by comparing the previous and next lists of event ids.
 *
 * What triggers a re-render
 * - If the length of the previous and next lists are different (event added or removed)
 */
const conversationEventListEquality = (prevState: AgentConversationEventsRecord, nextState: AgentConversationEventsRecord): boolean => {
  // If either is null, they are not equal
  if (!prevState || !nextState) return false

  // Convert each to a list of event id keys
  const prevKeys = Object.keys(prevState)
  const nextKeys = Object.keys(nextState)

  // If the lengths are different, they are not equal
  if (prevKeys.length !== nextKeys.length) return false

  return true
}

/**
 * Selector Creator
 * A custom selector creator that uses the custom equality operator
 */
const createConversationEventListSelector = createSelectorCreator(lruMemoize, conversationEventListEquality)

/**
 * Select: Agent Event Exists
 *
 * Returns true if the agent event with the given id exists in the store.
 */
export const selectAgentEventExists = (state: RootState, props: { conversationId: string; eventId: string | undefined | null }): boolean => {
  const { conversationId, eventId } = props
  if (!conversationId || !eventId) return false

  return Boolean(state.agentEventsState.events[conversationId]?.[eventId])
}

/**
 * Select: Agent Event by ID
 *
 * Returns the agent event with the given id, or null if it does not exist.
 */
export const selectAgentEventById = (state: RootState, props: { conversationId: string; eventId: string }): ClientAgentEventRead | null => {
  const { conversationId, eventId } = props

  return state.agentEventsState.events[conversationId]?.[eventId] ?? null
}

/**
 * Select: List Of Event IDs
 *
 * @returns {string[]} List of event IDs in ascending order of timestamp
 */
export const selectConversationEventIds = createConversationEventListSelector(
  (state: RootState, props: { conversationId: string }) => state.agentEventsState.events[props.conversationId],
  (eventsRecord) => {
    if (!eventsRecord) return []

    // Convert the Record of events to an array (filter required for TS)
    const events = Object.values(eventsRecord).filter(isDefinedAgentEventWithId)

    // Sort the events by timestamp in ascending order (newest at bottom)
    events.sort((a, b) => {
      if (a && b) {
        return a.created_at - b.created_at
      }
      return 0
    })

    // Return the list of event ids
    return events?.map((event) => event.id) ?? []
  },
  {
    memoizeOptions: {
      // Commenting out for now as it was causing issues with rendering the events for a conversation
      // equalityCheck: conversationEventListEquality,
    },
  }
)

/**
 * Select: Most Recent Event ID
 *
 * @returns {string} returns the most recent event id
 */
export const selectMostRecentConversationEventId = createConversationEventListSelector(
  (state: RootState, props: { conversationId: string }) => state.agentEventsState.events[props.conversationId],
  (eventsRecord) => {
    if (!eventsRecord) return null

    // Convert the Record of events to an array (filter required for TS)
    const events = Object.values(eventsRecord).filter((event) => !!event)

    // Sort the events by timestamp in ascending order (newest at bottom)
    events.sort((a, b) => {
      if (a && b) {
        return a.created_at - b.created_at
      }
      return 0
    })

    // Return the most recent event id
    return events[events.length - 1]?.id ?? null
  },
  {
    memoizeOptions: {
      equalityCheck: conversationEventListEquality,
    },
  }
)

/**
 * Selector: All Events for a Conversation
 *
 * Retrieves all events for a given conversation.
 */
export const selectEventsForConversation = (state: RootState, props: { conversationId: string }): Partial<Record<string, ClientAgentEventRead>> => {
  return state.agentEventsState.events[props.conversationId] ?? {}
}

/**
 * Select: Show Conversation Loading Bubble
 *
 * Determines whether to show a loading bubble for a conversation.
 * Returns true if:
 * - The conversation has an open WebSocket connection
 * - The most recent event is
 *   - a user query, or
 *   - an agent response with an empty value
 */
export const selectShowEventLoadingBubble = createSelector(
  [
    // 1) Is the WebSocket connected?
    (state: RootState, props: { conversationId: string }) => selectConversationWebSocketConnected(state, { conversationId: props.conversationId }),

    // 2) The id of the most recent event in the conversation
    (state: RootState, props: { conversationId: string }) => selectMostRecentConversationEventId(state, { conversationId: props.conversationId }),

    // 3) The events record for the conversation
    (state: RootState, props: { conversationId: string }) => selectEventsForConversation(state, { conversationId: props.conversationId }),
  ],
  (isConnected, mostRecentEventId, eventsRecord) => {
    // If no connection or no events
    if (!isConnected || !mostRecentEventId) return false

    // Get the most recent event
    const event = eventsRecord[mostRecentEventId]
    if (!event) return false

    // Most recent event is a user query
    if (event.type === AgentEventType.USER_QUERY) return true

    // Most recent event is an agent response with empty value
    if (event.type === AgentEventType.AGENT_RESPONSE && event.value.trim() === '') {
      return true
    }

    return false
  }
)

function isDefinedAgentEventWithId(event: Partial<ClientAgentEventRead> | null | undefined): event is ClientAgentEventRead {
  return !!event && event.id != null
}

import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectSelectedFilesAreProcessingOrPending } from '@/store/selectors/file-upload-tasks-reducers.selector'
import { selectConversationSelectedFilesContentExpanded } from '@/chat-common/store/chat-v2-ux.selectors'
import { selectFilesIsOverTokenLimit } from '@/chat-common/selected_files/store/selectors'
import SelectedFilesControl from '@/chat-common/selected_files/components/selected-files-control'
import ModeSelector from './mode-selector'
import SelectedFilesContent from '@/chat-common/selected_files/components/selected-files-content'

type ChatControlsAssistantProps = {
  conversationId: string
}

export default function ChatControlsAssistant({ conversationId }: ChatControlsAssistantProps) {
  const isExpanded = useAppSelector((state: RootState) => selectConversationSelectedFilesContentExpanded(state, { chatId: conversationId }))

  const selectedFilesExceedsLimit = useAppSelector((state: RootState) =>
    selectFilesIsOverTokenLimit(state, {
      chatId: conversationId,
    })
  )

  const selectedFilesAreProcessingOrPending = useAppSelector((state: RootState) => selectSelectedFilesAreProcessingOrPending(state, { chatId: conversationId }))

  return (
    <div>
      <div className={'flex flex-wrap items-end gap-x-2 gap-y-2 justify-between mb-2'}>
        <ModeSelector conversationId={conversationId} />
        <SelectedFilesControl chatId={conversationId} isOverLimit={selectedFilesExceedsLimit} someProcessingOrPending={selectedFilesAreProcessingOrPending} />
      </div>
      {isExpanded && <SelectedFilesContent chatId={conversationId} isOverLimit={selectedFilesExceedsLimit} />}
    </div>
  )
}

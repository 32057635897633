import { store } from '@/store/store'
import { selectConversationSelectedFiles, selectConversationSubmitBlocked } from '../store/chat-v2.selectors'
import { ChatV2Actions, ChatV2Feature, chatV2InsertUserQueryMessage, ChatV2MessageType, chatV2SetConversationCurrentTextInput } from '../store/chat-v2.slice'
import { nanoid } from 'nanoid'
import runChatV2WebsocketQuery, { ChatV2WebsocketQueryArgs } from '../chat-v2-websocket'
import { ChatV2Message } from '../schemas/chat-v2.schemas'
import { kNewConversationTitle } from '../conversation_list/constants'

/**
 * ChatV2 Handle Send Message
 * Handles sending the message when we have an existing conversation id
 *
 * IF: we have no conversation id (pending or real from server) in the URL param, we are creating a new conversation
 * 1. Create a pending conversation including the user's message
 * 2. Submit the query to the server with the appropriate args so the dispatcher can apply the appropriate handoff data
 * 3. The ChatV2Page will detect the conversationId from the server (and fromPendingId provided) and navigate
 *
 * @param e
 * @returns
 */
export function chatV2HandleSendMessage(props: { chatId: string; onComplete: () => void; messageOverride?: string }) {
  const { chatId, onComplete, messageOverride } = props
  // Store Data
  const state = store.getState()

  const conversation = state.chatV2State.conversations[chatId]
  if (conversation == null) {
    throw new Error('Cannot send message without a conversation.')
  }
  const feature = conversation.feature as ChatV2Feature
  const selectedFilePaths = selectConversationSelectedFiles(state, { conversationId: chatId })
  const submitBlocked = selectConversationSubmitBlocked(state, {
    chatId,
    keys: selectedFilePaths,
  })

  // Return early if there is no text message to submit
  // - Construct the final submission text message
  //    - If there is a message override, use that, otherwise use the current text input
  //        - messageOverride is provided by suggested questions, retry buttons, etc.
  //    - If the message is empty, log an error and return early
  const submitMessage = messageOverride ?? conversation.currentTextInput
  if (submitMessage.trim() === '') {
    console.error('Cannot send an empty message. Submit forms / buttons should not allow reaching here.')
    return
  }

  // Return early if submit is blocked
  if (submitBlocked) {
    console.error('Submit is disabled. Submit forms / buttons should not allow reaching here.')
    return
  }

  // Check if this is a new conversation (no user query messages yet)
  const isNewConversation = conversation.title == kNewConversationTitle

  // Construct the user message object from the form values for the UX
  const userMessage: ChatV2Message = {
    text: submitMessage,
    metadata: {
      message_id: nanoid(),
      feature: feature,
      message_type: ChatV2MessageType.user_query,
      created_at: Date.now(),
      conversation_id: conversation.id,
      is_error: false,
      get_follow_up_questions: false,
      references: {},
    },
  }

  // Create args to submit the query
  const args: ChatV2WebsocketQueryArgs = {
    feature: feature,
    conversation: conversation,
    userMessage,
    anonymousRateLimitedExceededFunction: function (): void {
      console.warn('Need to implement UX handling here for anonymousRateLimitedExceededFunction().')
    },
  }

  // 1. Insert the user's message into the pending conversation immediately for display
  store.dispatch(chatV2InsertUserQueryMessage({ conversationId: conversation.id, message: userMessage }))

  // Run the query on the new message
  runChatV2WebsocketQuery(args)

  // IF: This is a new conversation, generate the title from the first query
  if (isNewConversation) {
    // Stringify the currentSource metadata
    const metadataString = JSON.stringify(conversation.currentSource)
    store.dispatch(ChatV2Actions.generateConversationTitle({ conversationId: conversation.id, query: submitMessage, metadataString, feature }))
  }

  // Clear the form
  store.dispatch(chatV2SetConversationCurrentTextInput({ conversationId: conversation.id, text: '' }))

  // onComplete callback
  onComplete()
}

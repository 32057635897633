import { getAuth } from 'firebase/auth'
import { ArtifactVersionRead } from '../schemas'

/**
 * Constructs the filepath for an artifact version.
 *
 * @param artifactVersion - The artifact version data object.
 * @returns The constructed filepath or null if the user is not authenticated.
 */
export function constructArtifactFilePath(artifactVersion: ArtifactVersionRead): string | null {
  const user = getAuth().currentUser
  if (!user) return null

  const { artifact_id, id: version_id } = artifactVersion
  const userId = user.uid

  return `users/${userId}/${artifact_id}/${version_id}.md`
}

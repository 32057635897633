import { useEffect } from 'react'
import { FilesDriveActions } from '../store/files-drive.slice'
import { useDispatch } from 'react-redux'
import FilesDriveList from '../components/FilesDriveList'
import FileDriveNavigation from '../components/FileDriveNavigation'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectCurrentFolderId } from '../store/files-drive.selectors'

//Todo: be renamed when new file driver is ready
function FilesDrivePage() {
  const { folderId = '' } = useParams<{ folderId: string }>()

  const currentFolderId = useAppSelector((state: RootState) => selectCurrentFolderId(state))

  const dispatch = useDispatch()

  const isRootFolder = folderId === '' || folderId === undefined

  useEffect(() => {
    if (currentFolderId !== folderId || currentFolderId === '') {
      dispatch(FilesDriveActions.setCurrentFolderId({ folderId }))
    }
  }, [dispatch, folderId, currentFolderId])

  useEffect(() => {
    dispatch(FilesDriveActions.fetchItems({ folderId }))
    dispatch(FilesDriveActions.setCurrentFolder({ folderId }))
  }, [dispatch, folderId])

  return (
    <div className={'flex-grow flex flex-col py-4 pr-4 pl-4 lg:pl-0 mt-12 lg:mt-0'}>
      <div className="bg-brand-neutral-50 shadow rounded-lg">
        <div className="px-4 py-5 sm:p-6 text-red-700">New File Driver is under development</div>

        <FileDriveNavigation isRootFolder={isRootFolder} />
        <FilesDriveList currentFolderId={currentFolderId} />
      </div>
    </div>
  )
}

export default FilesDrivePage

import { parseTimestamp } from '@/util/validators'
import { z } from 'zod'

// ArtifactVersionStatusType Enum
export enum ArtifactVersionStatusType {
  GENERATING = 'generating',
  COMPLETED = 'complete',
  ERROR = 'error',
  CANCELLED = 'cancelled',
}

// Based on the BE ArtifactVersionRead model
export const ArtifactVersionReadSchema = z.object({
  id: z.string().uuid(),
  artifact_id: z.string().uuid(),
  status: z.nativeEnum(ArtifactVersionStatusType),
  name: z.string().nullable(),
  summary: z.string().nullable(),
  created_at: z.preprocess((val, ctx) => parseTimestamp(val, ctx), z.number()),
})

export type ArtifactVersionRead = z.infer<typeof ArtifactVersionReadSchema>

// Based on the BE WS2AgentConversationArtifactVersion model
export const ArtifactVersionUpdatedSchema = z.object({
  turn_id: z.string().uuid(),
  status_event_id: z.string().uuid(),
  response_event_id: z.string().uuid(),
  artifact_version: ArtifactVersionReadSchema,
})

export type ArtifactVersionUpdated = z.infer<typeof ArtifactVersionUpdatedSchema>

import * as Sentry from '@sentry/react'
import { FirebaseStorage, FullMetadata, getDownloadURL, uploadBytes } from 'firebase/storage'
import { kGcsUserFileUploadsBucketRef } from '@/constants/constants-gcs'
import { getMetadata, getStorage, ref, getBlob } from 'firebase/storage'
import { saveAs } from 'file-saver'
import { TokenLimitResponse } from '@/chat-common/selected_files/schema'

/**
 * Maximum allowed token count based on the backend's token limit.
 *
 * - Backend token limit: < 80,000 tokens.
 * - Soon we will fetch this from the backend to ensure it's up to date: ENG-4619.
 *
 * - This is the default value used when the token limit is not available.
 * - Intentionally different from the backend value
 */
const DEFAULT_MAX_TOKEN_COUNT = 79999

/**
 * Number of tokens in each chunk used to create a single embedding.
 *
 * - Each embedding is derived from a chunk of 2,000 tokens.
 * - This is the default value used when the token limit is not available.
 * - Intentionally different from the backend value
 */
const DEFAULT_CHUNK_SIZE = 1999

/**
 * Construct Default TokenLimitResponse
 */
export const defaultMaxTokenResponse: TokenLimitResponse = {
  max_tokens: DEFAULT_MAX_TOKEN_COUNT,
  chunk_size: DEFAULT_CHUNK_SIZE,
}

/**
 * File Upload Is Processing
 * Conditions to determine if a file is still processing.
 *
 * @param fileMetadata
 * @returns {boolean} true / false: whether the file is still processing
 */
export function fileUploadIsProcessing(fileMetadata: FullMetadata): boolean {
  // If there was a processing error, return false because the file is not processing
  const isProcessingError: boolean = typeof fileMetadata.customMetadata?.error == 'string' && fileMetadata.customMetadata?.error.trim() != ''
  if (isProcessingError) {
    return false
  }

  // Get processSuccess as a boolean (only true if explicitly 'True' (from python))
  const processSuccess: boolean = fileMetadata.customMetadata?.process_success === 'True'

  // If processSuccess is falsy, return true because the file is still processing
  if (!processSuccess) {
    return true
  }

  // Get the success timestamp (provided in seconds)
  const successTimestampMetadata = fileMetadata.customMetadata?.process_success_timestamp

  // If the successTimestampMetadata is null, return false - legacy processed files do not have this value
  // and should not be considered still processing
  if (!successTimestampMetadata) {
    return false
  }

  // successTimestampMetadata exists, parse it to an integer
  const successTimestampSeconds = parseInt(successTimestampMetadata, 10)
  if (isNaN(successTimestampSeconds)) {
    Sentry.captureException(new Error('Error parsing integer from successTimestampMetadata'), {
      extra: {
        fileMetadata: JSON.stringify(fileMetadata),
      },
    })

    // If there was an error parsing the success timestamp, return false because we cannot determine if the file is processing
    return false
  }

  // successTimestampMetadata is a valid integer, so it may still be processing
  // successIsValid: if the amount of time since successTimestampSeconds occurred exceeds the waiting period
  // === This is the old success metric ===
  const successIsValid = successTimeExceedsWaitingPeriod(successTimestampSeconds)

  // ======== NEW: SUCCESS METRIC FOR NEW SENTENCE ANNOTATION CONDITION EVALUATION BANDAID, BELOW ======== //

  // Check if the file has the new sentence annotations processing metadata
  const hasNewSentenceAnnotationsProcessing = fileMetadata.customMetadata?.has_sentences === 'True'

  // If not, return based on the old success metric
  if (!hasNewSentenceAnnotationsProcessing) {
    // If the success is valid, return false because the file is not processing
    return !successIsValid
  }

  // New success metric
  // Check if the new sentence annotations processing is successful
  const newSentenceAnnotationsSuccess = fileMetadata.customMetadata?.sentence_success === 'True'

  // If newSentenceAnnotationsSuccess is not true, return true because the file is still processing
  if (!newSentenceAnnotationsSuccess) {
    return true
  }

  // sentence_process_timestamp exists, parse it to an integer
  const sentenceSuccessTimestampSeconds = parseInt(fileMetadata.customMetadata?.sentence_process_timestamp ?? '', 10)
  if (isNaN(sentenceSuccessTimestampSeconds)) {
    Sentry.captureException(new Error('Error parsing integer from sentenceSuccessTimestampSeconds'), {
      extra: {
        fileMetadata: JSON.stringify(fileMetadata),
      },
    })

    // If there was an error parsing the success timestamp, return false because we cannot determine if the file is processing
    return false
  }

  // successIsValid: if the amount of time since successTimestampSeconds occurred exceeds the waiting period
  const sentenceSuccessIsValid = successTimeExceedsWaitingPeriod(sentenceSuccessTimestampSeconds)

  // If the success is valid, return false because the file is not processing
  return !sentenceSuccessIsValid
}

/**
 * Success Time Exceeds Waiting Period
 *
 * The timestamp of file upload success is too optimistic. Pinecone database currently reports
 * successful indexing even before the indexing is not actually complete. For large files, this
 * causes the file to report being completed processing before it's actually done processing.
 *
 * THIS IS A BANDAID function to add an arbitrary delay to the file upload time, to see if enough
 * time has passed AFTER the file has marked itself as successfully processed.
 *
 * @param successTimestampSeconds (SECONDS) is the timestamp of when the file metadata reported upload was successful
 * @returns {boolean} whether the time delta is greater than X seconds past the process_success_timestamp
 */
export function successTimeExceedsWaitingPeriod(successTimestampSeconds: number): boolean {
  // Change this variable to adjust the time delay / delta
  const waitingPeriod = 15

  // Now in seconds (to match process_success_timestamp)
  const now = Math.floor(Date.now() / 1000)

  // Get the difference between now and the process_success_timestamp
  const timeDiffSeconds = now - successTimestampSeconds

  // If the time difference is greater than the delta, return true (successfully processed)
  const isExceedingWaitingPeriod = timeDiffSeconds >= waitingPeriod
  // console.log(`Time diff: ${timeDiffSeconds} seconds, waiting period: ${waitingPeriod} seconds, isExceedingWaitingPeriod: ${isExceedingWaitingPeriod}`)

  return isExceedingWaitingPeriod
}

export async function getFileMetadataFromGCS(filePath: string) {
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const fileRef = ref(storage, filePath)
  const metadata = await getMetadata(fileRef)
  return metadata
}

export function sanitizeFileName(fileName: string | undefined): string {
  return (
    fileName
      ?.substring(0, 230)
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '_') // strip punctuation
      .replace(/\s/g, '_') // Replace spaces with underscores
      .replace(/[/\\?%*:|"<>]/g, '') // Remove invalid characters
      .replace(/^_+|_+$/g, '') // strip leading and trailing underscores
      .trim() || 'Download'
  )
}

export async function downloadFile(filePath: string, fileName: string) {
  if (!filePath) {
    return
  }
  if (!fileName) {
    fileName = sanitizeFileName(filePath?.split('/')?.pop())
  }
  const storage = getStorage(undefined, kGcsUserFileUploadsBucketRef)
  const fileRef = ref(storage, filePath)
  const blob = await getBlob(fileRef)
  saveAs(blob, fileName)
  return true
}

export async function copyFile(oldPath: string, newPath: string, storage: FirebaseStorage): Promise<void> {
  try {
    const oldFileRef = ref(storage, oldPath)
    const newFileRef = ref(storage, newPath)

    const url = await getDownloadURL(oldFileRef)

    const response = await fetch(url)
    const blob = await response.blob()

    await uploadBytes(newFileRef, blob)
  } catch (error) {
    console.error('Error copying file:', error)
    throw error
  }
}

/**
 * File name is all text after the last slash in the full path.
 * @param fullPath
 * @returns only the file name from the full path.
 */
export const extractFileNameFromFullPath = (fullPath: string): string => {
  return fullPath.split('/').pop() || ''
}

/**
 * Retrieves the embeddings count for a file if it has been fully processed.
 *
 * @param {string} filePath - The file path.
 * @returns {Promise<number>} - Returns the embeddings count or 0 if invalid or not processed.
 */
export async function getFileEmbeddingsCount(filePath: string): Promise<number> {
  try {
    const metadata = await getFileMetadataFromGCS(filePath)

    const isProcessed = metadata.customMetadata?.process_success === 'True'
    if (!isProcessed) return 0 // If the file isn't fully processed, return 0

    const embeddingsCountStr = metadata.customMetadata?.embeddings_count
    const embeddingsCount = parseInt(embeddingsCountStr || '0', 10)

    if (isNaN(embeddingsCount)) {
      Sentry.captureException(new Error('Unable to parse integer from embeddings_count'), {
        extra: {
          fileMetadata: JSON.stringify(metadata),
        },
      })
      return 0
    }

    return embeddingsCount
  } catch (error) {
    Sentry.captureException(new Error(`Error fetching metadata for file: ${filePath}`), {
      extra: { filePath, error: JSON.stringify(error) },
    })
    return 0
  }
}

/**
 * Checks if the total content of the given files exceeds the specified limit.
 * This function uses the embeddings count from the file metadata to determine content size.
 *
 * @param {string[]} filePaths - An array of file paths.
 * @param {number | undefined} maxTokenCount - The maximum allowed token count.
 * @param {number | undefined} chunkSize - The number of tokens per chunk.
 * @returns {Promise<boolean>} - Returns true if the total embeddings count is >= maxEmbeddingsCount, else false.
 */
export async function isFilesContentOverLimit(
  filePaths: string[],
  maxTokenCount: number = DEFAULT_MAX_TOKEN_COUNT,
  chunkSize: number = DEFAULT_CHUNK_SIZE
): Promise<boolean> {
  // Calculate the maximum allowable embeddings count based on the token limit and chunk size
  const maxEmbeddingsCount = maxTokenCount / chunkSize

  // Fetch embeddings counts for all files
  const embeddingsCounts = await Promise.all(filePaths.map(getFileEmbeddingsCount))
  const totalEmbeddingsCount = embeddingsCounts.reduce((sum, count) => sum + count, 0)

  return totalEmbeddingsCount >= maxEmbeddingsCount
}

/**
 * Get Selected Files Embedding Sum
 * Get the total embedding sum for all files in the given array of file paths.
 */
export async function getSelectedFilesEmbeddingSum(filePaths: string[]): Promise<number> {
  const embeddingsCounts = await Promise.all(filePaths.map(getFileEmbeddingsCount))
  return embeddingsCounts.reduce((sum, count) => sum + count, 0)
}

/**
 * Check If Files Exist In GCS
 *
 * @param {string[]} filePaths - An array of file paths in GCS to check
 * @returns {Promise<string[]>} - An array of file paths that exist in GCS
 */
export async function checkIfFilesExistInGCS(filePaths: string[]): Promise<string[]> {
  const existingFiles: string[] = []

  // Check if the file already exists at this google cloud storage reference by attempting to fetch the metadata
  for (const filePath of filePaths) {
    try {
      await getFileMetadataFromGCS(filePath)
      existingFiles.push(filePath)
    } catch (error) {
      // do nothing
    }
  }

  return existingFiles
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AgentResponseMetadataRead } from './schemas'

export type AgentResponseMetadataState = {
  metadata: Partial<{ [eventId: string]: AgentResponseMetadataRead }>
}

const initialState: AgentResponseMetadataState = {
  metadata: {},
}

/**
 * AgentResponseMetadata Slice
 * Holds response metadata for agent events of type AGENT_RESPONSE.
 */
export const agentResponseMetadataSlice = createSlice({
  name: 'agentResponseMetadataState',
  initialState,
  reducers: {
    // ============== Slice Actions ============== >
    nullifyData: () => initialState,

    // Upsert a single response metadata
    upsertResponseMetadata: (state, action: PayloadAction<AgentResponseMetadataRead>) => {
      state.metadata[action.payload.event_id] = action.payload
    },

    // Upsert a list of response metadata
    upsertResponseMetadataList: (state, action: PayloadAction<AgentResponseMetadataRead[]>) => {
      action.payload.forEach((meta) => {
        state.metadata[meta.event_id] = meta
      })
    },
  },
})

// Actions
export const agentResponseMetadataActions = agentResponseMetadataSlice.actions

export default agentResponseMetadataSlice.reducer

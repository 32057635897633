import { selectConversationEventIds } from '@/agent/events/store/selectors'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import EventBubbleConstructor from './EventBubbleConstructor'
import ChatLoadingBubble from '@/chat-common/components/message-bubbles/chat-loading-bubble'

type EventBubbleListProps = {
  conversationId: string
}

/**
 * Renders a list of event bubbles
 * @param props
 * @returns
 */
export default function EventBubbleList(props: EventBubbleListProps) {
  const { conversationId } = props

  // Get the list of event ids for this conversation
  const eventIds = useAppSelector((state: RootState) => selectConversationEventIds(state, { conversationId }))

  return (
    <>
      {eventIds.map((eventId) => (
        <EventBubbleConstructor key={`bubble-${eventId}`} conversationId={conversationId} eventId={eventId} />
      ))}
      <ChatLoadingBubble conversationId={conversationId} />
    </>
  )
}

import React, { useState } from 'react'
import { useCallback } from 'react'
import { Parser } from 'html-to-react'
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline'
import InlineTipError from '@/components/inline-tips/InlineTipError'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { GlobalToastType } from '@/constants/constants-ui'
import { useAppDispatch } from '@/store/store-hooks'
import { openGlobalToast } from '@/store/slices/global-toast.slice'
import { downloadArtifactAsDocX } from '../../fetch/get-download-artifact-docx'
import useArtifactMarkdown from '../../hooks/use-artifact-markdown'
import { ArtifactVersionRead, ArtifactVersionStatusType } from '@/artifacts/schemas'
import { constructArtifactFilePath } from '@/artifacts/util/get-file-path'
import { artifactNameFallback } from '@/constants/constants-artifact'

type ArtifactViewMarkdownProps = {
  artifactVersion: ArtifactVersionRead
}

/**
 * ArtifactViewMarkdown
 *
 * Component to render an artifact of type markdown.
 */
const ArtifactViewMarkdown: React.FC<ArtifactViewMarkdownProps> = (props: ArtifactViewMarkdownProps) => {
  const { artifactVersion } = props
  const dispatch = useAppDispatch()
  const htmlParser = Parser()

  // State for tracking download status
  const [isDownloading, setIsDownloading] = useState(false)

  const artifactVersionStatus = artifactVersion?.status
  const artifactVersionId = artifactVersion?.id
  const fileName = artifactVersion?.name ?? artifactNameFallback
  const filePath = constructArtifactFilePath(artifactVersion)

  // Fetch markdown content and convert to HTML
  const { htmlString, isLoading, hasError, retry } = useArtifactMarkdown({ filePath })

  // Handle downloading the artifact as DOCX
  const handleDownload = useCallback(async () => {
    if (!artifactVersionId) return

    setIsDownloading(true)
    try {
      await downloadArtifactAsDocX(artifactVersionId)
      dispatch(openGlobalToast({ type: GlobalToastType.SUCCESS, message: `${fileName} downloaded`, durationMs: 2000 }))
    } catch (err) {
      console.error('Error downloading DOCX:', err)
      dispatch(openGlobalToast({ type: GlobalToastType.ERROR, message: 'File download failed.', durationMs: 3000 }))
    } finally {
      setIsDownloading(false)
    }
  }, [fileName, artifactVersionId])

  // Render Loading State
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full py-4">
        <CircularProgressContinuousSized size={24} thickness={4} />
      </div>
    )
  }

  // Render Error State
  if (hasError || artifactVersionStatus !== ArtifactVersionStatusType.COMPLETED) {
    return (
      <div className="flex flex-col h-full justify-center items-center bg-white">
        <div className="mb-4 text-gray-700 text-left w-full">{InlineTipError('Failed to load artifact.')}</div>
        <div className="flex items-center justify-center h-full w-full pb-4">
          <button
            className="flex justify-center items-center w-full h-full bg-brand-500 text-white font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-400 hover:bg-brand-400 rounded-md text-sm"
            onClick={retry}
          >
            Retry
          </button>
        </div>
      </div>
    )
  }

  // Render HTML
  return (
    <div className="relative h-full flex flex-col bg-white">
      <div className="artifact-view-container overflow-y-auto flex-grow border">{htmlString && htmlParser.parse(htmlString)}</div>
      <div className="border-r border-l px-4 py-4 flex items-center">
        <button
          className="flex items-center justify-center w-5 h-5 text-gray-600 hover:text-gray-800 font-semibold focus:outline-none"
          onClick={handleDownload}
          disabled={isDownloading}
          aria-busy={isDownloading}
        >
          {isDownloading ? <CircularProgressContinuousSized size={16} thickness={4} /> : <DocumentArrowDownIcon className="h-5 w-5" />}
        </button>
      </div>
    </div>
  )
}

export default ArtifactViewMarkdown

import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAppDispatch } from '@/store/store-hooks'
import { ChatV2Actions } from '@/chat-common/store/chat-v2.slice'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import ContentViewerConstructor from './content-viewer-constructor'

type ContentViewerSlideoverProps = {
  open: boolean
  conversationId: string
}

/**
 * A layout component that displays content views in a slideover (modal) from the right side.
 */
export default function ContentViewerSlideover(props: ContentViewerSlideoverProps) {
  const { open, conversationId } = props
  const dispatch = useAppDispatch()

  const handleClose = () => {
    dispatch(ChatV2Actions.removeVisibleReference({ conversationId }))
    dispatch(AgentConversationsActions.removeVisibleArtifact({ conversationId }))
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        {/* Overlay transition */}
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-brand-neutral-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Slideover container */}
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-5">
              {/* Slideover transition */}
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="bg-brand-neutral-50 flex flex-col h-full w-full border-l border-brand-neutral-200">
                    {/* Slideover Content */}
                    <div className="flex-grow overflow-y-auto">
                      <ContentViewerConstructor conversationId={conversationId} />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import ArrowOutward from '@mui/icons-material/ArrowOutward'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline' // Icon for error state
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { artifactNameFallback } from '@/constants/constants-artifact'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { ArtifactVersionRead, ArtifactVersionStatusType } from '@/artifacts/schemas'
import { RootState } from '@/store/store'
import { selectConversationWebSocketConnected } from '@/websocket_v2/ws2.selectors'

type ArtifactButtonProps = {
  conversationId: string
  artifactVersion: ArtifactVersionRead
  onCancelGenerating: () => void
}

/**
 * ArtifactButton
 *
 * Renders a single button that either shows a "Generating..." state, an "Error" state,
 * or a "View Artifact" button based on the artifact's status.
 */
export default function ArtifactButton(props: ArtifactButtonProps) {
  const { conversationId, artifactVersion, onCancelGenerating } = props
  const dispatch = useAppDispatch()

  // Check the conversation's websocket connection status
  const isConnected = useAppSelector((state: RootState) => selectConversationWebSocketConnected(state, { conversationId }))

  // Fallback to artifact name if artifact version doesn't have a name
  const name = artifactVersion.name || artifactNameFallback

  // Handle View Artifact
  const handleViewArtifact = () => {
    dispatch(AgentConversationsActions.setVisibleArtifact({ conversationId, artifactVersion }))
  }

  const renderButton = () => {
    switch (artifactVersion.status) {
      case ArtifactVersionStatusType.GENERATING: {
        return (
          <div className="grid grid-cols-[60px_auto] items-center min-h-[60px] w-full max-w-md bg-gray-50 border border-gray-300 rounded-[5px] overflow-hidden">
            <div className="bg-gray-100 flex items-center justify-center w-full h-full border-r border-gray-300">
              <CircularProgressContinuousSized size={20} thickness={4} />
            </div>

            <div className="flex justify-between flex-wrap gap-x-2 gap-y-1 p-3 items-baseline">
              <div className="text-[13px] text-left font-medium leading-tight">Generating...</div>

              {/* Can only cancel generation if the websocket connection is open. */}
              {isConnected ? (
                <button
                  onClick={onCancelGenerating}
                  aria-label="Cancel generating document"
                  className="leading-none text-[12px] text-red-700 font-normal cursor-pointer inline"
                >
                  Cancel
                </button>
              ) : (
                <div>&nbsp;</div>
              )}
            </div>
          </div>
        )
      }

      case ArtifactVersionStatusType.ERROR:
      case ArtifactVersionStatusType.CANCELLED: {
        const message = artifactVersion.status === ArtifactVersionStatusType.CANCELLED ? 'Draft generation canceled.' : 'Failed to generate draft.'
        return (
          <div className="grid grid-cols-[60px_auto] items-center min-h-[60px] w-full max-w-md bg-gray-50 border border-gray-300 rounded-[5px] overflow-hidden">
            <div className="bg-gray-100 flex items-center justify-center w-full h-full border-r border-gray-300">
              <ErrorOutlineIcon className="text-red-700" />
            </div>

            <div className="flex flex-col justify-center p-3">
              <div className="text-xs font-medium text-gray-500">{message}</div>
            </div>
          </div>
        )
      }

      case ArtifactVersionStatusType.COMPLETED: {
        return (
          <button
            onClick={handleViewArtifact}
            className="grid grid-cols-[60px_auto] items-center min-h-[60px] w-full max-w-md bg-gray-50 border border-gray-300 rounded-[5px] overflow-hidden"
            aria-label="View Artifact"
          >
            <div className="bg-gray-100 flex items-center justify-center w-full h-full border-r border-gray-300">
              <TextSnippetIcon className="text-gray-400" />
            </div>

            <div className="flex flex-col justify-center p-3">
              <div className="text-[13px] text-left font-medium leading-tight mb-1">{name}</div>
              <div className="text-[12px] text-gray-500 flex items-center leading-tight">
                Open
                <ArrowOutward sx={{ fontSize: 14, marginLeft: '3px', marginBottom: '1px' }} />
              </div>
            </div>
          </button>
        )
      }

      default: {
        // If a new status is added in the future, TypeScript will flag this as an error
        const _exhaustiveCheck: never = artifactVersion.status
        return _exhaustiveCheck
      }
    }
  }

  return renderButton()
}

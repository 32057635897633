import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'
import { WS2ReceivedDataSchema, WS2ReceivedDataType } from './ws2.schemas'
import { AgentEventChunkSchema, AgentEventType, ClientAgentEventReadSchema } from '@/agent/events/store/schemas'
import { AgentEventsActions } from '@/agent/events/store/slice'
import { ArtifactVersionUpdatedSchema } from '@/artifacts/schemas'
import { ArtifactsActions } from '@/artifacts/store/slice'
import { store } from '@/store/store'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import { kNewConversationTitle } from '@/chat-common/conversation_list/constants'
import { selectAgentConversationFromId } from '@/agent/conversations/store/selectors'
import * as Sentry from '@sentry/browser'

export function ws2EventHandler(conversationId: string, event: MessageEvent): void {
  const stringifiedData = event.data
  if (!stringifiedData) {
    console.error('No data received from the Stream event')
    return
  }

  // Parse the string into JSON
  let parsedData
  try {
    parsedData = JSON.parse(stringifiedData)
  } catch (error) {
    console.error('Error parsing string into JSON: ', error)
    return
  }

  // Validate the data
  const validatedData = WS2ReceivedDataSchema.safeParse(parsedData)
  if (!validatedData.success) {
    console.error('Invalid websocket event: ', validatedData.error)
    return
  }

  // Destructure the data
  const { payload, type } = validatedData.data

  // Validate and handle the data
  switch (type) {
    case WS2ReceivedDataType.CONVERSATION_EVENT: {
      const validated = ClientAgentEventReadSchema.safeParse(payload)
      if (!validated.success) {
        console.error('Invalid chat message payload: ', validated.error)
        return
      }

      // Upsert the event into state for rendering
      store.dispatch(AgentEventsActions.upsertEvents({ conversationId, events: [validated.data] }))

      /**
       * Form clearing logic
       *
       * IF: This is a user_query event
       * THEN:
       * - Set the queryIsSubmitting state to false
       *
       * - IF: the value matches the current form input
       *    - THEN: the Clear the form input
       */
      const agentEventType = validated.data.type
      if (agentEventType === AgentEventType.USER_QUERY) {
        // Mark as no longer submitting
        store.dispatch(AgentConversationFormActions.setQueryIsSubmitting({ conversationId, isSubmitting: false }))

        // Check and clear form input
        const currentFormInput = store.getState().agentConversationFormState.forms[conversationId]?.requestParams.input_text ?? ''
        if (currentFormInput === validated.data.value) {
          store.dispatch(
            AgentConversationFormActions.setInputText({
              conversationId,
              text: '',
            })
          )
        }

        // Generate the conversation title if this is a new conversation
        const currentConversationTitle = selectAgentConversationFromId(store.getState(), { conversationId })?.title
        if (currentConversationTitle === kNewConversationTitle) {
          store.dispatch(AgentConversationsActions.generateConversationTitle({ conversationId }))
        }
      }

      break
    }
    case WS2ReceivedDataType.CONVERSATION_EVENT_CHUNK: {
      const validated = AgentEventChunkSchema.safeParse(payload)
      if (!validated.success) {
        console.error('Invalid chunk payload: ', validated.error)
        return
      }

      store.dispatch(
        AgentEventsActions.appendEventChunk({
          conversationId,
          eventId: validated.data.event_id,
          chunk: validated.data.chunk,
        })
      )

      break
    }

    case WS2ReceivedDataType.CLOSE_DONE: {
      console.log('CLOSE_DONE: server closed the connection')
      break
    }

    case WS2ReceivedDataType.CLOSE_ERROR_APP: {
      console.error('CLOSE_ERROR_APP: server closed the connection due to an application error (gracefully handled)')

      Sentry.captureException(new Error('Server closed websocket connection due to application error'), {
        extra: {
          conversationId: conversationId,
          ws2ReceivedDataType: WS2ReceivedDataType.CLOSE_ERROR_APP,
        },
      })
      break
    }

    case WS2ReceivedDataType.CLOSE_ERROR_OTHER: {
      console.error('CLOSE_ERROR_OTHER: The server closed the connection due to an unknown or unhandled error.')

      Sentry.captureException(new Error('Server closed websocket connection due an unknown or unhandled error'), {
        extra: {
          conversationId: conversationId,
          ws2ReceivedDataType: WS2ReceivedDataType.CLOSE_ERROR_OTHER,
        },
      })
      break
    }

    case WS2ReceivedDataType.CLOSE_STOP: {
      console.log('CLOSE_STOP: server closed the connection due to a stop request')
      break
    }

    case WS2ReceivedDataType.ARTIFACT_VERSION_UPDATED: {
      const validated = ArtifactVersionUpdatedSchema.safeParse(payload)
      if (!validated.success) {
        console.error('Invalid ARTIFACT_VERSION_UPDATED payload: ', validated.error)
        return
      }

      // Upsert the artifact version into state
      store.dispatch(ArtifactsActions.upsertArtifactVersion(validated.data.artifact_version))

      // Update the artifact_version_id of the event to associate it with the artifact version
      store.dispatch(
        AgentEventsActions.updateArtifactVersionId({
          conversationId: conversationId,
          eventId: validated.data.response_event_id,
          artifactVersionId: validated.data.artifact_version.id,
        })
      )

      break
    }

    case WS2ReceivedDataType.CONFIDENCE: {
      console.log('CONFIDENCE: Handling confidence message.')

      // TODO: Handle confidence message sent by the server
      break
    }

    case WS2ReceivedDataType.STATUS: {
      console.log('STATUS: Handling status message.')

      // TODO: Handle status message sent by the server
      break
    }

    default: {
      console.error('Unknown stream payload type: ', type)
    }
  }
}

import { useEffect, useRef } from 'react'
import { useAppSelector } from '@/store/store-hooks'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { RootState } from '@/store/store'
import ErrorComponent from '@/components/error/error-component'
import NewChatIntroAssistant from '@/chat-common/components/new-chat-intro-assistant'
import ChatWindowDropZoneMultifile, { ChatWindowDropZoneMultifileRef } from '@/chat-common/components/chat-window-dropzone-multifile'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { selectAgentConversationExists, selectAgentConversationLoading, selectAgentConversationLoadError } from '@/agent/conversations/store/selectors'
import AgentChatFormWrapper from '@/agent/chat_form/components/AgentChatFormWrapper'
import { FileUploadContext } from '@/context/file-upload-context'
import { useDispatch } from 'react-redux'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import EventBubbleList from './bubbles/EventBubbleList'
import ScrollToBottomIcon from './components/ScrollToBottomIcon'
import { selectConversationEventIds } from '../events/store/selectors'
import { setLastViewedConvoIdByFeature } from '@/chat-common/store/chat-v2-ux.slice'

type AgentChatWindowProps = {
  conversationId: string
}

/**
 * Chat Window
 *
 * This component is mainly for templating the Chat Window area.
 * - Scrollable messages area
 * - Chat form at the bottom
 * @param props
 * @returns
 */
export default function AgentChatWindow(props: AgentChatWindowProps) {
  const { conversationId } = props

  const dispatch = useDispatch()

  // Redux State Selectors
  const conversationExists = useAppSelector((state: RootState) => selectAgentConversationExists(state, { conversationId: conversationId }))
  const conversationLoading = useAppSelector((state: RootState) => selectAgentConversationLoading(state, { conversationId: conversationId }))
  const conversationLoadError = useAppSelector((state: RootState) => selectAgentConversationLoadError(state, { conversationId: conversationId }))

  const eventIds = useAppSelector((state: RootState) => selectConversationEventIds(state, { conversationId: conversationId }))

  // Conversation Loading state

  // Whether to show the loader
  const showLoader = conversationLoading && eventIds.length == 0

  // // MessageFeedbackDialog state
  // const [messageFeedbackDialogVisible, setMessageFeedbackDialogVisible] = useState<boolean>(false)
  // const [messageFeedbackDialogMessage, setMessageFeedbackDialogMessage] = useState<ChatV2Message | null>(null)
  // const [messageFeedbackDialogPositive, setMessageFeedbackDialogPositive] = useState<boolean | null>(null)

  // // MessageFeedbackDialog open
  // function openMessageFeedbackDialog(message: ChatV2Message, positive: boolean) {
  //   setMessageFeedbackDialogMessage(message)
  //   setMessageFeedbackDialogPositive(positive)
  //   setMessageFeedbackDialogVisible(true)
  // }

  // Refs
  const scrollingChatWindowRef = useRef<HTMLDivElement | null>(null)
  const chatWindowDropZoneMultifileRef = useRef<ChatWindowDropZoneMultifileRef | null>(null)

  // Allows interaction with the child component's openSystemFileSelector method
  const openSystemFileSelector = () => {
    chatWindowDropZoneMultifileRef?.current?.openSystemFileSelector()
  }

  /**
   * Load the conversation data for this chat
   */
  useEffect(() => {
    dispatch(AgentConversationsActions.fetchConversationData({ conversationId, forceRefresh: true }))
  }, [conversationId, dispatch])

  /**
   * Set as the last viewed conversation
   */
  useEffect(() => {
    if (conversationExists) {
      // Visually and in terms of UX, the "agent" feature is the assistant.
      dispatch(setLastViewedConvoIdByFeature({ feature: ChatV2Feature.assistant, conversationId }))
    }
  }, [conversationExists, conversationId, dispatch])

  // If there was an error loading the conversation, show the error component
  if (conversationLoadError) {
    return (
      <div className={'p-5'}>
        <ErrorComponent {...conversationLoadError} />
      </div>
    )
  }

  return (
    <>
      {/* TODO: update feedback dialog to conditionally handle agent */}
      {/* {messageFeedbackDialogMessage && messageFeedbackDialogPositive != null && (
        <MessageFeedbackDialog
          message={messageFeedbackDialogMessage}
          positive={messageFeedbackDialogPositive}
          visible={messageFeedbackDialogVisible}
          onClose={() => {
            setMessageFeedbackDialogVisible(false)
            setMessageFeedbackDialogMessage(null)
            setMessageFeedbackDialogPositive(null)
          }}
        />
      )} */}

      <div className="flex flex-grow flex-col items-center px-2">
        <div ref={scrollingChatWindowRef} className="flex-1 flex-grow overflow-y-scroll w-full mb-2">
          {/* This div will contain the conversation and will be scrollable */}
          <div className="max-w-3xl mx-auto h-full">
            {/* Render circular progress indicator if we're loading the messages and there are no messages */}
            {showLoader && (
              <div className={'w-full h-full flex justify-center items-center'}>
                <CircularProgressContinuousSized size={30} thickness={7} hexColor={'#2A49FF'} />
              </div>
            )}

            {/* Render the new chat intro if we are on the new chat screen and have not sent an initial message */}
            {!showLoader && eventIds.length == 0 && (
              <div className="flex flex-col justify-start pt-2 xs:pt-8 pb-5">
                <NewChatIntroAssistant />
                <ChatWindowDropZoneMultifile
                  ref={chatWindowDropZoneMultifileRef}
                  hideDropZoneUI={eventIds.length > 0}
                  chatId={conversationId}
                  chatFeature={ChatV2Feature.agent}
                />
              </div>
            )}

            {conversationExists && eventIds.length > 0 && (
              <>
                {/* Render ChatWindowDropZoneMultifile with the UI hidden once the conversation has started */}
                <ChatWindowDropZoneMultifile
                  ref={chatWindowDropZoneMultifileRef}
                  hideDropZoneUI={eventIds.length > 0}
                  chatId={conversationId}
                  chatFeature={ChatV2Feature.agent}
                />

                {/* Render the conversation messages */}
                {/* {messageIds.map((id) => {
                  return (
                    <BubbleConstructor
                      key={`${id}`}
                      chatId={chatId}
                      messageId={id}
                      openFeedbackDialog={openMessageFeedbackDialog}
                      onNewMessageText={() => {
                        if (stickToBottom) {
                          scrollToBottom()
                        }
                      }}
                    />
                  )
                })} */}
              </>
            )}

            <EventBubbleList conversationId={conversationId} />
          </div>
        </div>

        {/* This form will stay at the bottom of the screen */}
        {conversationExists && (
          // TODO: Move the down arrow chevron into its own component
          <div className={'w-full pb-4 pt-2 max-w-3xl m-auto border-t'}>
            {/* Floating go to bottom button */}
            <ScrollToBottomIcon conversationId={conversationId} scrollingDivRef={scrollingChatWindowRef} />

            <FileUploadContext.Provider value={{ openSystemFileSelector: openSystemFileSelector }}>
              <AgentChatFormWrapper conversationId={conversationId} />
            </FileUploadContext.Provider>
          </div>
        )}
      </div>
    </>
  )
}

import { z } from 'zod'

export enum AgentFocusMode {
  INTELLIGENT = 'INTELLIGENT',
  DRAFTING = 'DRAFTING',
  ANALYSIS = 'ANALYSIS',
}

/**
 * Agent Assistant Request Params
 * This schema is a replication of the backend agent assistant
 * form data model, used for processing requests.
 *
 * Default values are provided for each field.
 */
export const AgentAssistantRequestParamsSchema = z.object({
  input_text: z.string().default(''),
  focus_mode: z.nativeEnum(AgentFocusMode).default(AgentFocusMode.INTELLIGENT),
  file_paths: z.array(z.string()).default([]),
})
export type AgentAssistantRequestParams = z.infer<typeof AgentAssistantRequestParamsSchema>

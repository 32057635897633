import { store } from '@/store/store'
import {
  ChatV2MessageChunk,
  chatV2AddMessageFromMetadata,
  chatV2HandleConfidencePayload,
  chatV2HandleFullResponse,
  chatV2HandleMessageChunk,
  chatV2InsertUserQueryMessage,
  ChatV2Actions,
} from './store/chat-v2.slice'
import { z } from 'zod'
import { ConfidenceWSEventDataSchema } from '@/components/confidence-indicator/schema/confidence-indicator-schema'
import { ChatV2Message, ChatV2MessageMetadataSchema, ChatV2MessageReferencesSchema, ChatV2WebSocketFullResponseSchema } from './schemas/chat-v2.schemas'

// General schema and type for all websocket events
export const ChatV2Event = z.object({
  event: z.string(),
  data: z.any(),
})
export type ChatV2EventType = z.infer<typeof ChatV2Event>

/**
 * ChatV2 Dispatcher
 * Accepts stringified JSON data and validates it against known chat schemas
 * in order to dispatch against the correct reducers.
 *
 * If a fromPendingId is provided, we delete it from state after the first event is received from the server.
 *
 * @param onMigrationCallback callback function to run after migration from pending to server conversation is complete
 * @param onMessageIdCallback callback function to run after receiving a message id from the websocket
 * @param eventData stringified json data returned from websocket event
 * @param dispatch Redux Toolkit App Dispatch from the store
 */
export default function chatV2Dispatcher(onMessageIdCallback: (newMessageId: string) => void, userMessage: ChatV2Message, eventData: string) {
  // Parse the data
  const jsonData = JSON.parse(eventData)

  // Validate the data against the general event schema
  const validatedData = ChatV2Event.safeParse(jsonData)
  if (!validatedData.success) {
    throw new Error(`Invalid event schema for chatV2Dispatcher: ${eventData}`)
  }

  // Destructure the data
  const { event, data } = validatedData.data
  // console.log('Handling event: ', event, ' with data: ', data)

  // Switch - Evaluate the type of payload from its schema and dispatch appropriately
  switch (true) {
    case event == 'status':
      console.log(`Status received: `, data)
      break

    // Update the conversation's metadata
    case event == 'metadata': {
      console.log('Handling metadata: ', data)

      const metadata = ChatV2MessageMetadataSchema.safeParse(data)
      if (!metadata.success) throw new Error(`Invalid metadata schema for chatV2Dispatcher: ${metadata.error}`)

      // Insert the user's initial query into the conversation (action handles if it already exists)
      store.dispatch(chatV2InsertUserQueryMessage({ conversationId: metadata.data.conversation_id, message: userMessage }))

      // Dispatch the metadata
      store.dispatch(chatV2AddMessageFromMetadata(metadata.data))

      // Dispatch the references (support sentence annotator refactor)
      store.dispatch(ChatV2Actions.addReferencesFromMetadata(metadata.data))

      // Call the callback with the new message id
      onMessageIdCallback(metadata.data.message_id)

      break
    }

    // Response: full instant server response message
    case event == 'response': {
      console.log('Received full response: ', validatedData.data)
      const response = ChatV2WebSocketFullResponseSchema.safeParse(data)
      if (!response.success) throw new Error(`Invalid full response schema for chatV2Dispatcher: ${response.error}`)

      // Insert the user's initial query into the conversation (action handles if it already exists)
      store.dispatch(chatV2InsertUserQueryMessage({ conversationId: response.data.metadata.conversation_id, message: userMessage }))

      // Insert full response into state
      store.dispatch(chatV2HandleFullResponse(response.data))

      // Call the callback with the new message id
      onMessageIdCallback(response.data.metadata.message_id)

      break
    }

    case event == 'request_params':
      // This does nothing yet
      break

    // Chunk: streaming message response
    case event == 'chunk': {
      const chunk = ChatV2MessageChunk.safeParse(data)
      if (!chunk.success) throw new Error(`Invalid chunk schema for chatV2Dispatcher: ${chunk}`)

      // Concatenate the message chunk to the message
      store.dispatch(chatV2HandleMessageChunk(chunk.data))

      // Call the callback with the new message id
      onMessageIdCallback(chunk.data.message_id)
      break
    }

    case event == 'confidence': {
      // console.log('Received confidence data: ', data)
      const confidence = ConfidenceWSEventDataSchema.safeParse(data)
      if (!confidence.success) throw new Error(`Invalid full response schema for chatV2Dispatcher: ${confidence.error}`)

      // Add the confidence data to the state
      store.dispatch(chatV2HandleConfidencePayload(confidence.data))

      break
    }

    case event == 'references': {
      const references = ChatV2MessageReferencesSchema.safeParse(data)
      if (!references.success) throw new Error(`Invalid message references schema for chatV2Dispatcher: ${references.error}`)

      // Dispatch the references (support sentence annotator refactor)
      store.dispatch(ChatV2Actions.addReferencesFromPayload(references.data))

      break
    }

    default:
      console.warn(`Unknown event type for chatV2Dispatcher: ${eventData}`)
      break
  }
}

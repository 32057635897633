import { getAuth } from 'firebase/auth'
import { jwtDecode } from 'jwt-decode'

/**
 * Get the ID token for the WS2.
 * If the token is within 10 minutes of expiration, force a refresh.
 * This is to avoid the server receiving and attemting to use a token that is about to expire (actually happens).
 * @returns {Promise<string>} The ID token
 */
export async function getIdTokenForWS2(): Promise<string> {
  // Step 1: Get the latest auth id token
  // This will force a refresh if the token is within 10 minutes of expiration
  const auth = getAuth()
  const user = auth.currentUser
  if (!user) {
    throw new Error('User not authenticated')
  }

  // Get the ID token
  const idToken = await user.getIdToken()
  if (!idToken) {
    throw new Error('Failed to get auth token')
  }

  // Decode the idToken and check the expiration time
  const decodedToken = jwtDecode(idToken)
  const expirationTime = decodedToken.exp
  if (!expirationTime) {
    throw new Error('Failed to get expiration time from token / invalid token')
  }

  // Calculate the time until expiration
  const now = Math.floor(Date.now() / 1000)
  const timeUntilExpiration = expirationTime - now
  console.log(`ID Token expires in ${timeUntilExpiration} seconds`)

  // Force a refresh if the token is within 10 minutes of expiration
  if (timeUntilExpiration < 600) {
    console.log('Forcing a refresh of the ID token')
    const refreshedIdToken = await user.getIdToken(true)
    return refreshedIdToken
  }

  return idToken
}

import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useState, useEffect } from 'react'
import { useAppDispatch } from '@/store/store-hooks'
import { ChatListSidebarItem } from '../../conversation_list/schema'
import { ChatV2Actions, ChatV2Feature } from '../../store/chat-v2.slice'
import deleteConversationById from '@/chat-common/fetch/fetch-delete-conversation'
import deleteAgentConversation from '@/agent/conversations/fetch/delete-hide-agent-conversation'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import { useNavigate, useParams } from 'react-router-dom'

type ConversationDeleteConfirmDialogProps = {
  dialogVisible: boolean
  chatItem: ChatListSidebarItem | null
  onDialogClose: () => void
}

export default function ConversationDeleteConfirmDialog(props: ConversationDeleteConfirmDialogProps) {
  const { dialogVisible, chatItem, onDialogClose: onDialogClose } = props
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { chatFeature, chatId } = useParams()

  const handleNavigateAway = (chatItem: ChatListSidebarItem) => {
    // Conditions for navigating away from this URL because it was deleted
    if (chatId === chatItem.id && chatFeature) {
      navigate(`/dashboard/chat/${chatFeature}`)
    }
  }

  const handleConfirm = async () => {
    setLoading(true)
    setError(false)

    if (!chatItem) {
      console.error('No conversation to delete.')
      return
    }

    try {
      if (chatItem.feature === ChatV2Feature.agent) {
        await deleteAgentConversation(chatItem.id)

        // If required, navigate away before deleting the conversation from the state
        handleNavigateAway(chatItem)

        // Delete the conversation from the state
        dispatch(AgentConversationsActions.deleteConversation({ conversationId: chatItem.id }))
        // Trigger refresh of the conversations list
        dispatch(AgentConversationsActions.refreshConversationList({ forceRefresh: true }))
      } else {
        await deleteConversationById(chatItem.id, () => {
          throw new Error('Error deleting conversation.')
        })

        // If required, navigate away before deleting the conversation from the state
        handleNavigateAway(chatItem)

        // Delete the conversation from the state
        dispatch(ChatV2Actions.deleteConversation({ conversationId: chatItem.id }))
        // Trigger refresh of the conversations list
        dispatch(ChatV2Actions.refreshConversations({ forceRefresh: true }))
      }
      // Short delay before closing the dialog
      await new Promise((resolve) => setTimeout(resolve, 500))

      onDialogClose()
    } catch (err) {
      console.error('Error while deleting conversation:', err)
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    // Allow only Enter and Escape keys, block any other key actions
    if (event.key === 'Enter') {
      event.preventDefault()
      handleConfirm()
    } else if (event.key === 'Escape') {
      onDialogClose()
    } else {
      // Prevent any other key from triggering its default action or propagating
      event.preventDefault()
      event.stopImmediatePropagation()
    }
  }

  // Reset the dialog if dialogVisible changes
  useEffect(() => {
    setError(false)
  }, [dialogVisible])

  useEffect(() => {
    if (dialogVisible) {
      document.addEventListener('keydown', handleKeyDown, true)
      return () => {
        document.removeEventListener('keydown', handleKeyDown, true)
      }
    }
  }, [dialogVisible])

  if (!dialogVisible) return null

  return (
    <div className="fixed inset-0 w-[100vw] h-[100vh] flex items-center justify-center z-[999]">
      {/* Dimmed background overlay */}
      <div className="absolute inset-0 bg-black opacity-50" onClick={onDialogClose}></div>
      <div className="bg-brand-neutral-50 p-4 mx-2 rounded-md shadow-lg relative z-[1000] min-w-[275px] max-w-sm sm:max-w-md">
        <h2 className="text-lg font-semibold">Delete Conversation?</h2>
        <p className="mt-2 text-sm whitespace-pre-wrap">You are about to permanently delete "{chatItem?.title}". Please confirm.</p>
        {error && <p className="mt-2 text-sm text-red-700">Failed to delete chat. Please try again.</p>}
        <div className="flex gap-x-4 justify-end mt-4">
          <button
            className="inline-flex justify-center rounded-md bg-brand-neutral-50 px-5 py-2 text-sm font-semibold text-brand-neutral-900 shadow-sm ring-1 ring-inset ring-brand-neutral-300 hover:bg-brand-neutral-50"
            onClick={onDialogClose}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="flex items-center gap-x-2 justify-center rounded-md bg-brand-500 px-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400"
            onClick={handleConfirm}
            disabled={loading}
          >
            <div>Delete</div>
            {loading && <CircularProgressContinuousSized size={12} thickness={7} hexColor={'#ffffff'} />}
          </button>
        </div>
      </div>
    </div>
  )
}

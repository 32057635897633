import { getBrandFromHost, getFaviconFromBrand } from '@/util/enterprise'
import OnboardingWelcome from '../components/OnboardingWelcome'

const Onboarding: React.FC = () => {
  const hostBrand = getBrandFromHost()
  const faviconUrl = getFaviconFromBrand(hostBrand)

  return (
    <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-10 py-12 bg-brand-neutral-50 rounded-lg shadow-lg flex flex-grow flex-col space-y-8">
      <div className="flex flex-col gap-y-2.5">
        <h1 className="text-4xl leading-4xl font-semibold flex items-center tex-brand-950">
          <img className="flex mr-3 h-8 w-8" src={faviconUrl} alt={hostBrand} />
          Welcome!
        </h1>
        <p className="text-base leading-6 text-brand-neutral-600 -tracking-[0.32px]">
          Please answer a few questions to help {hostBrand.charAt(0).toUpperCase() + hostBrand.slice(1)} personalize your experience.
        </p>
      </div>
      <OnboardingWelcome visible={true} />
    </div>
  )
}

export default Onboarding

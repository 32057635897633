import { ChatV2QueryMetadataResearch } from '@/chat-common/schemas/chat-query-metadata-schema'
import {
  ChatV2Feature,
  chatV2CurrentSourceResearchSetCaseLawDateRange,
  chatV2CurrentSourceResearchSetIncludeUnpublishedCases,
} from '@/chat-common/store/chat-v2.slice'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useState } from 'react'
import ResearchDialog from '../source-dialogs/research/research-dialog'
import { CourtsResponse, useGetAllCourtsQuery } from '@/store/apis/hosted-filters-api'
import { Source } from '@mui/icons-material'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { capitalizeFirstLetters } from '@/util/string-utils'
import DateRangeSelector from './caselaw/caselaw-date-range-selector'
import { inferSelectedStateFromCourtIds } from '../source-dialogs/research/research-source-maps'
import IncludeUnpublishedCasesPopover from '../source-dialogs/caselaw/include-unpublished-cases-popover'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'

type ChatControlsResearchProps = {
  conversationId: string
}

export default function ChatControlsResearch(props: ChatControlsResearchProps) {
  const { conversationId } = props
  const dispatch = useAppDispatch()
  const { trackEvent } = useAnalytics()

  // Local UX state
  const [dialogOpen, setDialogOpen] = useState(false)

  // Source information from the conversation
  const currentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataResearch | null
  const yearLow = currentSource?.case_law_year_low ?? null
  const yearHigh = currentSource?.case_law_year_high ?? null

  // Get All Courts Query
  const { data: courtsData, isLoading: courtsLoading, isError: isCourtsError } = useGetAllCourtsQuery()

  const stateCourts = courtsData?.State ?? null
  const federalCourts = courtsData?.Federal ?? null

  // Loading and error states
  if (courtsLoading) return <CircularProgressContinuousSized size={18} thickness={7} hexColor={'#ffffff'} />
  if (isCourtsError || !stateCourts || !federalCourts) return <div className="text-red-700 text-sm">Error loading courts.</div>

  // Get the description of the currently selected source to be displayed to the user
  function getSelectedSourceDescription() {
    const selectedStateCourts = currentSource?.state_court_ids ?? []
    const state = inferSelectedStateFromCourtIds(selectedStateCourts)

    const federalCourtIds = currentSource?.federal_court_ids
    const capitalizedState = capitalizeFirstLetters(state)

    if (state) {
      return federalCourtIds ? `${capitalizedState} + Federal` : capitalizedState
    } else if (federalCourtIds) {
      return `Federal (${federalCourtIds.length})`
    } else {
      return ''
    }
  }

  // Source information
  const hasSelections = currentSource?.state_court_ids != null || currentSource?.federal_court_ids != null
  const selectedSource = getSelectedSourceDescription()
  const includeUnpublishedCases = currentSource?.include_unpublished_cases // Default is false

  // Event handler for date range change
  function handleDateRangeChange(yearLow: number | null, yearHigh: number | null) {
    dispatch(chatV2CurrentSourceResearchSetCaseLawDateRange({ conversationId: conversationId, yearLow, yearHigh }))
  }

  return (
    <>
      <ResearchDialog conversationId={conversationId} open={dialogOpen} courts={courtsData as CourtsResponse} onClose={() => setDialogOpen(false)} />
      {/* Form Controls */}
      <div className="flex flex-wrap gap-x-8 gap-2 mb-2">
        <div className="flex flex-col">
          <button
            onClick={() => {
              trackEvent(AnalyticsEvent.OpenChatDialog, { feature: ChatV2Feature.research })
              setDialogOpen(true)
            }}
            className="w-auto rounded-md text-sm px-0 font-bold text-brand-500 hover:underline text-left"
          >
            Select source <Source fontSize="small" />
          </button>
          <div className="mt-1 text-left">
            {!hasSelections && <p className="mt-1 text-sm text-red-700">Source is required</p>}
            {selectedSource && <p className="mt-1 text-sm text-brand-neutral-500">Source: {selectedSource}</p>}
          </div>
        </div>
        <DateRangeSelector
          label="Case law date range"
          conversationId={conversationId}
          yearLow={yearLow}
          yearHigh={yearHigh}
          setDateRange={handleDateRangeChange}
        />
        <div>
          <p className="block text-sm font-bold text-brand-neutral-700">
            Case law unpublished cases <IncludeUnpublishedCasesPopover />
          </p>
          <div className={'flex flex-wrap gap-2 mt-1'}>
            <input
              id="include-unpublished-cases-checkbox"
              name="include-unpublished-cases-checkbox"
              type="checkbox"
              checked={includeUnpublishedCases}
              onChange={(event) => {
                dispatch(
                  chatV2CurrentSourceResearchSetIncludeUnpublishedCases({ conversationId: conversationId, includeUnpublishedCases: event.target.checked })
                )
              }}
              className="h-5 w-5 mt-1 rounded border-brand-neutral-300 text-brand-500 focus:ring-brand-500"
            />
            <label htmlFor="include-unpublished-cases-checkbox" className="mt-1 text-sm text-brand-neutral-600">
              Include
            </label>
          </div>
        </div>
      </div>
    </>
  )
}

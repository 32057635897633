import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { nanoid } from 'nanoid'
import { z } from 'zod'
import {
  reducerCurrentSourceLRRAddSource,
  reducerCurrentSourceLRRRemoveSource,
  reducerCurrentSourceLrrPathsAdd,
  reducerCurrentSourceLrrPathsRemove,
  reducerCurrentSourceLrrRemoveAll,
} from './chat-v2-reducers-lrr'
import {
  reducerAddCaseLawCourtIds,
  reducerRemoveCaseLawCourtIds,
  reducerSetCaseLawDateRange,
  reducerSetIncludeUnpublishedCases,
} from './chat-v2-reducers-caselaw'
import {
  reducerCurrentSourceDocumentQueryAddSelectedFile,
  reducerCurrentSourceDocumentQueryClearAll,
  reducerCurrentSourceDocumentQueryToggleSelectedFile,
} from './chat-v2-reducers-documentquery'

import { ChatV2SuggestedQuestions } from '../fetch/fetch-suggested-questions'

import { reducerCurrentSourceWebsearchSetMetadata } from './chat-v2-reducers-websearch'

import { reducerCurrentSourceDraftingChatMetadata } from './chat-v2-reducers-drafting'
import getDefaultQueryMetadataByFeature from '../schemas/chat-default-schema-values'
import {
  reducerCurrentSourceContractAnalysisClearAll,
  reducerCurrentSourceContractAnalysisSetAsOnlySelectedFile,
  reducerCurrentSourceContractAnalysisToggleSelectedFile,
} from './chat-v2-reducers-contractanalysis'
import {
  reducerCurrentSourceDocumentSummarizationAddSelectedFile,
  reducerCurrentSourceDocumentSummarizationClearAll,
  reducerCurrentSourceDocumentSummarizationToggleSelectedFile,
} from './chat-v2-reducers-documentsummarization'

import { reducerCurrentSourceContractSearchMetadata } from './chat-v2-reducers-contractsearch'
import { ConfidenceWSEventData } from '@/components/confidence-indicator/schema/confidence-indicator-schema'
import {
  reducerCurrentSourceResearchAddFederalCourtIds,
  reducerCurrentSourceResearchRemoveFederalCourtIds,
  reducerCurrentSourceResearchSetCaseLawDateRange,
  reducerCurrentSourceResearchAddStateCourtIds,
  reducerCurrentSourceResearchRemoveStateCourtIds,
  reducerCurrentSourceResearchAddFederalLRRSource,
  reducerCurrentSourceResearchRemoveFederalLRRSource,
  reducerCurrentSourceResearchAddStateLRRSource,
  reducerCurrentSourceResearchRemoveStateLRRSource,
  reducerCurrentSourceResearchRemoveAllStateLRRSources,
  reducerCurrentSourceResearchRemoveAllFederalLRRSources,
  reducerCurrentSourceResearchRemoveAllFederalCourtIds,
  reducerCurrentSourceResearchRemoveAllStateCourtIds,
  reducerCurrentSourceResearchSetIncludeUnpublishedCases,
} from './chat-v2-reducers-research'
import {
  reducerCurrentSourceAssistantAddFile,
  reducerCurrentSourceAssistantClearAllFiles,
  reducerCurrentSourceAssistantSetFocus,
  reducerCurrentSourceAssistantToggleSelectedFile,
} from './chat-v2-reducers-assistant'
import { paxtonHighlightAreaSchema } from '@/components/react-pdf-viewer/schema/paxton-highlight-area'
import { ConversationHeader } from '../conversation_list/schema'
import { ChatV2FullResponseType, ChatV2Message, ChatV2MessageMetadataType, ChatV2MessageReferencesType } from '../schemas/chat-v2.schemas'
import { PatchGenerateConversationTitleArgs } from '../fetch/patch-generate-conversation-title'

// Enum of chat types accepted by the server (FeatureType on server)
export enum ChatV2Feature {
  agent = 'agent',
  lrr = 'lrr',
  lrr_v2 = 'lrr_v2',
  research = 'research',
  caselaw = 'caselaw',
  websearch = 'websearch',
  assistant = 'assistant',
  documentquery = 'documentquery',
  drafting = 'drafting',
  contractanalysis = 'contractanalysis',
  contractsearch = 'contract_retrieval',
  documentSummarization = 'summarization',
}

// Enum of chat actors
export enum ChatV2MessageType {
  user_query = 'user_query',
  client_error = 'client_error',
  response = 'response',
  suggested_questions = 'suggested_questions',
}

export enum ChatV2ReferenceType {
  documentquery = 'documentquery',
  documentquery_full_doc = 'documentquery_full_doc',
  pol = 'pol',
  caselaw = 'caselaw',
  lrr_v2 = 'lrr_v2',
  websearch = 'websearch',
}

// Conversation type
export type ChatV2Conversation = {
  id: string
  lastRefresh: number | null
  title: string
  feature: ChatV2Feature
  modified_on: number
  isLoading: boolean
  isError: boolean
  messages: Record<string, ChatV2Message>
  currentSource: Record<string, any> | null // Holds the current source for any conversation type. Zod schema can validate at runtime if it's appropriate for a feature or form.
  currentTextInput: string
  formValidationError: boolean
  visibleReferenceRenderTrigger?: string // Optional parameter for triggering a re-render of the reference viewer
  openRefToCitatorTab?: boolean // Optional parameter for whether to open the reference viewer to the citator tab
  visibleReference: ChatV2MessageReferenceType | null
  references: Record<string, Record<string, ChatV2MessageReferenceType>>
}

// References type
export const ChatV2MessageReferenceSchema = z.object({
  reference_number: z.string(),
  text: z.string(),
  metadata: z.record(z.any()),
  relevant_sentences: z.array(z.string()).nullable(),
  relevant_highlights: z
    .array(paxtonHighlightAreaSchema)
    .nullable()
    .describe('Highlight bounding box data compatible with react_pdf_viewer highlighting needs and sentence grouping needs'),
})
export type ChatV2MessageReferenceType = z.infer<typeof ChatV2MessageReferenceSchema>

// Artifact Version type
export const ChatV2ArtifactVersionSchema = z.object({
  id: z.string(),
  artifact_id: z.string(),
  name: z.string(),
  summary: z.string(),
  file_full_path: z.string(),
  created_at: z.string(),
})
export type ChatV2ArtifactVersionType = z.infer<typeof ChatV2ArtifactVersionSchema>

// Message chunk schema and type (from zod schema)
export const ChatV2MessageChunk = z.object({
  conversation_id: z.string(),
  message_id: z.string(),
  value: z.string(),
})
export type ChatV2MessageChunkType = z.infer<typeof ChatV2MessageChunk>

export enum ChatV2PromptAssistMode {
  feature_correction = 'feature_correction',
  prompt_follow_up = 'prompt_follow_up',
  untriggered = 'untriggered',
  unassisted = 'unassisted',
}

type ConversationId = string

/**
 * Chat V2 State Slice
 * This slice contains the state for the chat V2 feature.
 * @param conversations - Record of conversations by conversation id
 * @param initialLoadComplete - Whether the initial load of conversations has completed
 * @param lastRefresh - Timestamp of the last refresh (milliseconds)
 * @param loading - Loading status of the conversations fetching
 * @param error - Error status of the conversations fetching
 */
export type ChatV2State = {
  conversations: Record<ConversationId, ChatV2Conversation>
  initialLoadComplete: boolean
  lastRefresh: number | null
  loading: boolean
  error: boolean
}

// Initial state
const initialState: ChatV2State = {
  conversations: {},
  initialLoadComplete: false,
  lastRefresh: null,
  loading: false,
  error: false,
}

// Slice / reducers
export const chatV2Slice = createSlice({
  name: 'chatV2State',
  initialState,
  reducers: {
    // ============================== Listener Trigger Actions ==============================>
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refreshConversations: (_state, _action: PayloadAction<{ forceRefresh: boolean }>) => {
      // Handled by listener middleware
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    generateConversationTitle: (_state, _action: PayloadAction<PatchGenerateConversationTitleArgs>) => {
      // Handled by listener middleware
    },

    // ============================== State Actions ==============================>
    nullifyData: () => initialState,

    // Set last refresh
    setLastRefresh: (state, action: PayloadAction<number>) => {
      state.lastRefresh = action.payload
    },

    // Set Loading
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    // Set Error
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload
    },

    // Set Initial Load Complete
    setInitialLoadComplete: (state, action: PayloadAction<boolean>) => {
      state.initialLoadComplete = action.payload
    },

    // Upsert a conversation From Header
    // IF: Conversation does not exist, create it
    // ELSE: Update the conversation (specific fields only)
    upsertConversationFromHeader: (state, action: PayloadAction<ConversationHeader>) => {
      const conversationHeader = action.payload
      const existingConversation = state.conversations[conversationHeader.id]

      // If the conversation does not exist, create it
      if (!existingConversation) {
        const newConversation: ChatV2Conversation = {
          id: conversationHeader.id,
          lastRefresh: Date.now(),
          title: conversationHeader.title,
          isLoading: false,
          feature: conversationHeader.feature,
          messages: {},
          modified_on: conversationHeader.modified_on,
          currentSource: getDefaultQueryMetadataByFeature(conversationHeader.feature),
          currentTextInput: '',
          isError: false,
          formValidationError: false,
          visibleReference: null,
          references: {},
        }

        // Add the conversation to the state
        state.conversations[conversationHeader.id] = newConversation

        return
      } else {
        // If the modified_on date is the same, skip the update
        if (existingConversation.modified_on === conversationHeader.modified_on) {
          return
        }

        // Update the conversation
        existingConversation.title = conversationHeader.title
        existingConversation.modified_on = conversationHeader.modified_on
      }
    },

    // Add Conversation Messages
    // IF: Message does not exist, add it
    upsertConversationMessages: (state, action: PayloadAction<{ conversationId: string; messages: ChatV2Message[] }>) => {
      const { conversationId, messages } = action.payload

      // Get the conversation messages
      const currentMessages = state.conversations[conversationId].messages

      // Get the conversation references
      const currentReferences = state.conversations[conversationId].references

      if (!currentMessages) return

      // Filter for current user_query messages
      const currentUserQueries = Object.values(currentMessages).filter((message) => message.metadata.message_type === ChatV2MessageType.user_query)

      // Insert the messages into the conversation
      messages.forEach((message) => {
        // DUPLICATION PREVENTION: because we are currently inserting messages on the client side,
        // we need to prevent duplicates being injected from the server response.
        // - This is temporary until we are only submitting and receiving messages from the server
        //
        // 1. If this is a user_query message, check if the text value matches any user_query messages already in the conversation
        const thisMessageIsUserQuery = message.metadata.message_type === ChatV2MessageType.user_query
        if (thisMessageIsUserQuery) {
          const isDuplicateOfClientUserQuery = currentUserQueries.some((userQuery) => userQuery.text === message.text)
          if (isDuplicateOfClientUserQuery) return
        }
        // END DUPLICATION PREVENTION

        // Normal Activity: Do not insert the message if it already exists
        const messageExists = !!currentMessages[message.metadata.message_id]
        if (!messageExists) {
          // Insert the message
          currentMessages[message.metadata.message_id] = message
        }

        // Set references from metadata when provided
        const referenceExists = !!currentReferences[message.metadata.message_id]
        if (!referenceExists) {
          currentReferences[message.metadata.message_id] = message.metadata?.references || {}
        }
      })
    },

    // Update conversations's modified_on timestamp
    updateConversationModifiedOn: (state, action: PayloadAction<{ conversationId: string; modifiedOn: number }>) => {
      const { conversationId, modifiedOn } = action.payload

      // Update the conversation's modified_on timestamp to match the most recent message
      state.conversations[conversationId].modified_on = modifiedOn
    },

    // Update Conversation Current Source
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateConversationCurrentSource: (state, action: PayloadAction<{ conversationId: string; currentSource: Record<string, any> | null }>) => {
      const { conversationId, currentSource } = action.payload

      // Get the conversation
      const conversation = state.conversations[conversationId]
      if (!conversation) return

      // Update the conversation's current source
      conversation.currentSource = currentSource
    },

    // Update the conversation title
    chatV2UpdateConversationTitle: (state, action: PayloadAction<{ conversationId: string; title: string }>) => {
      const { conversationId, title } = action.payload

      // Get the conversation
      const conversation = state.conversations[conversationId]
      if (!conversation) return

      // Update the conversation title
      conversation.title = title
    },

    // Delete a pending conversation
    deleteConversation: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload

      // Delete the pending conversation
      delete state.conversations[conversationId]
    },

    chatV2SetConversationCurrentTextInput: (state, action: PayloadAction<{ conversationId: string; text: string }>) => {
      const { conversationId, text } = action.payload

      // Set the conversation's current text input
      state.conversations[conversationId].currentTextInput = text
    },

    chatV2SetConversationLoadingStatus: (state, action: PayloadAction<{ conversationId: string; isLoading: boolean }>) => {
      const { conversationId, isLoading } = action.payload

      // Set the conversation's loading status
      state.conversations[conversationId].isLoading = isLoading
    },

    chatV2SetFormValidationError: (state, action: PayloadAction<{ conversationId: string; formValidationError: boolean }>) => {
      const { conversationId, formValidationError } = action.payload

      // Set the conversation's loading status
      state.conversations[conversationId].formValidationError = formValidationError
    },

    // Add Client Error Message Bubble
    addClientErrorMessageBubble: (state, action: PayloadAction<{ conversationId: string; errorMessage: string }>) => {
      const { conversationId, errorMessage } = action.payload

      // Get state conversation reference
      const conversation = state.conversations[conversationId]

      // Generate a message id
      const messageId = nanoid()

      // Construct a ChatV2Message of type client_error
      const message: ChatV2Message = {
        text: errorMessage,
        metadata: {
          feature: conversation.feature,
          message_type: ChatV2MessageType.client_error,
          created_at: Date.now(),
          conversation_id: conversation.id,
          message_id: messageId,
          is_error: true,
          get_follow_up_questions: false,
          references: {},
        },
      }

      // Add the client error message to the conversation
      conversation.messages[messageId] = message

      // Set loading to false
      conversation.isLoading = false
    },

    // Add Client Suggested Questions Message Bubble
    addSuggstedQuestionsMessageBubble: (
      state,
      action: PayloadAction<{ conversationId: string; newMessageId: string; loading: boolean; suggestedQuestions?: ChatV2SuggestedQuestions }>
    ) => {
      const { conversationId, newMessageId, loading, suggestedQuestions } = action.payload

      // Get state conversation reference
      const conversation = state.conversations[conversationId]

      // Construct a ChatV2Message of type follow_up_questions
      const message: ChatV2Message = {
        text: 'this should not be rendered on this type of message',
        metadata: {
          feature: conversation.feature,
          message_type: ChatV2MessageType.suggested_questions,
          created_at: Date.now(),
          conversation_id: conversation.id,
          message_id: newMessageId,
          is_error: false,
          get_follow_up_questions: false,
          suggested_questions: suggestedQuestions,
          suggested_questions_loading: loading,
          references: {},
        },
      }

      // Add the client error message to the conversation
      conversation.messages[newMessageId] = message
    },

    // Update Suggested Questions Message Bubble
    updateSuggstedQuestionsMessageBubble: (
      state,
      action: PayloadAction<{ conversationId: string; messageId: string; isError?: boolean; loading?: boolean; suggestedQuestions?: ChatV2SuggestedQuestions }>
    ) => {
      const { conversationId, messageId, isError, loading, suggestedQuestions } = action.payload

      // Get state conversation reference
      const conversation = state.conversations[conversationId]

      // Get the message reference
      const message = conversation.messages[messageId]

      // Update the message
      if (typeof isError !== 'undefined') {
        message.metadata.is_error = isError
      }

      if (typeof loading !== 'undefined') {
        message.metadata.suggested_questions_loading = loading
      }

      if (suggestedQuestions) {
        message.metadata.suggested_questions = suggestedQuestions
      }
    },

    // Insert user's query message into the conversation (for active conversations)
    chatV2InsertUserQueryMessage: (state, action: PayloadAction<{ conversationId: string; message: ChatV2Message }>) => {
      const { conversationId, message } = action.payload

      // Insert the message if it does not already exist in the conversation
      // (this is to prevent duplicate messages from being inserted)
      // Handled both instantly when we have a conversation id, and after metadata is received for new conversations
      if (!state.conversations[conversationId].messages[message.metadata.message_id]) {
        state.conversations[conversationId].messages[message.metadata.message_id] = message
      }
    },

    // Add Message From Metadata - creates a new message in its conversation
    chatV2AddMessageFromMetadata: (state, action: PayloadAction<ChatV2MessageMetadataType>) => {
      const metadata = action.payload

      // Initialize message_id if it doesn't exist
      if (!state.conversations[metadata.conversation_id].messages[metadata.message_id]) {
        state.conversations[metadata.conversation_id].messages[metadata.message_id] = {
          text: '',
          metadata,
        }
      }
    },

    // Handle a full response
    chatV2HandleFullResponse: (state, action: PayloadAction<ChatV2FullResponseType>) => {
      const message = action.payload

      // Initialize message_id if it doesn't exist
      if (!state.conversations[message.metadata.conversation_id].messages[message.metadata.message_id]) {
        state.conversations[message.metadata.conversation_id].messages[message.metadata.message_id] = message
      }
    },

    // Handle a message chunk
    chatV2HandleMessageChunk: (state, action: PayloadAction<ChatV2MessageChunkType>) => {
      const chunk = action.payload

      // Check if the conversation exists
      if (!state.conversations[chunk.conversation_id]) {
        console.error(`Conversation does not exist in state. Cannot handle chunk:`, chunk)
        return state
      }

      const oldText = state.conversations[chunk.conversation_id].messages[chunk.message_id].text
      const newText = oldText + chunk.value

      // Update the message with the new text
      state.conversations[chunk.conversation_id].messages[chunk.message_id].text = newText
    },

    // Set Visible Reference
    setVisibleReference: (state, action: PayloadAction<{ conversationId: string; reference: ChatV2MessageReferenceType; openRefToCitatorTab?: boolean }>) => {
      const { conversationId, reference, openRefToCitatorTab } = action.payload
      const conversation = state.conversations[conversationId]
      if (!conversation) return

      conversation.visibleReference = reference
      conversation.visibleReferenceRenderTrigger = nanoid()
      conversation.openRefToCitatorTab = openRefToCitatorTab
    },

    // Remove Visible Reference
    removeVisibleReference: (state, action: PayloadAction<{ conversationId: string }>) => {
      const { conversationId } = action.payload
      const conversation = state.conversations[conversationId]
      if (!conversation) return

      conversation.visibleReference = null
      conversation.openRefToCitatorTab = false
    },

    // Handle Confidence Payload
    chatV2HandleConfidencePayload: (state, action: PayloadAction<ConfidenceWSEventData>) => {
      const { value, conversation_id, message_id } = action.payload

      // Get the conversation and message
      const message = state.conversations[conversation_id]?.messages[message_id] ?? null

      // Update the message with the confidence data
      if (!message) {
        console.error(`Trying to set confidence on unfound message: `, JSON.stringify(action.payload))
        return
      }

      // Update the message with the confidence data
      message.confidence_label = value
    },

    // Add References From Metadata - creates a new reference map in its conversation
    // Supports metadata.references - sentence annotator refactor
    addReferencesFromMetadata: (state, action: PayloadAction<ChatV2MessageMetadataType>) => {
      const metadata = action.payload

      // Initialize reference only if they don't exist or are empty
      if (
        !state.conversations[metadata.conversation_id].references[metadata.message_id] ||
        Object.keys(state.conversations[metadata.conversation_id].references[metadata.message_id]).length === 0
      ) {
        if (metadata.references) {
          state.conversations[metadata.conversation_id].references[metadata.message_id] = metadata.references
        }
      }
    },

    // Add References From Payload - creates a new reference map in its conversation
    // Supports reference payload (forced update) - sentence annotator refactor
    addReferencesFromPayload: (state, action: PayloadAction<ChatV2MessageReferencesType>) => {
      const metadata = action.payload

      // Set references from payload when provided
      if (metadata.references) {
        state.conversations[metadata.conversation_id].references[metadata.message_id] = metadata.references
      }
    },

    // currentSource Reducers: LRR_V2
    chatV2CurrentSourceLrrAddSource: reducerCurrentSourceLRRAddSource,
    chatV2CurrentSourceLrrRemoveSource: reducerCurrentSourceLRRRemoveSource,
    chatV2CurrentSourceLrrPathsAdd: reducerCurrentSourceLrrPathsAdd,
    chatV2CurrentSourceLrrPathsRemove: reducerCurrentSourceLrrPathsRemove,
    chatV2CurrentSourceLrrRemoveAll: reducerCurrentSourceLrrRemoveAll,

    // currentSource Reducers: Case Law
    chatV2SetCaseLawDateRange: reducerSetCaseLawDateRange,
    chatV2AddCaseLawCourtIds: reducerAddCaseLawCourtIds,
    chatV2RemoveCaseLawCourtIds: reducerRemoveCaseLawCourtIds,
    chatV2SetCaseLawIncludeUnpublishedCases: reducerSetIncludeUnpublishedCases,

    // currentSource Reducers: Research
    chatV2CurrentSourceResearchSetCaseLawDateRange: reducerCurrentSourceResearchSetCaseLawDateRange,
    chatV2CurrentSourceResearchAddFederalCourtIds: reducerCurrentSourceResearchAddFederalCourtIds,
    chatV2CurrentSourceResearchRemoveFederalCourtIds: reducerCurrentSourceResearchRemoveFederalCourtIds,
    chatV2CurrentSourceResearchRemoveAllFederalCourtIds: reducerCurrentSourceResearchRemoveAllFederalCourtIds,
    chatV2CurrentSourceResearchAddStateCourtIds: reducerCurrentSourceResearchAddStateCourtIds,
    chatV2CurrentSourceResearchRemoveStateCourtIds: reducerCurrentSourceResearchRemoveStateCourtIds,
    chatV2CurrentSourceResearchRemoveAllStateCourtIds: reducerCurrentSourceResearchRemoveAllStateCourtIds,
    chatV2CurrentSourceResearchAddFederalLRRSource: reducerCurrentSourceResearchAddFederalLRRSource,
    chatV2CurrentSourceResearchRemoveFederalLRRSource: reducerCurrentSourceResearchRemoveFederalLRRSource,
    chatV2CurrentSourceResearchRemoveAllFederalLRRSources: reducerCurrentSourceResearchRemoveAllFederalLRRSources,
    chatV2CurrentSourceResearchAddStateLRRSource: reducerCurrentSourceResearchAddStateLRRSource,
    chatV2CurrentSourceResearchRemoveStateLRRSource: reducerCurrentSourceResearchRemoveStateLRRSource,
    chatV2CurrentSourceResearchRemoveAllStateLRRSources: reducerCurrentSourceResearchRemoveAllStateLRRSources,
    chatV2CurrentSourceResearchSetIncludeUnpublishedCases: reducerCurrentSourceResearchSetIncludeUnpublishedCases,

    // currentSource Reducers: Document Query
    chatV2CurrentSourceDocumentQueryAddSelectedFile: reducerCurrentSourceDocumentQueryAddSelectedFile,
    chatV2CurrentSourceDocumentQueryToggleSelectedFile: reducerCurrentSourceDocumentQueryToggleSelectedFile,
    chatV2CurrentSourceDocumentQueryClearAll: reducerCurrentSourceDocumentQueryClearAll,

    // currentSource Reducers: Assistant
    chatV2CurrentSourceAssistantToggleSelectedFile: reducerCurrentSourceAssistantToggleSelectedFile,
    chatV2CurrentSourceAssistantAddFile: reducerCurrentSourceAssistantAddFile,
    chatV2CurrentSourceAssistantSetFocus: reducerCurrentSourceAssistantSetFocus,
    chatV2CurrentSourceAssistantClearAllFiles: reducerCurrentSourceAssistantClearAllFiles,

    // currentSource Reducers: contractanalysis
    chatV2CurrentSourceContractAnalysisToggleSelectedFile: reducerCurrentSourceContractAnalysisToggleSelectedFile,
    chatV2CurrentSourceContractAnalysisSetAsOnlySelectedFile: reducerCurrentSourceContractAnalysisSetAsOnlySelectedFile,
    chatV2CurrentSourceContractAnalysisClearAll: reducerCurrentSourceContractAnalysisClearAll,

    // currentSource Reducers: Websearch
    chatV2CurrentSourceWebsearchSetMetadata: reducerCurrentSourceWebsearchSetMetadata,
    chatV2CurrentSourceDraftingMetadata: reducerCurrentSourceDraftingChatMetadata,

    // currentSource Reducers: Document Summarization
    chatV2CurrentSourceDocumentSummarizationToggleSelectedFile: reducerCurrentSourceDocumentSummarizationToggleSelectedFile,
    chatV2CurrentSourceDocumentSummarizationClearAll: reducerCurrentSourceDocumentSummarizationClearAll,
    chatV2CurrentSourceDocumentSummarizationAddSelectedFile: reducerCurrentSourceDocumentSummarizationAddSelectedFile,
    chatV2CurrentSourceContractSearchMetadata: reducerCurrentSourceContractSearchMetadata,
  },
})

// New way of accessing actions `ChatV2Actions.[ide_autocomplete]`
export const ChatV2Actions = chatV2Slice.actions

// old way of accessing actions - do not add more to this list
export const {
  nullifyData,
  chatV2UpdateConversationTitle,
  chatV2SetConversationLoadingStatus,
  chatV2InsertUserQueryMessage,
  chatV2AddMessageFromMetadata,
  chatV2HandleFullResponse,
  chatV2HandleMessageChunk,
  chatV2SetFormValidationError,
  chatV2SetConversationCurrentTextInput,
  addClientErrorMessageBubble,
  addSuggstedQuestionsMessageBubble,
  updateSuggstedQuestionsMessageBubble,
  chatV2HandleConfidencePayload,
  chatV2CurrentSourceLrrAddSource,
  chatV2CurrentSourceLrrRemoveSource,
  chatV2CurrentSourceLrrPathsAdd,
  chatV2CurrentSourceLrrPathsRemove,
  chatV2CurrentSourceLrrRemoveAll,
  chatV2SetCaseLawDateRange,
  chatV2AddCaseLawCourtIds,
  chatV2RemoveCaseLawCourtIds,
  chatV2SetCaseLawIncludeUnpublishedCases,
  chatV2CurrentSourceResearchSetCaseLawDateRange,
  chatV2CurrentSourceResearchAddFederalCourtIds,
  chatV2CurrentSourceResearchRemoveFederalCourtIds,
  chatV2CurrentSourceResearchRemoveAllFederalCourtIds,
  chatV2CurrentSourceResearchAddStateCourtIds,
  chatV2CurrentSourceResearchRemoveStateCourtIds,
  chatV2CurrentSourceResearchRemoveAllStateCourtIds,
  chatV2CurrentSourceResearchAddFederalLRRSource,
  chatV2CurrentSourceResearchRemoveFederalLRRSource,
  chatV2CurrentSourceResearchRemoveAllFederalLRRSources,
  chatV2CurrentSourceResearchAddStateLRRSource,
  chatV2CurrentSourceResearchRemoveStateLRRSource,
  chatV2CurrentSourceResearchRemoveAllStateLRRSources,
  chatV2CurrentSourceResearchSetIncludeUnpublishedCases,
  chatV2CurrentSourceDocumentQueryAddSelectedFile,
  chatV2CurrentSourceDocumentQueryToggleSelectedFile,
  chatV2CurrentSourceDocumentQueryClearAll,
  chatV2CurrentSourceAssistantToggleSelectedFile,
  chatV2CurrentSourceAssistantAddFile,
  chatV2CurrentSourceAssistantSetFocus,
  chatV2CurrentSourceAssistantClearAllFiles,
  chatV2CurrentSourceContractAnalysisToggleSelectedFile,
  chatV2CurrentSourceContractAnalysisSetAsOnlySelectedFile,
  chatV2CurrentSourceContractAnalysisClearAll,
  chatV2CurrentSourceWebsearchSetMetadata,
  chatV2CurrentSourceDraftingMetadata,
  chatV2CurrentSourceContractSearchMetadata,
  chatV2CurrentSourceDocumentSummarizationToggleSelectedFile,
  chatV2CurrentSourceDocumentSummarizationClearAll,
} = chatV2Slice.actions

export default chatV2Slice.reducer

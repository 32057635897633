import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { getAuth } from 'firebase/auth'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { kTokenLimitApiPath } from '@/constants/constants-api-paths'
import { TokenLimitResponse, TokenLimitResponseSchema } from '@/chat-common/selected_files/schema'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'

/**
 * Get Token Limit
 */
export async function getTokenLimit(): Promise<TokenLimitResponse> {
  const sentry_transaction_id = nanoid()

  try {
    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw new Error(kErrorCodeAuthError)

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    const apiUrl = kPaxtonAppApiBaseUrl() + kTokenLimitApiPath
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })
        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            response: JSON.stringify(response),
            data: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
      throw new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`)
    }

    const parsedData = TokenLimitResponseSchema.parse(data)
    return parsedData
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })
      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    throw e
  }
}

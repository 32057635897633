import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ItemNode } from '../schemas/files-drive-schema'
import { useAppSelector } from '@/store/store-hooks'
import { selectCurrentFolder } from '../store/files-drive.selectors'
import { RootState } from '@/store/store'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'

interface FileDriveNavigationProps {
  isRootFolder: boolean
}

export default function FileDriveNavigation({ isRootFolder }: FileDriveNavigationProps) {

  const location = useLocation()
  const navigate = useNavigate()

  // Extracting pathname for easier manipulation
  const { pathname } = location
  // Splice by five to consider `file-drive-2.0` -- remove when file-drive-2.0 is ready
  const baseDriveLocationPath = pathname.split('/').slice(0, 5).join('/')

  // Determine the drive label
  const { driveName } = useParams()
  const driveNameLabel = driveName === 'org-drive' ? 'Shared Drive' : 'My Drive'


  //Get current folder data from state
  const currentFolder = useAppSelector((state: RootState) => selectCurrentFolder(state))


  const handleClickLocation = (folderId: string) => {
    if (folderId === '') {
      navigate(baseDriveLocationPath) // navigate back to the root drive path
    } else {
      navigate(`${baseDriveLocationPath}/${folderId}`)
    }
  }


  const renderBreadCrumbs = (item: ItemNode) => {

    const paths = driveNameLabel.concat(`/${item.folder_path}/${item.name}`).split('/').filter(Boolean)

    return paths.map((segment, index) => {
      const isLast = index === paths.length - 1
      const isRoot = index === 0
      const folderId = isRoot ? '' : item.id

      const textClasses = 'font-semibold leading-6 text-brand-neutral-900 text-sm'
      const clickableClasses = !isLast ? 'underline text-brand-800 cursor-pointer' : ''
      const combinedClasses = `${textClasses} ${clickableClasses}`.trim()

      return (
        <span key={index}>
          <span
            className={combinedClasses}
            onClick={!isLast ? () => handleClickLocation(folderId) : undefined}
          >
            {segment}
          </span>
          {!isLast && ' > '}
        </span>
      )
    })
  }

  return (
    <div className="px-4 pt-4 mb-1">
      {!currentFolder && !isRootFolder ? (
        // Show loading only if we have no folder and we're not at the root
        <div className="inline-block">
          <CircularProgressContinuousSized size={12} thickness={2} />
        </div>
      ) : isRootFolder ? (
        // Show drive label if we're at the root
        <div className="inline-block font-semibold leading-6 text-brand-neutral-900 text-sm">
          {driveNameLabel}
        </div>
      ) : (
        // Otherwise, we must have a current folder, so render its breadcrumbs
        currentFolder && (
          <div className="inline-block font-semibold leading-6 text-brand-neutral-900 text-sm">
            {renderBreadCrumbs(currentFolder)}
          </div>
        )
      )}
    </div>

  )
}

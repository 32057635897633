import { RootState } from '@/store/store'

/**
 * Select: Conversation WebSocket Connected
 * Returns a boolean indicating if the conversation has an open WebSocket connection.
 */
export const selectConversationWebSocketConnected = (state: RootState, props: { conversationId: string }): boolean => {
  const connectionState = state.ws2State.connections[props.conversationId]
  return Boolean(connectionState?.connected)
}

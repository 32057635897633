import postCopySampleFile from '../fetch/post-copy-sample-file'

/**
 * Copy Sample File Hook
 * Hook to copy sample file to user's bucket
 * @returns {copySampleFile} function to trigger the sample file copy
 */
export function useCopySampleFile() {
  /**
   * Copy Sample File
   *
   * Handles:
   * 1. Send the copy request to the server
   * 2. Handle success/error callbacks
   *
   * @param onSuccess Optional callback for successful copy
   * @param onError Optional callback for copy errors
   */
  const copySampleFile = async (onSuccess?: () => void, onError?: (e: any) => void) => {
    try {
      console.log('Copying sample file')

      // Submit the copy request
      const response = await postCopySampleFile()

      // Call the onSuccess callback if provided
      if (onSuccess) onSuccess()

      return response
    } catch (e) {
      // Call the onError callback if provided
      if (onError) onError(e)
      throw e
    }
  }

  return { copySampleFile }
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { kCreateOrganization, kPaymentsSurveyPath } from '@/constants/constants-api-paths'
import { prepareRtkQueryHeaders, transformRtkQueryResponse } from '@/store/apis/rtk-query-utils'
import { OrganizationResponse, OrganizationResponseSchema, Survey, SurveyResponseSchema, SurveyRequestResponse } from '@/onboarding/schema/onboarding.schema.ts'

export type OnboardingState = {
  currentStep: number
  steps: OnboardingStep[]
  fetchingOrganization: boolean
  surveyResponse: Survey
}

export type OnboardingStep = {
  stage: number
  stageLabel: string
  title: string
  description: string
  isCompleted: boolean
  href: string
}

const steps: OnboardingStep[] = [
  {
    stage: 1,
    stageLabel: 'Welcome!',
    title: 'Welcome to Paxton',
    description: 'Please answer a few questions to help Paxton personalize your experience.',
    isCompleted: false,
    href: '/onboarding/welcome',
  },
  {
    stage: 2,
    stageLabel: 'Account Setup',
    title: 'Create your account',
    description: 'Invite your team members to join your organization.',
    isCompleted: false,
    href: '/onboarding/account-setup',
  },
  {
    stage: 3,
    stageLabel: 'Tell us about yourself',
    title: 'How do you plan to use Paxton?',
    description: "We'll use this information to personalize your experience.",
    isCompleted: false,
    href: '/onboarding/tell-us-about-yourself',
  },
]

const initialState: OnboardingState = {
  currentStep: 1,
  steps,
  fetchingOrganization: false,
  surveyResponse: {
    role: '',
    primaryUse: '',
    legalResearchTools: '',
    biggestChallenge: '',
    usageFrequency: '',
  },
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    advance: (state) => {
      state.currentStep += 1
      // Mark all steps before the new current step as completed
      state.steps = state.steps.map((step) => ({
        ...step,
        isCompleted: step.stage < state.currentStep,
      }))
    },
    setFetchingOrganization: (state, action: PayloadAction<boolean>) => {
      state.fetchingOrganization = action.payload
    },
    setSurveyResponse: (state, action: PayloadAction<{ responseKey: keyof Survey; responseValue: string }>) => {
      state.surveyResponse[action.payload.responseKey] = action.payload.responseValue
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      const newStep = action.payload
      if (newStep >= 1 && newStep <= state.steps.length) {
        state.currentStep = newStep
        // Mark all steps before the new current step as completed
        state.steps = state.steps.map((step) => ({
          ...step,
          isCompleted: step.stage < newStep,
        }))
      }
    },
  },
})

export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: fetchBaseQuery({ baseUrl: kPaxtonAppApiBaseUrl(), prepareHeaders: async (headers) => await prepareRtkQueryHeaders(headers) }),
  endpoints: (builder) => ({
    createOrganization: builder.mutation<OrganizationResponse, { name: string; seats: number; users: string[]; billing_frequency: 'annual' | 'monthly' }>({
      query: ({ name, seats, users, billing_frequency }) => ({
        url: kCreateOrganization,
        method: 'POST',
        body: { organization: { name, seats, invites: users.map((user) => ({ invitee_email: user })), billing_frequency } },
      }),
      transformResponse: (response) => {
        const validatedResponse = OrganizationResponseSchema.safeParse(response)
        if (!validatedResponse.success) {
          throw new Error(validatedResponse.error.message)
        }
        return validatedResponse.data as OrganizationResponse
      },
      // Capture errors to Sentry
      transformErrorResponse: async (baseQueryReturnValue, meta, arg) => await transformRtkQueryResponse(baseQueryReturnValue, meta, arg),

      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setFetchingOrganization(true))
          const { data } = await queryFulfilled
          if (data) {
            dispatch(setFetchingOrganization(false))
          }
        } catch (error) {
          console.log('Error validating organization response', error)
        }
      },
    }),
    postSurveyResponse: builder.mutation<SurveyRequestResponse, Survey>({
      query: (survey) => ({
        url: kPaymentsSurveyPath,
        method: 'POST',
        body: {
          role: survey.role,
          primary_use: survey.primaryUse,
          legal_research_tools: survey.legalResearchTools,
          biggest_challenge: survey.biggestChallenge,
          usage_frequency: survey.usageFrequency,
        },
      }),
      transformResponse: (response) => {
        const validatedResponse = SurveyResponseSchema.safeParse(response)
        if (!validatedResponse.success) {
          throw new Error(validatedResponse.error.message)
        }
        return validatedResponse.data as SurveyRequestResponse
      },
      // Capture errors to Sentry
      transformErrorResponse: async (baseQueryReturnValue, meta, arg) => await transformRtkQueryResponse(baseQueryReturnValue, meta, arg),
    }),
  }),
})

export const { advance, setFetchingOrganization, setSurveyResponse, setCurrentStep } = onboardingSlice.actions

export const { useCreateOrganizationMutation, usePostSurveyResponseMutation } = onboardingApi

export default onboardingSlice.reducer

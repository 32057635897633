import postCreateSurveyResponse from '../fetch/post-create-survey-response'
import { OnboardingSurveyRequest, OnboardingSurveyResponse } from '../schema/onboarding.schema'

/**
 * Create Survey Hook
 * Hook to submit the onboarding survey response
 * @returns {createSurvey} function to submit the survey response
 */
export function useCreateSurvey() {
  /**
   * Create Survey
   *
   * Handles:
   * 1. Send the survey response to the server
   * 2. Handle success/error callbacks
   *
   * @param surveyData The survey data to submit
   * @param onSuccess Optional callback for successful submission
   * @param onError Optional callback for submission errors
   */
  const createSurvey = async (
    surveyData: {
      firstName: string
      lastName: string
      areaOfPractice: string
      professionalRole: string
      firmSize: string
      rankingFirstChoice: string
      rankingSecondChoice?: string
      rankingThirdChoice?: string
      paxtonReferrer: string
      mediaSource?: string
    },
    onSuccess?: () => void,
    onError?: (e: any) => void
  ) => {
    try {
      console.log('Creating survey response')

      // Transform the camelCase form data to snake_case for the API
      const apiData: OnboardingSurveyRequest = {
        first_name: surveyData.firstName,
        last_name: surveyData.lastName,
        area_of_practice: surveyData.areaOfPractice,
        professional_role: surveyData.professionalRole,
        firm_size: surveyData.firmSize,
        planned_use_first_choice: surveyData.rankingFirstChoice,
        planned_use_second_choice: surveyData.rankingSecondChoice,
        planned_use_third_choice: surveyData.rankingThirdChoice,
        paxton_referrer: surveyData.paxtonReferrer,
        social_media_source: surveyData.mediaSource,
      }

      // Submit the survey response
      const response: OnboardingSurveyResponse = await postCreateSurveyResponse(apiData)

      // Call the onSuccess callback if provided
      if (onSuccess) onSuccess()

      return response
    } catch (e) {
      // Call the onError callback if provided
      if (onError) onError(e)
      throw e
    }
  }

  return { createSurvey }
}

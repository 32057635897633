import { selectConversationFeature, selectShowMessageLoadingBubble } from '@/chat-common/store/chat-v2.selectors'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { RootState } from '@/store/store'
import { useAppSelector } from '@/store/store-hooks'
import { getBrandName } from '@/util/enterprise'
import { useEffect, useState } from 'react'
import SkeletonLoader from './skeleton-loader'
import { selectAgentConversationExists } from '@/agent/conversations/store/selectors'
import { selectShowEventLoadingBubble as selectShowEventLoadingBubble } from '@/agent/events/store/selectors'

enum ChatLoadingStatusMessage {
  findingSources = 'Finding relevant sources...',
  analyzingQuery = 'Analyzing query...',
  reviewingSources = 'Reviewing relevant sources...',
  analyzingSources = 'Analyzing relevant sources...',
  generatingResponse = 'Generating response...',
  draftingResponse = 'Drafting response...',
  reviewingContract = 'Reviewing contract...',
  compilingSummary = 'Compiling summary and suggestions...',
  generatingSummary = 'Generating summary...',
}

type ChatLoadingBubbleProps = {
  conversationId: string
}

export default function ChatLoadingBubble(props: ChatLoadingBubbleProps) {
  const { conversationId } = props

  // Redux State Selectors

  // Determine conversation feature
  const isAgentConversation = useAppSelector((state: RootState) => selectAgentConversationExists(state, { conversationId: conversationId }))
  const legacyConversationFeature = useAppSelector((state: RootState) => selectConversationFeature(state, { chatId: conversationId }))

  // Determine if the loading bubble should be shown based on the conversation type
  const showEventLoadingBubble = useAppSelector((state: RootState) => selectShowEventLoadingBubble(state, { conversationId }))
  const showMessageLoadingBubble = useAppSelector((state: RootState) => selectShowMessageLoadingBubble(state, { chatId: conversationId }))
  const showAgentConversationLoadingBubble = isAgentConversation && showEventLoadingBubble
  const showLegacyConversationLoadingBubble = !isAgentConversation && showMessageLoadingBubble

  const showLoadingBubble = showAgentConversationLoadingBubble || showLegacyConversationLoadingBubble

  // Loading message state
  const [loadingMessage, setLoadingMessage] = useState<string>('Generating response...')

  let feature: ChatV2Feature

  if (isAgentConversation) {
    feature = ChatV2Feature.agent
  } else {
    feature = legacyConversationFeature
  }

  // Messages cycle
  let timeoutId1: NodeJS.Timeout | null = null
  let timeoutId2: NodeJS.Timeout | null = null

  // Message header
  const messageHeader = getBrandName()

  const draftingTimeoutLength = 2000
  const assistantTimeoutLength = 3500
  const contractAnalysisLength = 7500
  const standardTimeoutLength = 15000

  function triggerLoadingMessagesCycle() {
    // console.log('Triggering loading messages cycle')
    switch (feature) {
      case ChatV2Feature.drafting:
        setLoadingMessage(ChatLoadingStatusMessage.analyzingQuery)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatLoadingStatusMessage.draftingResponse)
        }, draftingTimeoutLength)
        break
      case ChatV2Feature.contractanalysis:
        setLoadingMessage(ChatLoadingStatusMessage.reviewingContract)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatLoadingStatusMessage.compilingSummary)
        }, contractAnalysisLength)
        break
      case ChatV2Feature.documentSummarization:
        setLoadingMessage(ChatLoadingStatusMessage.analyzingQuery)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatLoadingStatusMessage.generatingSummary)
        }, standardTimeoutLength)
        break
      case ChatV2Feature.assistant:
      case ChatV2Feature.agent:
        setLoadingMessage(ChatLoadingStatusMessage.analyzingQuery)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatLoadingStatusMessage.reviewingSources)
          timeoutId2 = setTimeout(() => {
            setLoadingMessage(ChatLoadingStatusMessage.generatingResponse)
          }, assistantTimeoutLength)
        }, assistantTimeoutLength)
        break
      default:
        setLoadingMessage(ChatLoadingStatusMessage.findingSources)
        timeoutId1 = setTimeout(() => {
          setLoadingMessage(ChatLoadingStatusMessage.analyzingSources)
          timeoutId2 = setTimeout(() => {
            setLoadingMessage(ChatLoadingStatusMessage.generatingResponse)
          }, standardTimeoutLength)
        }, standardTimeoutLength)
        break
    }
  }

  function cancelLoadingMessagesCycle() {
    if (timeoutId1) clearTimeout(timeoutId1)
    if (timeoutId2) clearTimeout(timeoutId2)

    // Reset timeout IDs to null
    timeoutId1 = null
    timeoutId2 = null

    // Optionally, reset the loading message
    setLoadingMessage('')
  }

  // Start the loading message cycle if the messageId changes
  useEffect(() => {
    triggerLoadingMessagesCycle()

    return () => {
      cancelLoadingMessagesCycle()
    }
  }, [showMessageLoadingBubble, showEventLoadingBubble])

  return (
    <>
      {showLoadingBubble && (
        <div className={`p-2 rounded-lg text-base whitespace-pre-wrap bg-white transition ease-in-out`}>
          <p className={`font-bold text-xl mb-1`}>{messageHeader}</p>
          <p className="animate-pulse text-neutral-600 text-sm leading-6">{loadingMessage}</p>
          <SkeletonLoader />
        </div>
      )}
    </>
  )
}

import { FileDriveItemsSchema, FilesDriveResponse, NodeType } from '../schemas/files-drive-schema'
import { response } from './mock/mock-response-all'

// -> Remove when staging is returning new structure like mock to enable real API call.

// import { nanoid } from 'nanoid'
// import { FileDriveItemsSchema, FilesDriveResponse } from '../schemas/files-drive-schema'
// import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
// import { getAuth } from 'firebase/auth'
// import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
// import * as Sentry from '@sentry/browser'

// /**
//  * Get all files for the authenticated user
//  * Sends a GET request to the backend to retrieve the user's files
//  * @returns A promise that resolves to an array of files and folders
//  * @throws An error if the response status is not OK
//  */
// export async function fetchAllItemsByFolder(folderId: string | null): Promise<FilesDriveResponse> {
//   const sentry_transaction_id = nanoid()

//   try {
//     const token = await getAuth().currentUser?.getIdToken(true)

//     if (!token) throw Error(kErrorCodeAuthError)

//     const apiUrl = kPaxtonAppApiBaseUrl() + `/api/v1/files/` + (folderId ? `?folder_id=${folderId}` : '')

//     const requestOptions: RequestInit = {
//       headers: {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`,
//       },
//       method: 'GET',
//     }

//     const response = await fetch(apiUrl, requestOptions)
//     const data = await response.json()

//     const validatedData = FileDriveItemsSchema.safeParse(data)

//     if (!validatedData.success) {
//       throw new Error(`Failed to validate response: ${validatedData.error.message}`)
//     }

//     return { ...data }
//   } catch (e) {
//     console.error(e)
//     Sentry.withScope((scope) => {
//       scope.setTags({ transaction_id: sentry_transaction_id })
//       Sentry.captureException(e, {
//         extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
//       })
//     })
//     throw e
//   }
// }
// <-

export async function fetchAllItemsByFolder(folderId: string | null): Promise<FilesDriveResponse> {
  // Mock call - To see items with different status!
  console.log('Mock fetching items...', folderId)

  // -> Filter and formatting the response based on the folderId - ONLY FOR MOCK
  const rootFolderId = response.folders.find((item) => !item.parent_id)?.id

  const filtered = [...response.folders, ...response.files].filter((item) => item.parent_id === folderId || item.parent_id === rootFolderId)

  const formatted = {
    folders: filtered.filter((item) => item.type === NodeType.FOLDER),
    files: filtered.filter((item) => item.type === NodeType.FILE),
  }
  // <-

  const validatedData = FileDriveItemsSchema.safeParse(formatted)
  if (!validatedData.success) {
    throw new Error(`Failed to validate response: ${validatedData.error.message}`)
  }

  return { ...formatted }
}

import { ChatV2Message } from '@/chat-common/schemas/chat-v2.schemas'
import { ChatV2Conversation, ChatV2Feature } from '../../store/chat-v2.slice'

type ChatUserQueryBubbleProps = {
  conversation: ChatV2Conversation
  message: ChatV2Message
}

export default function ChatUserQueryPrintableBubble(props: ChatUserQueryBubbleProps) {
  const { conversation, message } = props
  const { metadata, text } = message
  const trimmedText = text.trim()
  let messageHeader = 'You'
  if (metadata.feature == ChatV2Feature.contractanalysis) {
    const fileName = conversation.currentSource?.file_path?.split('/').pop()
    messageHeader = fileName ?? 'Contract Analysis'
  }

  return (
    <div>
      <h2>{messageHeader}</h2>
      <p>{trimmedText}</p>
    </div>
  )
}

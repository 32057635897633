import { useRef } from 'react'
import { ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import ChatWindow from '@/chat-common/components/chat-window'
import ErrorComponent from '@/components/error/error-component'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { RootState } from '@/store/store'
import debounce from 'lodash.debounce'
import {
  selectFeatureMostRecentNewChat,
  selectVisibleReferenceUsesPDFRenderer,
  selectConversationVisibleReferenceExists,
} from '@/chat-common/store/chat-v2.selectors'
import { getScreenSize, ScreenSize } from '@/util/screen-size'
import { useCreateNewChat } from '@/chat-common/new_chat/hooks/use-start-new-chat'
import { CircularProgress } from '@mui/material'
import ContentViewerColumn from '@/chat-common/components/content-viewer/content-viewer-column'
import ContentViewerSlideover from '@/chat-common/components/content-viewer/content-viewer-slideover'
import { selectAgentConversationContentViewerVisible, selectAgentConversationExists } from '@/agent/conversations/store/selectors'
import AgentChatWindow from '@/agent/chat_window/AgentChatWindow'
import { useCreateNewAgentChat } from '@/agent/conversations/hooks/use-new-agent-chat'

/**
 * Chat V2 Page
 * Main templating page for Chat
 * @returns
 */
export default function ChatV2Page() {
  const { chatFeature, chatId } = useParams()
  const { createNewChat } = useCreateNewChat()
  const { createNewAgentChat } = useCreateNewAgentChat()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  // Column width ref
  const referenceColumnRef = useRef<HTMLDivElement | null>(null)

  // Screen Size enum can be used for conditional rendering and styles
  const [screenSize, setScreenSize] = useState<ScreenSize>(getScreenSize())

  // Check if this is a valid feature
  const validFeature: boolean = Object.values(ChatV2Feature).includes(chatFeature as ChatV2Feature)

  // Whether the initial load has been completed for both chat types
  const legacyInitialChatListLoadComplete = useAppSelector((state: RootState) => state.chatV2State.initialLoadComplete)
  const agentInitialChatListLoadComplete = useAppSelector((state: RootState) => state.agentConversationsState.initialLoadComplete)

  // Check if this conversation is an agent conversation
  const isLegacyConversation = useAppSelector((state: RootState) => !!state.chatV2State.conversations[chatId ?? ''])
  const isAgentConversation = useAppSelector((state: RootState) => selectAgentConversationExists(state, { conversationId: chatId }))

  // Determine which chat window to show based on whether it's an agent or legacy conversation, once the initial load has completed
  const showAgentChat = isAgentConversation && agentInitialChatListLoadComplete
  const showLegacyChat = isLegacyConversation && legacyInitialChatListLoadComplete
  // Show error if the chat id does not belong to either type
  const showError = !showAgentChat && !showLegacyChat && legacyInitialChatListLoadComplete && agentInitialChatListLoadComplete

  // Check if this conversation has a "New chat"
  const featureMostRecentNewChat = useAppSelector((state: RootState) => selectFeatureMostRecentNewChat(state, { chatFeature: chatFeature as ChatV2Feature }))

  // Visible reference for legacy chats
  const conversationHasVisibleReference = useAppSelector((state: RootState) => selectConversationVisibleReferenceExists(state, { chatId }))
  const referenceUsesPdfRenderer = useAppSelector((state: RootState) => selectVisibleReferenceUsesPDFRenderer(state, { chatId }))

  // Check if the content viewer for agent conversations (to display a reference or artifact) is visible
  const isAgentContentViewerVisible = useAppSelector((state: RootState) => selectAgentConversationContentViewerVisible(state, { conversationId: chatId }))

  // For conditional rendering of the content viewer column/slideover if there's a visible reference or artifact
  const hasVisibleReferenceOrArtifact = conversationHasVisibleReference || isAgentContentViewerVisible

  // SCREEN RESIZE HANDLER
  // Functions that should run every time the screen size changes
  // Debounced to reduce the number of calls on resize
  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      // Set the screen size state
      setScreenSize(getScreenSize())
    }, 50)

    window.addEventListener('resize', debouncedHandleResize)

    debouncedHandleResize() // Initial calculation

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
      debouncedHandleResize.cancel() // Cancel any pending debounced calls
    }
  }, [])

  /**
   * IF pending chat id in URL
   * THEN: Strip the chat id from the URL and redirect to the stripped URL
   *
   * Old versions of the app created "pen-" chat ids for pending chats on the client
   * side. This no longer happens as all new chats are first made on the server.
   */
  useEffect(() => {
    if (!chatId) return

    // Check if the chat id is a pending chat id
    if (chatId.startsWith('pen-')) {
      // Navigate to this page without the pending chat id
      navigate(`/dashboard/chat/${chatFeature}`)
    }
  }, [chatFeature, chatId, navigate])

  /**
   * HANDLE NO CHAT ID IN URL
   * IF: The initial load has been completed
   * AND: The feature is valid
   * AND: There is no chat ID in the URL
   *
   * THEN:
   * IF: There is a featureMostRecentNewChat, redirect to that chat
   * ELSE: Create a new chat
   */
  useEffect(() => {
    if (!legacyInitialChatListLoadComplete || !agentInitialChatListLoadComplete) return
    if (!validFeature) return
    if (chatId) return

    // If there's a most recent new chat, redirect to that chat
    if (featureMostRecentNewChat) {
      navigate(`/dashboard/chat/${chatFeature}/${featureMostRecentNewChat.id}`)
      return
    } else {
      console.log('Creating new chat...')

      // Create new agent chat (assistant and agent are both now agent for new chats)
      if (chatFeature === ChatV2Feature.agent || chatFeature === ChatV2Feature.assistant) {
        createNewAgentChat(true)
      } else {
        createNewChat(chatFeature as ChatV2Feature, true)
      }
    }
  }, [
    chatId,
    validFeature,
    chatFeature,
    createNewChat,
    createNewAgentChat,
    featureMostRecentNewChat,
    navigate,
    dispatch,
    legacyInitialChatListLoadComplete,
    agentInitialChatListLoadComplete,
  ])

  // IF INVALID FEATURE: show 404 error
  if (!validFeature || !chatFeature) {
    console.error(`Invalid chat. Feature: ${chatFeature}, ID: ${chatId}`)

    // Return 404 error component
    return <ErrorComponent code={'404'} title={'Not Found'} message={"We can't find what you're looking for."} />
  }

  // If there's no chatId yet, return a loading indicator while a new chat is being created
  if (!chatId) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <CircularProgress size={24} thickness={5} />
      </div>
    )
  }

  // Whether to show a wider source area (for PDF renderer)
  const wideSourcePanel = referenceUsesPdfRenderer && screenSize != ScreenSize.LARGE

  // console.log(`Rendering ${chatFeature} conversation id: ${chatId}`)

  return (
    <>
      {/* Display 1 or 2 columns depending on whether showReference is true and screen size */}
      {/* PDF Renderer uses 2/5 : 3/5 split, otherwise 1/2 : 1/2 */}
      <div className={`flex flex-grow gap-x-2`}>
        <div className={`${wideSourcePanel ? 'w-2/5' : 'w-1/2'} mt-12 lg:mt-0 mx-0 lg:mx-0 flex-grow flex justify-center`}>
          {/* Show the correct chat window */}
          {showAgentChat && <AgentChatWindow conversationId={chatId} />}
          {showLegacyChat && <ChatWindow chatId={chatId} chatFeature={chatFeature as ChatV2Feature} />}
          {/* Show error if the chat id does not belong to either type */}
          {showError && <ErrorComponent code={'404'} title={'Chat not found'} message={`We could not find the chat with id: ${chatId}`} />}
        </div>

        {/* Content viewer as second column - large screens */}
        {hasVisibleReferenceOrArtifact && screenSize !== ScreenSize.SMALL && (
          <div ref={referenceColumnRef} className={`${wideSourcePanel ? 'w-3/5' : 'w-1/2'} mt-12 lg:mt-0 flex flex-grow overflow-hidden`}>
            <ContentViewerColumn conversationId={chatId} />
          </div>
        )}

        {/* Content viewer as slideover - small screens */}
        {hasVisibleReferenceOrArtifact && screenSize === ScreenSize.SMALL && <ContentViewerSlideover open conversationId={chatId} />}
      </div>
    </>
  )
}

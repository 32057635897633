import { getAuth } from 'firebase/auth'
import { saveAs } from 'file-saver'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { kErrorCodeAuthError } from '@/constants/constants-error-codes'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { kArtifactDownloadDocxApiPath } from '@/constants/constants-api-paths'
import { artifactNameFallback } from '@/constants/constants-artifact'

/**
 * Downloads an artifact version as a DOCX file
 */
export async function downloadArtifactAsDocX(artifactVersionId: string) {
  const token = await getAuth().currentUser?.getIdToken(true)
  if (!token) throw new Error(kErrorCodeAuthError)

  // Create transaction id
  const sentry_transaction_id = nanoid()

  try {
    const apiUrl = `${kPaxtonAppApiBaseUrl()}${kArtifactDownloadDocxApiPath.replace('{artifactVersionId}', artifactVersionId)}`

    const requestOptions: RequestInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await fetch(apiUrl, requestOptions)
    if (!response.ok) {
      throw new Error(`Failed to download docx. Status: ${response.status}`)
    }

    // Extract the filename from the Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition')
    let filename = `${artifactNameFallback}.docx` // Fallback filename if not found in header

    if (contentDisposition && contentDisposition.includes('filename=')) {
      const filenameMatch = contentDisposition.match(/filename="(.+)"/)
      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1]
      }
    }

    // Convert the response to a Blob
    const blob = await response.blob()

    // Save the Blob as a file
    saveAs(blob, filename)
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(error, {
        extra: {
          onLine: navigator.onLine,
          cookieEnabled: navigator.cookieEnabled,
          artifactVersionId,
          error: JSON.stringify(error),
        },
      })
    })
    throw error
  }
}

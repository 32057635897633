import { useDispatch, useSelector } from 'react-redux'
import type { TypedUseSelectorHook } from 'react-redux'
import type { RootState, AppDispatch } from './store'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// THESE ARE TEMPLATES FOR THE LISTENER MIDDLEWARE
// YOU CAN COPY AND PASTE THESE INTO EACH LISTENER
// YOU CANNOT USE THESE DIRECTLY BECAUSE YOUR INSTANCE OF THE startAppListener
// MUST BE IMPORTED INTO THE STORE.
//
// 1. CREATE YOUR OWN LISTENER MIDDLEWARE
// 2. IMPORT IT INTO THE STORE

// Type the listener middleware
// https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
// export const appListenerMiddleware = createListenerMiddleware()

/**
 * Function to statically add a listener middleware function at startup
 *
 * Usage:
 * startAppListener({
 *  actionCreator: ConversationListActions.fetchConversations,
 *   effect: async (action, listenerApi) => {
 *     ...
 *  },
 * })
 */
// export const startAppListener = appListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()

/**
 * Function for dynamically adding a typed listener
 *
 * Usage:
 * store.dispatch(
 *   addAppListener({
 *    actionCreator: ConversationListActions.fetchConversations,
 *    effect: async (action, listenerApi) => {
 *     ...
 *  },
 * })
 */
// export const addAppListener = addListener.withTypes<RootState, AppDispatch>()

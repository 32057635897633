import { createListenerMiddleware } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '@/store/store'
import { kConversationListCacheDuration, kConversationRefreshCacheDuration } from '@/chat-common/conversation_list/constants'
import { getAgentConversationList } from '../fetch/get-agent-conversation-list'
import { AgentConversation, AgentConversationsActions } from './slice'
import { getAgentConversationData } from '../fetch/get-agent-conversation-data'
import { AgentEventsActions } from '@/agent/events/store/slice'
import { ArtifactsActions } from '@/artifacts/store/slice'
import { agentResponseMetadataActions } from '@/agent/response_metadata/store/slice'
import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'
import { mapErrorToConversationLoadError } from '@/agent/conversations/util/conversation-load-error-mapper'
import patchGenerateAgentConversationTitle from '../fetch/patch-generate-agent-conversation-title'
import { ArtifactVersionStatusType } from '@/artifacts/schemas'

export const agentConversationsListenerMiddleware = createListenerMiddleware()
const startListener = agentConversationsListenerMiddleware.startListening.withTypes<RootState, AppDispatch>()

/**
 * Fetch Conversations List Action Listener
 * This listener fetches the conversations from the server
 * It checks the cache duration and only fetches if the cache has expired
 * or if the user forces a refresh
 */
startListener({
  actionCreator: AgentConversationsActions.refreshConversationList,
  effect: async (action, listenerApi) => {
    const { forceRefresh } = action.payload

    // Get the existing state
    const state = listenerApi.getState().chatV2State

    // Is loading already?
    const isLoading = state.loading

    // Cache Handling
    const cacheDuration = kConversationListCacheDuration
    const lastFetch = state.lastRefresh
    const cacheExpired = lastFetch === null || Date.now() - lastFetch > cacheDuration

    // Return early if:
    // - the cache has not expired and we are not forcing a refresh
    // - the conversation is already loading
    if ((!cacheExpired && !forceRefresh) || isLoading) {
      return
    }

    // Else, fetch the conversations
    try {
      listenerApi.dispatch(AgentConversationsActions.setLoading(true))
      listenerApi.dispatch(AgentConversationsActions.setError(false))

      // Fetch conversations
      const response = await getAgentConversationList()

      // Sort the the conversations by updated_at, most recent first
      // updated_at is milliseconds since epoch
      const sorted = [...response.conversations].sort((a, b) => {
        return b.updated_at - a.updated_at
      })

      // For each conversation, upsert it into the state
      sorted.forEach((conversation) => {
        listenerApi.dispatch(AgentConversationsActions.upsertConversation(conversation))
      })

      // Set initial load complete
      listenerApi.dispatch(AgentConversationsActions.setInitialLoadComplete(true))
    } catch (e) {
      listenerApi.dispatch(AgentConversationsActions.setError(true))
    } finally {
      // Set loading to false
      listenerApi.dispatch(AgentConversationsActions.setLoading(false))
      // Set the last refresh time
      listenerApi.dispatch(AgentConversationsActions.setLastRefresh(Date.now()))
    }
  },
})

/**
 * Fetch Conversation Data Action Listener
 * This listener fetches the conversation data from the server,
 * and dispatches data to the appropriate slices.
 */
startListener({
  actionCreator: AgentConversationsActions.fetchConversationData,
  effect: async (action, listenerApi) => {
    const { conversationId, forceRefresh } = action.payload

    // Get the existing state
    const state = listenerApi.getState().agentConversationsState
    const currentConversationState: AgentConversation | undefined = state.conversations[conversationId]

    // Is loading already?
    const isLoading = currentConversationState?.isLoading ?? false

    // Cache Handling
    const cacheDuration = kConversationRefreshCacheDuration
    const lastFetch = currentConversationState?.lastRefresh ?? null
    const cacheExpired = lastFetch === null || Date.now() - lastFetch > cacheDuration

    // Return early if:
    // - the cache has not expired and we are not forcing a refresh
    // - the conversation is already loading
    if ((!cacheExpired && !forceRefresh) || isLoading) {
      return
    }

    try {
      listenerApi.dispatch(AgentConversationsActions.setConversationError({ conversationId, loadingError: null }))
      listenerApi.dispatch(AgentConversationsActions.setConversationLoading({ conversationId, isLoading: true }))

      // Fetch conversation data
      const data = await getAgentConversationData(conversationId)

      // Upsert the conversation
      listenerApi.dispatch(AgentConversationsActions.upsertConversation(data.conversation))

      // Upsert events
      if (data.events && data.events.length > 0) {
        listenerApi.dispatch(AgentEventsActions.upsertEvents({ conversationId, events: data.events }))
      }

      // Upsert artifact_versions
      if (data.artifact_versions && data.artifact_versions.length > 0) {
        listenerApi.dispatch(ArtifactsActions.upsertArtifactVersions(data.artifact_versions))

        // Identify generating artifacts and poll for updates
        const generatingArtifactVersions = data.artifact_versions.filter((artifactVersion) => artifactVersion.status === ArtifactVersionStatusType.GENERATING)
        if (generatingArtifactVersions.length > 0) {
          generatingArtifactVersions.forEach((artifactVersion) => {
            listenerApi.dispatch(ArtifactsActions.startArtifactVersionStatusPoll({ artifactVersionId: artifactVersion.id }))
          })
        }
      }

      // Upsert response_metadata
      if (data.response_metadata && data.response_metadata.length > 0) {
        listenerApi.dispatch(agentResponseMetadataActions.upsertResponseMetadataList(data.response_metadata))
      }

      // Set the latest request params in the chat form
      if (data.latest_request_params) {
        listenerApi.dispatch(
          AgentConversationFormActions.setFocusMode({
            conversationId,
            focus_mode: data.latest_request_params.focus_mode,
          })
        )

        listenerApi.dispatch(
          AgentConversationFormActions.addFiles({
            conversationId,
            filePaths: data.latest_request_params.file_paths,
          })
        )
      }
    } catch (error) {
      const loadingError = mapErrorToConversationLoadError(error, conversationId)
      listenerApi.dispatch(AgentConversationsActions.setConversationError({ conversationId, loadingError }))
    } finally {
      listenerApi.dispatch(AgentConversationsActions.setConversationLoading({ conversationId, isLoading: false }))
      listenerApi.dispatch(AgentConversationsActions.setConversationLastRefresh({ conversationId, lastRefresh: Date.now() }))
    }
  },
})

/**
 * Generate Conversation Title Action Listener
 * This listener generates the conversation title for a new conversation
 */
startListener({
  actionCreator: AgentConversationsActions.generateConversationTitle,
  effect: async (action, listenerApi) => {
    const { conversationId } = action.payload
    try {
      await patchGenerateAgentConversationTitle(conversationId)

      listenerApi.dispatch(AgentConversationsActions.refreshConversationList({ forceRefresh: true }))
    } catch (error) {
      console.error('Failed to generate conversation title', error)
    }
  },
})

import ChatListSidebar from '@/chat-common/conversation_list/components/chat-list-sidebar'
import NewChatButton from '@/chat-common/new_chat/components/new-chat-button'
import { Dialog, Transition } from '@headlessui/react'
import { CloseOutlined } from '@mui/icons-material'
import { Fragment } from 'react'

type DashboardMobileConnectionBannerProps = {
  sidebarOpen: boolean
  setSidebarOpen: (open: boolean) => void
}

export default function DashboardMobileChatHistory(props: DashboardMobileConnectionBannerProps) {
  const { sidebarOpen, setSidebarOpen } = props

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-brand-neutral-900/80" />
        </Transition.Child>

        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                  <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                    <span className="sr-only">Close sidebar</span>
                    <CloseOutlined className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-brand-neutral-100 px-2 py-5">
                <div className={`px-2 py-2 w-full`}>
                  <NewChatButton onClickCallback={() => setSidebarOpen(false)} />
                </div>
                <ChatListSidebar onClickCallback={() => setSidebarOpen(false)} />
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

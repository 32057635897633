import { FeedbackThumb } from '@/chat-common/schemas/feedback-schema'
import { z } from 'zod'

// ConfidenceRatingEnum
export enum ConfidenceRatingEnum {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

// AgentResponseMetadataRead Schema
export const AgentResponseMetadataReadSchema = z.object({
  event_id: z.string().uuid(),
  time_to_first_token: z.number(),
  thumb_rating: z.nativeEnum(FeedbackThumb).nullable(),
  thumb_text: z.string().nullable(),
  confidence: z.number().nullable(),
  confidence_label: z.nativeEnum(ConfidenceRatingEnum).nullable(),
})

export type AgentResponseMetadataRead = z.infer<typeof AgentResponseMetadataReadSchema>

import { store } from '@/store/store'

import { paxtonAppApi } from '@/store/apis/paxton-app-api'
import { nullifyData as booleanNullify } from '@/store/slices/boolean-builder-stream-response.slice'
import { nullifyData as auditNullify } from '@/store/slices/document-audit-stream-response.slice'
import { nullifyData as chatUxNullify } from '@/chat-common/store/chat-v2-ux.slice'
import { nullifyData as subscriptionNullify } from '@/routes/dashboard/subscribe/store/subscription.slice'
import { ChatV2Actions } from '@/chat-common/store/chat-v2.slice'

/**
 * Clear State Data
 * Handles clearing of various slices of global state
 */
export async function clearReduxStateData() {
  // Legacy features
  store.dispatch(auditNullify())
  store.dispatch(booleanNullify())

  // Chat UX
  store.dispatch(chatUxNullify())

  // Chat Conversations
  store.dispatch(ChatV2Actions.nullifyData())

  // Subscription
  store.dispatch(subscriptionNullify())

  // Suggested questions
  store.dispatch(paxtonAppApi.util.resetApiState())
}

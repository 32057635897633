import * as Popover from '@radix-ui/react-popover'
import { useState } from 'react'
import { ChatV2QueryMetadataAssistant, ModeChoice } from '@/chat-common/schemas/chat-query-metadata-schema'
import { assistantModes } from '@/chat-common/components/form-source-controls/assistant/assistant-modes'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { RootState } from '@/store/store'
import { selectConversationCurrentSource } from '@/chat-common/store/chat-v2.selectors'
import { chatV2CurrentSourceAssistantSetFocus } from '@/chat-common/store/chat-v2.slice'
import { ArrowDropDown } from '@mui/icons-material' // Material UI dropdown icon
import { AgentConversationFormActions } from '@/agent/chat_form/store/slice'
import { AgentFocusMode } from '@/agent/chat_form/store/schemas'

type ModeSelectorProps = {
  conversationId: string
}

export default function ModeSelector(props: ModeSelectorProps) {
  const { conversationId } = props
  const dispatch = useAppDispatch()

  // Redux selectors
  const agentConversationForm = useAppSelector((state: RootState) => state.agentConversationFormState.forms[conversationId])
  const legacyConversationCurrentSource = useAppSelector((state: RootState) =>
    selectConversationCurrentSource(state, { chatId: conversationId })
  ) as ChatV2QueryMetadataAssistant | null

  // Local state
  const [open, setPopoverOpen] = useState<boolean>(false)

  // Get the current mode, fallback to default mode if not set
  const focusMode = agentConversationForm?.requestParams.focus_mode ?? legacyConversationCurrentSource?.focus_mode ?? ModeChoice.ASSISTANT

  // Extract the selected option and its icon
  const selectedOption = assistantModes.find((option) => {
    return agentConversationForm ? option.agentValue === focusMode : option.legacyValue === focusMode
  })
  const IconComponent = selectedOption?.icon

  // Handle agent assistant selection
  const handleAgentAssistantFocusMode = (newMode: AgentFocusMode) => {
    dispatch(AgentConversationFormActions.setFocusMode({ conversationId: conversationId, focus_mode: newMode }))
    setPopoverOpen(false)
  }

  // Handle legacy assistant selection
  const handleLegacyFocusMode = (newMode: ModeChoice) => {
    dispatch(chatV2CurrentSourceAssistantSetFocus({ conversationId: conversationId, focusMode: newMode }))
    setPopoverOpen(false)
  }

  return (
    <div className="flex flex-nowrap items-center bg-brand-neutral-50 border border-brand-neutral-400 rounded-lg overflow-hidden">
      <span className="px-3 py-2 font-medium text-brand-neutral-700 text-xs bg-brand-neutral-50">Mode</span>
      <Popover.Root open={open} onOpenChange={setPopoverOpen}>
        <Popover.Trigger asChild>
          <button
            className="cursor-pointer flex items-center justify-between border-l border-brand-neutral-400 bg-brand-50 px-2 py-2 text-sm font-medium transition-all"
            aria-label="Choose the mode of your query"
            title="Mode"
          >
            {IconComponent && <IconComponent fontSize="small" className="mr-1 text-brand-500" />}
            <span className="text-xs font-medium text-brand-500">{selectedOption?.title}</span>
            <ArrowDropDown className="ml-2 text-brand-neutral-700" fontSize="small" />
          </button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            onEscapeKeyDown={() => setPopoverOpen(false)}
            onInteractOutside={() => setPopoverOpen(false)}
            side="bottom"
            align="start"
            alignOffset={-58}
            sideOffset={-45}
            className="PopoverContent z-50 min-w-[260px] mr-3 px-2 py-2 bg-brand-neutral-50 rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.75),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.5)]"
          >
            <div className="flex flex-col gap-1">
              <h2 className="text-[14px] mt-1 text-center font-semibold text-brand-neutral-600">Mode</h2>
              {assistantModes.map((option, i) => {
                const value = agentConversationForm ? option.agentValue : option.legacyValue

                return (
                  <button
                    key={value + '_' + i}
                    className={`flex p-2 items-center text-left rounded-md hover:bg-brand-neutral-100 ${
                      value === focusMode ? 'text-brand-500' : 'text-brand-neutral-700'
                    }`}
                    onClick={() => {
                      if (agentConversationForm) {
                        handleAgentAssistantFocusMode(value as AgentFocusMode)
                      } else {
                        handleLegacyFocusMode(value as ModeChoice)
                      }
                    }}
                    aria-pressed={value === focusMode}
                  >
                    {option.icon && <option.icon />}
                    <div>
                      <div className="ml-2 text-[12px] font-semibold w-[75px]">{option.title}</div>
                      <p className="ml-2 text-[12px]">{option.description}</p>
                    </div>
                  </button>
                )
              })}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  )
}

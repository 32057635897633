import React from 'react'
import { ReactElement } from 'react'
import ChatFootnotePopoverPrintable from '../components/chat-footnote-popover-printable'
import { ChatV2MessageReferenceType } from '../store/chat-v2.slice'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { rehypeCustomFootnotes } from '@/util/rehype-custom-plugins/rehype-custom-footnotes'
import { ChatV2Message } from '../schemas/chat-v2.schemas'

/**
 * Format Response String
 * Isolates the response footnotes as clickable buttons that trigger a popover
 * @param response
 * @param onFootnoteClick
 * @returns
 */
export default function chatV2FormatResponseStringPrintable(message: ChatV2Message, references:  Record<string, ChatV2MessageReferenceType>): ReactElement {

  // Footnote numbers source of truth from metadata
  const actualFootnotes = Object.keys(references)

  // Trim whitespace from the response
  const trimmedResponse = message.text.trim()

  function extractText(node: React.ReactNode): string {
    let text = ''
    React.Children.forEach(node, (child) => {
      if (typeof child === 'string') {
        text += child
      } else if (React.isValidElement(child) && child.props.children) {
        text += extractText(child.props.children)
      }
    })
    return text
  }
  return (
    <Markdown
      className={'markdown-bubble-container max-w-3xl'}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw, rehypeCustomFootnotes]}
      components={{
        // This span override is used to render the footnote span as a ChatFootnotePopover component
        // This component is used to render a clickable button that triggers a popover
        // Markdown is unable to accept react components so we must manually place our ChatFootnotePopover component
        // The replacement is done by checking if the span has a data attribute 'data-footnote'
        // Spans with the 'data-footnote' attribute come from the rehypeCustomFootnotes plugin
        // Go to src/chat-common/util/chat-remark-footnote.tsx to see how the plugin works
        div: ({ children, ...props }) => {
          // map the children and if there is a child string that contains any more than 2 '/n' for example
          // "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n" then repalce it with a max of a 2 new line '\n\n'
          const newLineRegex = /\n{2,}/g
          const filteredChildren = Array.isArray(children)
            ? children.map((child) => {
                if (typeof child === 'string') {
                  return child.replace(newLineRegex, '\n\n')
                }
                return child
              })
            : children

          return <div {...props}>{filteredChildren}</div>
        },
        li: ({ children, ...props }) => {
          // filter the children and if there is a child string of '\n' then remove it
          const filteredChildren = Array.isArray(children) ? children.filter((child) => child !== '\n') : children

          return <li {...props}>{filteredChildren}</li>
        },
        span: ({ ...props }) => {
          if ('data-footnote' in props) {
            const footnoteIndex = props['data-footnote'] as string
            const isValid = actualFootnotes.includes(footnoteIndex)
            if (!isValid) {
              return (
                <span key={`${footnoteIndex}-invalid`} className="text-brand-neutral-500">
                  {props.children}
                </span>
              )
            }

            const footnoteText = extractText(props.children) // Extract text from children

            return <ChatFootnotePopoverPrintable footnoteText={footnoteText} highlight={false} />
          }

          // Render regular spans as usual if they don't have the footnote data attribute
          return <span {...props}>{props.children}</span>
        },
      }}
    >
      {trimmedResponse}
    </Markdown>
  )
}

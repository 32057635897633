import { RootState } from '@/store/store'
import { createSelector, createSelectorCreator, lruMemoize } from '@reduxjs/toolkit'
import { ConversationHeader } from './schema'

/**
 * Selector: Conversations As Headers Array
 * Selects the list of conversations as an array of conversation headers
 * Converts the Record<string, ChatV2Conversation> to Array<ConversationHeader>
 */
const selectConversationRecordsAsHeadersArray = createSelector(
  (state: RootState) => state.chatV2State.conversations,
  (conversations) => {
    if (!conversations || Object.keys(conversations).length === 0) {
      return []
    }

    // Convert the conversations object into an array and map to headers
    const conversationArray: ConversationHeader[] = Object.values(conversations)
      .map((conversation) => ({
        id: conversation.id,
        title: conversation.title,
        feature: conversation.feature,
        modified_on: conversation.modified_on,
      }))
      // Sort the conversations by modified_on, most recent first
      .sort((a, b) => b.modified_on - a.modified_on)

    return conversationArray
  }
)

/**
 * Conversation Headers Equality
 * Purpose: Avoids re-rendering every time a ChatV2Conversation changes in the Record.
 *          We only want to re-render when header values change.
 *
 * What triggers a re-render
 * - If the length of the previous and next lists are different (conversation added or removed)
 * - If the modified_on value of any conversation changes
 * - If the title of any conversation changes
 */
const conversationHeaderListEquality = (prevList: ConversationHeader[], nextList: ConversationHeader[]): boolean => {
  // If the lengths are different, they are not equal
  if (prevList.length !== nextList.length) return false

  // Compare each conversation
  for (let i = 0; i < prevList.length; i++) {
    const prev = prevList[i]
    const next = nextList[i]

    // If the modified_on values are different, they are not equal
    if (prev.modified_on !== next.modified_on) return false

    // If the titles are different, they are not equal
    if (prev.title !== next.title) return false
  }

  return true
}

/**
 * Selector Creator
 * A custom selector creator that uses the custom equality operator
 */
const createConversationListDataSelector = createSelectorCreator(lruMemoize, conversationHeaderListEquality)

/**
 * Selector: ConversationListData
 *
 * @returns {ConversationHeader[]} the conversation list data
 */
export const selectListOfConversationHeaders = createConversationListDataSelector(
  selectConversationRecordsAsHeadersArray,
  (conversations) => {
    if (!conversations || Object.keys(conversations).length === 0) {
      return []
    }

    // Convert the conversations object into an array
    const conversationArray: ConversationHeader[] = []
    Object.values(conversations).forEach((conversation) => {
      conversationArray.push({
        id: conversation.id,
        title: conversation.title,
        feature: conversation.feature,
        modified_on: conversation.modified_on,
      })
    })

    // Sort the conversations by modified_on, most recent first
    conversationArray.sort((a, b) => {
      return b.modified_on - a.modified_on
    })

    return conversationArray
  },
  {
    memoizeOptions: {
      // Custom equality check works on the output of the combination function
      equalityCheck: conversationHeaderListEquality,
    },
  }
)

import { RootState } from '@/store/store'
import { createSelector } from '@reduxjs/toolkit'

/**
 * Select: Conversation Is Over Token Limit
 */
export const selectFilesIsOverTokenLimit = createSelector(
  // Input selector: token limit
  (state: RootState) => state.selectedFilesState.tokenLimit.response,
  // Input selector: selected files token count
  (state: RootState, props: { chatId: string }) => state.selectedFilesState.chatQueryEmbeddingsUsed[props.chatId]?.embeddingsCount ?? 0,
  (tokenLimitResponse, embeddingsCount) => {
    // Calculate the maximum allowable embeddings count based on the token limit and chunk size
    const maxTokens = tokenLimitResponse.max_tokens
    const chunkSize = tokenLimitResponse.chunk_size
    const maxEmbeddings = maxTokens / chunkSize

    // Return true / false if the embeddings count exceeds the limit
    const exceedsLimit = embeddingsCount > maxEmbeddings
    return exceedsLimit
  }
)

import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TokenLimitResponse } from '../schema'
import { defaultMaxTokenResponse } from '@/routes/dashboard/files/files-utils'

export type ChatQueryEmbeddingsUsed = {
  embeddingsCount: number
  loading: boolean
}

export type TokenLimit = {
  response: TokenLimitResponse
  loading: boolean
}

export type SelectedFilesState = {
  tokenLimit: TokenLimit
  chatQueryEmbeddingsUsed: {
    [chatId: string]: ChatQueryEmbeddingsUsed
  }
}

const initialState: SelectedFilesState = {
  tokenLimit: {
    response: defaultMaxTokenResponse,
    loading: false,
  },
  chatQueryEmbeddingsUsed: {},
}

/**
 * Chat V2 Files Slice
 * A slice to hold state for chat files information
 */
export const selectedFilesSlice = createSlice({
  name: 'selectedFilesState',
  initialState,
  reducers: {
    // ============== Listener Middleware Triggers ============== >
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    checkLimits: (_state, _action: PayloadAction<{ conversationId: string; filePath: string }>) => {
      // Handled by listener middleware
    },

    // ============== Slice Actions ============== >
    nullifyData: () => initialState,

    // Set Token Limit Loading
    setTokenLimitLoading: (state, action: PayloadAction<boolean>) => {
      state.tokenLimit.loading = action.payload
    },

    // Set Token Limit Response
    setTokenLimitResponse: (state, action: PayloadAction<TokenLimitResponse>) => {
      state.tokenLimit.response = action.payload
    },

    // Set Chat Used Tokens Loading
    setUsedTokensLoading: (state, action: PayloadAction<{ chatId: string; loading: boolean }>) => {
      const { chatId, loading } = action.payload

      state.chatQueryEmbeddingsUsed[chatId] = {
        ...state.chatQueryEmbeddingsUsed[chatId],
        loading,
      }
    },

    // Set Chat Used Tokens
    setUsedTokens: (state, action: PayloadAction<{ chatId: string; usedTokens: number }>) => {
      const { chatId, usedTokens } = action.payload

      state.chatQueryEmbeddingsUsed[chatId] = {
        ...state.chatQueryEmbeddingsUsed[chatId],
        embeddingsCount: usedTokens,
      }
    },
  },
})

// Actions
export const SelectedFilesActions = selectedFilesSlice.actions

export default selectedFilesSlice.reducer

import { useAppDispatch } from '@/store/store-hooks'
import { chatV2CurrentSourceDocumentSummarizationClearAll, chatV2CurrentSourceDocumentSummarizationToggleSelectedFile } from '@/chat-common/store/chat-v2.slice'
import { SourceSelectionDialog } from './source-selection-dialog'

type SelectionDialogDocumentSummarizationProps = {
  open: boolean
  onClose: (value: boolean) => void
  conversationId: string
}

/**
 * SelectionDialogDocumentSummarization component.
 *
 * This component is used exclusively in the Document Summarization features
 * to allow the user to select documents for the Document Summarization to use by wrapping the SourceSelectionDialog component with the appropriate dispatch functions.
 *
 * @param props - The properties for the SelectionDialogDocumentSummarization component.
 * @returns A JSX element representing the document selection dialog.
 */
export default function SelectionDialogDocumentSummarization(props: SelectionDialogDocumentSummarizationProps) {
  const { open, onClose, conversationId } = props
  const dispatch = useAppDispatch()

  const addFileSelection = (file: string) => {
    dispatch(chatV2CurrentSourceDocumentSummarizationToggleSelectedFile({ conversationId: conversationId, selectedFile: file }))
  }

  const toggleFileSelection = (file: string) => {
    dispatch(chatV2CurrentSourceDocumentSummarizationToggleSelectedFile({ conversationId: conversationId, selectedFile: file }))
  }

  const clearFilesSelection = () => {
    dispatch(chatV2CurrentSourceDocumentSummarizationClearAll({ conversationId: conversationId }))
  }

  return (
    <SourceSelectionDialog
      conversationId={conversationId}
      open={open}
      onClose={onClose}
      allowMultipleSelection={false}
      dispatchFunctions={{
        addFileSelection,
        clearFilesSelection,
        toggleFileSelection,
      }}
    />
  )
}

import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/store-hooks'
import { useEffect, useState } from 'react'
import { CircularProgressContinuousSized } from '@/components/loaders/CircularProgressContinuous'
import { useAnalytics } from '@/analytics/hooks/useAnalytics'
import { AnalyticsEvent } from '@/analytics/schema/events.schema'
import { ChatListSidebarItem, ConversationHeader } from '../schema'
import { ChatV2Actions, ChatV2Feature } from '@/chat-common/store/chat-v2.slice'
import { kNewConversationTitle } from '../constants'
import ChatListSidebarChatItemMenu from '@/chat-common/components/chat-list-sidebar-chat-item-menu'
import ConversationDeleteConfirmDialog from '@/chat-common/components/dialogs/conversation-delete-confirm-dialog'
import { selectListOfConversationHeaders } from '../selectors'
import { RootState } from '@/store/store'
import ChatTitleChangeDialog from '@/chat-common/components/dialogs/chat-title-change-dialog'
import { AgentConversationsActions } from '@/agent/conversations/store/slice'
import { isDefined } from '@/util/is-defined'

const fadeOutActiveChat = {
  content: '""',
  width: '60px',
  background: 'linear-gradient(to right, transparent, #EAE9E5 calc(100% - 20px), #EAE9E5)',
}
const fadeOutInactiveChat = {
  content: '""',
  // background: 'red',
  width: '60px',
  background: 'linear-gradient(to right, transparent, #FDFCFB calc(100% - 20px), #FDFCFB)',
}

type ChatListDateRange = {
  title: string
  conversations: ConversationHeader[]
}

type ChatListSidebarProps = {
  onClickCallback?: () => void
}

export default function ChatListSidebar(props: ChatListSidebarProps) {
  const { trackEvent } = useAnalytics()
  const { chatFeature, chatId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { onClickCallback } = props

  const errorMessage = 'Error loading chat history'

  // Local state
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteDialogItem, setDeleteDialogItem] = useState<ChatListSidebarItem | null>(null)
  const [changeTitleDialogOpen, setChangeTitleDialogOpen] = useState(false)
  const [changeTitleItem, setChangeTitleItem] = useState<ChatListSidebarItem | null>(null)

  // Validate the chat feature
  const validFeature: boolean = Object.values(ChatV2Feature).includes(chatFeature as ChatV2Feature)

  // Agent Conversation State
  const agentConversations = useAppSelector((state: RootState) => state.agentConversationsState.conversations)
  const agentConversationsArray = Object.values(agentConversations).filter(isDefined)
  const agentConversationsLoading = useAppSelector((state: RootState) => state.agentConversationsState.loading)
  const agentConversationsError = useAppSelector((state: RootState) => state.agentConversationsState.error)

  // Legacy Conversation State
  const conversationsArray = useAppSelector(selectListOfConversationHeaders)
  const conversationsLoading = useAppSelector((state: RootState) => state.chatV2State.loading)
  const conversationsError = useAppSelector((state: RootState) => state.chatV2State.error)

  // Combined agentConversationsArray and conversationsArray
  const allConversationsArray: ChatListSidebarItem[] = []

  // Add agent conversations
  agentConversationsArray.forEach((conversation) => {
    allConversationsArray.push({
      id: conversation.id,
      title: conversation.title,
      feature: conversation.feature,
      modified_on: conversation.updated_at,
    })
  })

  // Add legacy conversations
  conversationsArray.forEach((conversation) => {
    allConversationsArray.push({
      id: conversation.id,
      title: conversation.title,
      feature: conversation.feature,
      modified_on: conversation.modified_on,
    })
  })

  // Sort the combined array by modified_on (milliseconds)
  allConversationsArray.sort((a, b) => b.modified_on - a.modified_on)

  // Inferred Values
  const noConversations = allConversationsArray.length == 0
  const loading = conversationsLoading || agentConversationsLoading
  const error = conversationsError || agentConversationsError

  // Whether to show the error message
  const showError = (error || !validFeature) && !loading

  // Whether to show the loader - only if no data yet
  const showLoader = loading && noConversations

  // Deletion functions
  // Open the confirm delete dialog
  const openDeleteDialog = (conversationItem: ChatListSidebarItem) => {
    setDeleteDialogItem(conversationItem)
    setDeleteDialogOpen(true)
  }

  // Rename functions
  // Open the change title dialog
  const openChangeTitleDialog = (conversationItem: ChatListSidebarItem) => {
    setChangeTitleItem(conversationItem)
    setChangeTitleDialogOpen(true)
  }

  const handleChatClick = (id: string) => {
    // Navigate to the chat
    navigate(`/dashboard/chat/${chatFeature}/${id}`)
  }

  // Fetch the chat history when this component is rendered
  // (cached at the listener level)
  useEffect(() => {
    // Fetch agent conversations
    dispatch(AgentConversationsActions.refreshConversationList({ forceRefresh: false }))

    // Fetch legacy conversations
    dispatch(ChatV2Actions.refreshConversations({ forceRefresh: false }))
  }, [])

  // Match For Feature Changes
  // Rename any lrr features in the dataChatList to lrr_v2 so we can show them both in the same list
  function featureMatchAcrossVersions(conversationFeature: string, chatFeature: string | undefined): boolean {
    // Match "agent" to "assistant"
    if (conversationFeature == ChatV2Feature.agent && chatFeature == ChatV2Feature.assistant) return true

    // allow lrr to match lrr_v2
    if (conversationFeature == ChatV2Feature.lrr && chatFeature == ChatV2Feature.lrr_v2) return true

    // normal matching for all others
    return conversationFeature == chatFeature
  }

  // Filter for only this feature's conversations
  const featureConversations = allConversationsArray.filter((conversation) => featureMatchAcrossVersions(conversation.feature, chatFeature))

  // Filter for only non-new conversations
  const featureExistingConversations = featureConversations.filter((conversation) => conversation.title != kNewConversationTitle)

  // Filter for only this feature's new conversations
  const featureNewConversations = featureConversations.filter((conversation) => conversation.title == kNewConversationTitle)

  // Construct dates for the time intervals
  const now = new Date()
  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const startOfYesterday = new Date(startOfToday)
  startOfYesterday.setDate(startOfYesterday.getDate() - 1)
  const sevenDaysAgo = new Date(startOfToday)
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

  // Construct empty lists for each time interval
  const todaysConversations: ConversationHeader[] = []
  const yesterdaysConversations: ConversationHeader[] = []
  const last7DaysConversations: ConversationHeader[] = []

  // Collect the the date ranges into an iterable
  const featureExistingConversationsDateSegmented: ChatListDateRange[] = [
    { title: 'Today', conversations: todaysConversations },
    { title: 'Yesterday', conversations: yesterdaysConversations },
    { title: 'Last 7 days', conversations: last7DaysConversations },
  ]

  // Iterate
  // push to place at beginning of array to keep the timestamp ordering
  // 1. Add conversations for today
  // 2. Add conversations for yesterday
  // 3. Add conversations for last 7 days
  // 4. Add older conversations by month
  for (const conversation of featureExistingConversations) {
    const conversationDate = new Date(conversation.modified_on)

    // Today's Conversations
    if (conversationDate >= startOfToday) {
      todaysConversations.push(conversation)
      continue
    }

    // Yesterday's Conversations
    else if (conversationDate >= startOfYesterday && conversationDate < startOfToday) {
      yesterdaysConversations.push(conversation)
      continue
    }

    // Last 7 Days Conversations
    else if (conversationDate >= sevenDaysAgo && conversationDate < startOfYesterday) {
      last7DaysConversations.push(conversation)
      continue
    }

    // Older - Group by month
    // Identify the month and year of the conversation
    // If there is no ChatListDateRange for the month, add one
    else {
      // Get the month and year (2 digit) of the conversation
      const month = conversationDate.toLocaleString('default', { month: 'short' })
      const year = conversationDate.getFullYear().toString()
      const title = `${month}, ${year}`

      // Check if the title already exists in the array
      const existingTitle = featureExistingConversationsDateSegmented.find((range) => range.title == title)
      if (existingTitle) {
        existingTitle.conversations.push(conversation)
      } else {
        featureExistingConversationsDateSegmented.push({ title: title, conversations: [conversation] })
      }
      continue
    }
  }

  // Create the array of list elements with buffers between each date range
  const featureExistingConversationsJsxElements: JSX.Element[] = []
  featureExistingConversationsDateSegmented.forEach((range) => {
    // Push the title of the range if there are conversations in it
    if (range.conversations.length > 0) {
      featureExistingConversationsJsxElements.push(
        <li key={range.title} className="block pl-2 py-1 text-xs text-black font-bold">
          {range.title}
        </li>
      )
    }

    range.conversations.forEach((conversation) => {
      const active = conversation.id == chatId
      const lastInDateRangeGroup = range.conversations.indexOf(conversation) == range.conversations.length - 1

      featureExistingConversationsJsxElements.push(
        <li
          key={conversation.id}
          test-id={`chat-list-sidebar-${conversation.id}`}
          title={conversation.title}
          className={`group relative block whitespace-nowrap pl-2 py-1 text-sm text-brand-neutral-800 cursor-pointer rounded-md ${
            lastInDateRangeGroup ? 'mb-4' : ''
          } ${active ? 'bg-brand-neutral-200' : ''}`}
          onClick={() => {
            handleChatClick(conversation.id)

            // Callback
            if (onClickCallback) onClickCallback()
          }}
        >
          <span>{conversation.feature === ChatV2Feature.assistant ? 'Legacy Assistant - ' : ''}</span>
          <span>{conversation.title}</span>
          <span className="absolute right-0 top-0 h-full rounded-e-md" style={active ? fadeOutActiveChat : fadeOutInactiveChat} />

          <ChatListSidebarChatItemMenu
            chatListSidebarItem={conversation}
            onSelectRename={() => openChangeTitleDialog(conversation)}
            onSelectDelete={() => openDeleteDialog(conversation)}
          />
        </li>
      )
    })
  })

  // Show loading indicator
  if (showLoader)
    return (
      <div className="p-2 text-sm flex gap-x-2 items-center">
        <CircularProgressContinuousSized size={12} thickness={7} />
      </div>
    )

  // Show error
  if (showError) return <div className="p-2 text-red-700 text-sm text-center">{errorMessage}</div>

  return (
    <>
      <ChatTitleChangeDialog
        chatItem={changeTitleItem}
        dialogVisible={changeTitleDialogOpen}
        onDialogClose={() => {
          setChangeTitleDialogOpen(false)
          setChangeTitleItem(null)
        }}
      />
      <ConversationDeleteConfirmDialog
        chatItem={deleteDialogItem}
        dialogVisible={deleteDialogOpen}
        onDialogClose={() => {
          setDeleteDialogOpen(false)
          setDeleteDialogItem(null)
        }}
      />
      <div className="overflow-hidden mt-1 ml-2 mr-2">
        {featureConversations.length == 0 && <div className="text-brand-neutral-500 text-sm">No chat history</div>}
        {featureNewConversations.length > 0 && (
          <ul className="list-none m-0 p-0 mb-4">
            {featureNewConversations
              .reverse()
              .map((conversation) => {
                const active = conversation.id == chatId
                return (
                  <li
                    key={conversation.id}
                    className={`relative block whitespace-nowrap pl-2 py-1 text-sm text-brand-neutral-800 cursor-pointer rounded-md ${
                      active ? 'bg-brand-neutral-200' : ''
                    }`}
                    onClick={() => {
                      trackEvent(AnalyticsEvent.OpenExistingConversation)
                      handleChatClick(conversation.id)

                      // Callback
                      if (onClickCallback) onClickCallback()
                    }}
                  >
                    <span>{conversation.feature === ChatV2Feature.assistant ? 'Legacy Assistant - ' : ''}</span>
                    <span>{conversation.title}</span>

                    <span className="absolute right-0 top-0 w-8 h-full rounded-e-md" style={active ? fadeOutActiveChat : fadeOutInactiveChat} />
                    <ChatListSidebarChatItemMenu
                      chatListSidebarItem={conversation}
                      onSelectRename={() => openChangeTitleDialog(conversation)}
                      onSelectDelete={() => openDeleteDialog(conversation)}
                    />
                  </li>
                )
              })
              .reverse()}
          </ul>
        )}
        {featureExistingConversations.length > 0 && <ul className="list-none m-0 p-0">{featureExistingConversationsJsxElements}</ul>}
      </div>
    </>
  )
}

import { kAgentRouterApiBasePath } from '@/constants/constants-api-paths'
import { kPaxtonAppApiBaseUrl } from '@/constants/constants-links'
import { getAuth } from 'firebase/auth'
import { nanoid } from 'nanoid'
import * as Sentry from '@sentry/browser'
import { GetAgentConversationsResponse, GetAgentConversationsResponseSchema } from '../store/schemas'

/**
 * Fetches the list of agent conversations for the current user.
 * @returns
 */
export async function getAgentConversationList(): Promise<GetAgentConversationsResponse> {
  const sentry_transaction_id = nanoid()

  try {
    const token = await getAuth().currentUser?.getIdToken()
    if (!token) throw new Error("Could not retrieve user's authentication token.")

    const requestOptions: RequestInit = {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Transaction-Id': sentry_transaction_id,
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    }

    const apiUrl = kPaxtonAppApiBaseUrl() + kAgentRouterApiBasePath + '/conversations'
    const response = await fetch(apiUrl, requestOptions)
    const data = await response.json()

    if (!response.ok) {
      Sentry.withScope((scope) => {
        scope.setTags({ transaction_id: sentry_transaction_id })

        Sentry.captureException(new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`), {
          extra: {
            requestUrl: apiUrl,
            requestOptions: requestOptions,
            response: JSON.stringify(response),
            data: JSON.stringify(data),
            onLine: navigator.onLine,
            cookieEnabled: navigator.cookieEnabled,
          },
        })
      })
      throw new Error(`Fetch error - status: ${response.status}, detail: ${JSON.stringify(data.detail)}`)
    }

    // Optionally validate the response data using a schema
    const parsedData = GetAgentConversationsResponseSchema.parse(data)
    console.log('Agent conversations: ', parsedData)
    return parsedData
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setTags({ transaction_id: sentry_transaction_id })

      Sentry.captureException(e, {
        extra: { onLine: navigator.onLine, cookieEnabled: navigator.cookieEnabled },
      })
    })
    throw e
  }
}

import { configureStore } from '@reduxjs/toolkit'
import chatV2UxSlice from '@/chat-common/store/chat-v2-ux.slice'
import aiQueryResultSlice from './slices/ai-query-result.slice'
import documentAuditStreamResponseSlice from './slices/document-audit-stream-response.slice'
import booleanBuilderStreamResponseSlice from './slices/boolean-builder-stream-response.slice'
import globalToastSlice from './slices/global-toast.slice'
import uiStateSlice from './slices/ui-state.slice'
import { paxtonAppApi } from './apis/paxton-app-api'
import onboardingReducer, { onboardingApi } from '@/onboarding/store/onboarding.slice'
import { getSourceApi } from '@/chat-common/api/source-apis'
import documentEditingSlice from '@/document-editing/store/document-editing.slice'
import { documentEditingApi } from '@/document-editing/apis/document-editing.api'
import { hostedFiltersApi } from './apis/hosted-filters-api'
import { orgAdminApi } from '@/organizations/apis/org-admin.api'
import citatorStateSlice from '@/citator/store/citator-state.slice'
import { citatorApi } from '@/citator/repository/citiator.api'
import { caselawReferenceViewerApi } from '@/standalone-reference-viewer/caselaw/apis/caselaw-reference-viewer.api'
import subscriptionReducer, { subscriptionApi } from '../routes/dashboard/subscribe/store/subscription.slice'
import appInitSlice from './slices/app-init.slice'
import uploadTasksSlice from './slices/file-upload-tasks.slice'
import selectedFilesSlice from '@/chat-common/selected_files/store/slice'
import chatV2Slice from '@/chat-common/store/chat-v2.slice'
import { conversationListListenerMiddleware } from '@/chat-common/conversation_list/store/listener'
import { selectedFilesListenerMiddleware } from '@/chat-common/selected_files/store/listeners'
import { chatV2ListenerMiddleware } from '@/chat-common/store/chat-v2.listeners'
import agentConversationsSlice from '@/agent/conversations/store/slice'
import ws2Slice from '@/websocket_v2/ws2.slice'
import agentConversationFormSlice from '@/agent/chat_form/store/slice'
import { agentFormListenerMiddleware } from '@/agent/chat_form/store/listeners'
import { ws2ListenerMiddleware } from '@/websocket_v2/ws2.listeners'
import artifactsSlice from '@/artifacts/store/slice'
import agentEventsSlice from '@/agent/events/store/slice'
import agentResponseMetadataSlice from '@/agent/response_metadata/store/slice'
import { agentConversationsListenerMiddleware } from '@/agent/conversations/store/listeners'
import { artifactListenerMiddleware } from '@/artifacts/store/listeners'
import filesDriveReducer from '@/routes/dashboard/file-drive/store/files-drive.slice'
import filesDriveListenerMiddleware from '@/routes/dashboard/file-drive/store/files-drive.listeners'

export const store = configureStore({
  reducer: {
    agentConversationsState: agentConversationsSlice,
    agentConversationFormState: agentConversationFormSlice,
    agentEventsState: agentEventsSlice,
    agentResponseMetadataState: agentResponseMetadataSlice,
    artifactsState: artifactsSlice,
    chatV2State: chatV2Slice,
    chatV2UxState: chatV2UxSlice,
    selectedFilesState: selectedFilesSlice,
    uploadFileTasksState: uploadTasksSlice,
    aiQueryResults: aiQueryResultSlice,
    documentAuditApiResponse: documentAuditStreamResponseSlice,
    booleanBuilderApiResponse: booleanBuilderStreamResponseSlice,
    globalToast: globalToastSlice,
    uiState: uiStateSlice,
    appInitState: appInitSlice,
    documentEditingState: documentEditingSlice,
    citatorState: citatorStateSlice,
    subscriptionState: subscriptionReducer,
    onboardingState: onboardingReducer,
    ws2State: ws2Slice,
    filesDrive: filesDriveReducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    // Add generated RTK Query reducers (the streaming endpoints are not supported by RTK Query)
    [paxtonAppApi.reducerPath]: paxtonAppApi.reducer, // THIS IS THE NEW RTK QUERY API FOR SUGGESTED QUESTIONS
    [getSourceApi.reducerPath]: getSourceApi.reducer,
    [hostedFiltersApi.reducerPath]: hostedFiltersApi.reducer,
    [documentEditingApi.reducerPath]: documentEditingApi.reducer,
    [orgAdminApi.reducerPath]: orgAdminApi.reducer,
    [citatorApi.reducerPath]: citatorApi.reducer,
    [caselawReferenceViewerApi.reducerPath]: caselawReferenceViewerApi.reducer,
  },

  // Add createListenerMiddleware and RTK-Query Middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(
        paxtonAppApi.middleware,
        getSourceApi.middleware,
        hostedFiltersApi.middleware,
        documentEditingApi.middleware,
        orgAdminApi.middleware,
        citatorApi.middleware,
        caselawReferenceViewerApi.middleware,
        subscriptionApi.middleware,
        onboardingApi.middleware,
        filesDriveListenerMiddleware.middleware
      )
      // Prepend listener middleware because it must be started before other middleware
      .prepend(
        artifactListenerMiddleware.middleware,
        ws2ListenerMiddleware.middleware,
        chatV2ListenerMiddleware.middleware,
        conversationListListenerMiddleware.middleware,
        selectedFilesListenerMiddleware.middleware,
        agentFormListenerMiddleware.middleware,
        agentConversationsListenerMiddleware.middleware
      ),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

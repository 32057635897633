import { z } from 'zod'

export const UserSettingsGetSchema = z.object({
  prompt_assist_enabled: z.boolean().nullish(),
})

export const UserSettingsPatchSchema = z.object({
  prompt_assist_enabled: z.boolean(),
})

export const SettingsGetResponseSchema = z.object({
  success: z.boolean(),
  data: z.object({
    user: UserSettingsGetSchema,
    organization: z.record(z.any()).optional().default({}),
  }),
})

export const UserSettingsPatchResponseSchema = z.object({
  success: z.boolean(),
  data: UserSettingsPatchSchema,
})

export type SettingsGetResponse = z.infer<typeof SettingsGetResponseSchema>
export type UserSettingsPatchResponse = z.infer<typeof UserSettingsPatchResponseSchema>

import sanitizeHtml from 'sanitize-html'
import markdownToHtmlString from '@/util/markdown-parsing/markdown-to-html'
import { ArtifactProcessingError } from '../artifact-errors'
import * as Sentry from '@sentry/react'

/**
 * Converts markdown to sanitized HTML.
 *
 * @param markdown - The markdown string to process.
 * @returns The resulting HTML string.
 * @throws ArtifactProcessingError if processing fails.
 */
export const convertArtifactMarkdownToHtml = async (markdown: string): Promise<string> => {
  try {
    const rawHtml = await markdownToHtmlString(markdown)

    const sanitizedHtml = sanitizeHtml(rawHtml)

    return sanitizedHtml
  } catch (error) {
    const processingError = new ArtifactProcessingError(`Failed to convert markdown to html string: ${(error as Error).message}`)
    Sentry.captureException(processingError, {
      extra: { error: JSON.stringify(error) },
    })
    throw processingError
  }
}
